import { Component, Input } from '@angular/core';

// Take a note that this component is not a substitution of a11y ready readonly form: https://ux.stackexchange.com/a/34083
@Component({
  selector: 'ngp-readonly-text-control',
  templateUrl: 'readonly-text-control.component.html',
  styleUrls: ['readonly-text-control.component.sass'],
})
export class ReadonlyTextControlComponent {
  @Input()
  labelId = '';
  @Input()
  labelKey = '';
  @Input()
  text = '';
}
