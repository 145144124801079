import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { toData } from '../../../../service/rest/response-mapping';

import { EntityId } from '../../../../interface/has-entity-id.interface';
import { RestResponse } from '../../../../model/response.interface';
import { DeleteResponse } from '../../../../model/rest-response/delete-response.interface';
import { PostResponse } from '../../../../model/rest-response/post-response.interface';
import { ValidityResponse } from '../../../../model/validity-response.interface';
import { encodeParams } from '../../../../security/encode-params';
import { encodeEndpoint } from '../../../../security/encode-url';
import { FileFormatColumnType } from '../model/file-format-column-type.interface';
import { FileFormatInheritedColumnType } from '../model/file-format-inherited-column-type.interface';
import { FileFormatItem } from '../model/file-format-item.interface';
import { FileFormatSearchParam } from '../model/file-format-search-param';
import { FileFormat } from '../model/file-format.interface';
import { FileFormatsResponse } from '../model/file-formats-response.interface';
import { ReplaceWithTemplate } from '../model/replace-with-template.interface';

@Injectable({
  providedIn: 'root',
})
export class FileFormatResourceService {
  constructor(private http: HttpClient, private translate: TranslateService) {}

  searchFileFormats$(
    transactionId: number,
    entityRevision: number,
    versionPreviewMode: boolean,
    searchParams?: FileFormatSearchParam,
    sort?: string,
  ): Observable<FileFormatsResponse> {
    const params = encodeParams({ sort });

    const url = versionPreviewMode
      ? encodeEndpoint(
          'resources/transactions/{}/entity-revisions/{}/file-formats/search',
          transactionId,
          entityRevision,
        )
      : encodeEndpoint('resources/transactions/{}/file-formats/search', transactionId);

    return this.http
      .post<RestResponse<FileFormatsResponse>>(url, searchParams || {}, { params })
      .pipe(map(toData));
  }

  getFileFormat(
    transactionId: number,
    fileFormatId: EntityId,
    entityRevision: number,
    versionPreviewMode: boolean,
  ) {
    const url = versionPreviewMode
      ? encodeEndpoint(
          'resources/transactions/{}/entity-revisions/{}/file-formats/{}',
          transactionId,
          entityRevision,
          fileFormatId,
        )
      : encodeEndpoint('resources/transactions/{}/file-formats/{}', transactionId, fileFormatId);

    return this.http.get<RestResponse<FileFormat>>(url).pipe(map(toData));
  }

  postFileFormat(transactionId: number, data: unknown) {
    const url = encodeEndpoint('resources/transactions/{}/file-formats', transactionId);
    return this.http.post<RestResponse<FileFormatItem>>(url, data).pipe(map(toData));
  }

  putFileFormat(transactionId: number, fileFormatId: EntityId, data: unknown) {
    const url = encodeEndpoint(
      'resources/transactions/{}/file-formats/{}',
      transactionId,
      fileFormatId,
    );
    return this.http.put<RestResponse<FileFormatItem>>(url, data).pipe(map(toData));
  }

  postDataSource(transactionId: number, data: unknown) {
    const url = encodeEndpoint('resources/transactions/{}/data-sources', transactionId);
    return this.http.post(url, data);
  }

  getColumnDefinitionTypes(
    transactionId: number,
    entityRevision: number,
  ): Observable<FileFormatColumnType[]> {
    const params = encodeParams({ entityRevision });
    const url = encodeEndpoint('resources/transactions/{}/column-types', transactionId);
    return this.http.get<RestResponse<FileFormatColumnType[]>>(url, { params }).pipe(
      map(toData),
      map((columnDefinitions) =>
        columnDefinitions.map((definition) => ({
          entityId: definition.entityId,
          key: definition.displayName ? undefined : definition.entityId,
          isDefinedByUser: !!definition.displayName,
          displayName: definition.displayName,
          name: definition.displayName,
          supportedVariant: definition.supportedVariant,
          analysisGroup: definition.analysisGroup,
          dataType: definition.dataType,
          analysisGroupEditable: false,
          dataTypeEditable: false,
        })),
      ),
    );
  }

  getInheritedColumnDefinitionTypesForTransaction(
    transactionId: number,
    entityRevision: number,
  ): Observable<FileFormatInheritedColumnType[]> {
    const params = encodeParams({ entityRevision });
    const url = encodeEndpoint('resources/transactions/{}/inherited-column-types', transactionId);
    return this.http.get<RestResponse<FileFormatInheritedColumnType[]>>(url, { params }).pipe(
      map(toData),
      map((columnDefinitions) =>
        columnDefinitions.map((definition) => ({
          entityId: definition.entityId,
          analysisGroup: definition.analysisGroup,
          dataType: definition.dataType,
          key: definition.displayName ? undefined : definition.entityId,
          name: definition.displayName
            ? definition.displayName
            : this.translate.instant('COLUMN_DEFINITION_TYPES.' + definition.entityId),
          displayName: definition.displayName
            ? definition.displayName
            : this.translate.instant('COLUMN_DEFINITION_TYPES.' + definition.entityId),
        })),
      ),
    );
  }

  postLocalTemplate(transactionId: number, data: unknown) {
    const url = encodeEndpoint('resources/transactions/{}/templates/file-formats', transactionId);
    return this.http.post<RestResponse<PostResponse>>(url, data).pipe(map(toData));
  }

  checkFileFormatTemplateNameAvailable(name: string, transactionId: number) {
    const url = encodeEndpoint(
      'resources/transactions/{}/templates/file-formats/name-availability',
      transactionId,
    );
    const params = encodeParams({ name });
    return this.http.get(url, { params }).pipe(map(toData));
  }

  removeFileFormat(fileFormatId: EntityId, transactionId: number, entityRevision: number) {
    const params = encodeParams({ entityRevision });
    const url = encodeEndpoint(
      'resources/transactions/{}/file-formats/{}',
      transactionId,
      fileFormatId,
    );
    return this.http.delete<RestResponse<DeleteResponse>>(url, { params }).pipe(map(toData));
  }

  removeTemplateFromFileFormat(
    fileFormatId: EntityId,
    transactionId: number,
    entityRevision: number,
  ) {
    const params = encodeParams({ entityRevision });
    const url = encodeEndpoint(
      'resources/transactions/{}/file-formats/{}/template',
      transactionId,
      fileFormatId,
    );
    return this.http.delete<RestResponse<DeleteResponse>>(url, { params }).pipe(map(toData));
  }

  replaceFileFormatWithTemplate(
    transactionId: number,
    fileFormatId: EntityId,
    data: ReplaceWithTemplate,
  ) {
    const url = encodeEndpoint(
      'resources/transactions/{}/file-formats/{}/template',
      transactionId,
      fileFormatId,
    );
    return this.http.post<RestResponse<FileFormatItem>>(url, data).pipe(map(toData));
  }

  validateReplacingFileFormatWithTemplate(
    transactionId: number,
    fileFormatId: EntityId,
    data: ReplaceWithTemplate,
  ) {
    const url = encodeEndpoint(
      'resources/transactions/{}/file-formats/{}/template/validate',
      transactionId,
      fileFormatId,
    );
    const params = encodeParams({
      transactionEntityRevision: data.transactionEntityRevision,
      localFileFormatTemplateId: data.localFileFormatTemplateId,
      globalFileFormatTemplateId: data.globalFileFormatTemplateId,
    });
    return this.http.get<RestResponse<ValidityResponse>>(url, { params }).pipe(map(toData));
  }
}
