import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { map, tap } from 'rxjs/operators';

import {
  isDefined,
  NavigationService,
  PushRequest,
  PushRequestService,
  RelatedVersion,
} from '@demica/core/core';
import { ApprovePush } from '@demica/resources/common';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

enum PushType {
  CLIENT = 'client',
  SELLER = 'opco',
  TRANSACTION = 'transaction',
}

type RelatedVersionGroup = FormGroup<{
  label: FormControl<string>;
  subjectVersionId: FormControl<number>;
  subjectId: FormControl<number>;
  subjectType: FormControl<string>;
  approve: FormControl<boolean>;
}>;

type RelatedVersionFormValue = RelatedVersionGroup['value'];

export type PushRequestReviewForm = FormGroup<{
  approvePush: FormControl<boolean>;
  comment: FormControl<string>;
  relatedVersions: FormArray<RelatedVersionGroup>;
}>;

@Component({
  selector: 'trf-push-request-review-modal',
  templateUrl: 'push-request-review-modal.component.html',
  styleUrls: ['./push-request-review-modal.component.sass'],
})
export class PushRequestReviewModalComponent implements OnInit {
  @Input()
  pushRequest: PushRequest;

  @Input()
  pushRequestApproved: () => void;

  loading = true;
  submitted = false;
  displayAlreadyReviewedWarning = false;

  form: PushRequestReviewForm = this.fb.group({
    approvePush: [true, []],
    comment: [null as string, []],
    relatedVersions: new FormArray<RelatedVersionGroup>([]),
  });

  private _destroyRef = inject(DestroyRef);

  constructor(
    private fb: FormBuilder,
    private modal: NgbActiveModal,
    private navigate: NavigationService,
    private pushRequestService: PushRequestService,
  ) {}

  ngOnInit(): void {
    this.pushRequestService
      .getRelatedVersions(this.pushRequest.entityId)
      .pipe(
        map(this._sortVersions),
        tap((relatedVersions) => {
          this._addRelatedVersionFormControl(relatedVersions);
          this.displayAlreadyReviewedWarning = relatedVersions.some((rv) => rv.valid != null);
          this.loading = false;
        }),
        takeUntilDestroyed(this._destroyRef),
      )
      .subscribe();

    this.form.controls.relatedVersions.valueChanges
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(this._onFormVersionsValueChange);
  }

  onVersionClick(group: RelatedVersionGroup): void {
    const groupValue = group.getRawValue();
    switch (groupValue.subjectType) {
      case PushType.CLIENT:
        this.navigate.clientPreview(groupValue.subjectId, {
          queryParams: { version: groupValue.subjectVersionId },
        });
        break;
      case PushType.SELLER:
        this.navigate.sellerPreview(groupValue.subjectId, {
          queryParams: { version: groupValue.subjectVersionId },
        });
        break;
      case PushType.TRANSACTION:
        this.navigate.transactionVersionPreview(groupValue.subjectId, {
          queryParams: { version: groupValue.subjectVersionId },
        });
        break;
    }
    this.onClose();
  }

  onSave(): void {
    const formValues = this.form.getRawValue();
    const versions: Record<number, boolean> = {};

    formValues.relatedVersions.forEach(
      (value) => (versions[value.subjectVersionId] = value.approve),
    );

    const payload: ApprovePush = {
      approveVersions: versions,
      approvePush: formValues.approvePush,
      comment: formValues.comment || null,
    };

    this.pushRequestService
      .approvePushRequest(this.pushRequest.entityId, payload)
      .pipe(tap(() => this._handlePushRequestApproval()))
      .subscribe();
  }

  onClose(): void {
    this.modal.dismiss();
  }

  private _onFormVersionsValueChange = (changes: RelatedVersionFormValue[]): void => {
    const disable = changes.some((rv) => !rv.approve);
    const control = this.form.controls.approvePush;
    if (disable) {
      control.disable();
      control.setValue(false);
    } else {
      control.enable();
    }
  };

  private _addRelatedVersionFormControl(relatedVersions: RelatedVersion[]): void {
    relatedVersions.forEach((relatedVersion) => {
      this.form.controls.relatedVersions.push(this._createRelatedVersionGroup(relatedVersion));
    });
  }

  private _createRelatedVersionGroup(relatedVersion: RelatedVersion): RelatedVersionGroup {
    const versionGroup = this.fb.group({
      label: [this._getRelatedVersionLabel(relatedVersion)],
      subjectVersionId: [relatedVersion.subjectVersionId],
      subjectId: [relatedVersion.subjectId],
      subjectType: [relatedVersion.subjectType],
      approve: [isDefined(relatedVersion.valid) ? relatedVersion.valid : true],
    });
    if (isDefined(relatedVersion.valid)) versionGroup.disable();
    return versionGroup;
  }

  private _getRelatedVersionLabel(relatedVersion: RelatedVersion): string {
    return `${relatedVersion.subjectName} (${relatedVersion.subjectCode}) - ${relatedVersion.versionName}`;
  }

  private _sortVersions = (relatedVersions: RelatedVersion[]): RelatedVersion[] => {
    return relatedVersions.sort((rv) => (rv.mainSubject ? -1 : 1));
  };

  private _handlePushRequestApproval(): void {
    this.pushRequestApproved();
    this.onClose();
  }
}
