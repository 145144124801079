import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  encodeEndpoint,
  encodeParams,
  EntityId,
  HttpContextType,
  NameAvailabilityResponse,
  PushRequest,
  RelatedVersion,
  RestResponse,
  RestResponseWithMetadata,
  toData,
  Version,
} from '@demica/resources/common';

import { Client } from '../../client/model/client.interface';
import { Seller, SellerDictionary } from '../model/seller.model';

@Injectable({
  providedIn: 'root',
})
export class SellerResourceService {
  constructor(private _http: HttpClient) {}

  getSeller$(id: number): Observable<Seller> {
    const url = encodeEndpoint('resources/opcos/{}', id);

    return this._http.get<RestResponse<Seller>>(url).pipe(map(toData));
  }

  verifySellerStatus$(id: EntityId, contextType: HttpContextType): Observable<null> {
    const url = encodeEndpoint('resources/opcos/{}', id);
    const context = new HttpContext().set(contextType, true);

    return this._http.head<null>(url, { context });
  }

  getSellerInRevision$(id: number, entityRevision: number): Observable<Seller> {
    const url = encodeEndpoint('resources/opcos/{}/entity-revisions/{}', id, entityRevision);

    return this._http.get<RestResponse<Seller>>(url).pipe(map(toData));
  }

  getTransactionSellers$(transactionId: number): Observable<SellerDictionary[]> {
    const url = encodeEndpoint('resources/transactions/{}/opcos', transactionId);

    return this._http.get<RestResponseWithMetadata<SellerDictionary>>(url).pipe(
      map(toData),
      map((sellers) =>
        sellers.map((seller) => ({
          entityId: seller.entityId,
          key: seller.name,
          name: seller.name,
        })),
      ),
    );
  }

  // TODO: define return-type
  createSeller$(opco: Seller): Observable<unknown> {
    return this._http.post(encodeEndpoint('resources/opcos'), opco);
  }

  // TODO: define return-type
  updateSeller$(id: number, seller: Seller): Observable<unknown> {
    return this._http.put(encodeEndpoint('resources/opcos/{}', id), seller);
  }

  // TODO: define return-type
  removeSeller$(sellerId: number): Observable<unknown> {
    return this._http.delete(encodeEndpoint('resources/opcos/{}', sellerId));
  }

  getClientAssociatedWithSeller$(sellerId: number): Observable<Client> {
    const url = encodeEndpoint('resources/opcos/{}/client', sellerId);

    return this._http.get<RestResponse<Client>>(url).pipe(map(toData));
  }

  checkSellerVersionNameAvailability$(
    name: string,
    sellerId: number,
  ): Observable<NameAvailabilityResponse> {
    const url = encodeEndpoint('resources/opcos/{}/version-name-availability', sellerId);
    const params = encodeParams({ name });

    return this._http
      .get<RestResponse<NameAvailabilityResponse>>(url, { params: params })
      .pipe(map(toData));
  }

  // SAME IN! demica/libs/core/core/src/lib/_domain/transaction/organisation/service/organisation-resource.service.ts
  // see: checkOpcoNameAvailable
  checkSellerNameAvailability$(name: string, opcoId?: number): Observable<boolean> {
    const url = encodeEndpoint('resources/organizations/clients/name-availability');
    const params = encodeParams({ name, opcoId });

    return this._http
      .get<RestResponse<NameAvailabilityResponse>>(url, {
        params,
      })
      .pipe(map((response) => response.data.nameAvailable));
  }

  getSellerVersions$(version: number): Observable<Version[]> {
    const url = encodeEndpoint('resources/opcos/{}/versions', version);

    return this._http.get<RestResponse<Version[]>>(url).pipe(map(toData));
  }

  // TODO: define return-type
  createSellerVersion$(sellerId: number, data: RelatedVersion): Observable<unknown> {
    const url = encodeEndpoint('resources/opcos/{}/versions', sellerId);

    return this._http.post(url, data);
  }

  // TODO: define return-type
  createPushRequest$(sellerId: number, data: unknown): Observable<unknown> {
    const url = encodeEndpoint('resources/opcos/{}/push-requests', sellerId);

    return this._http.post(url, data);
  }

  getPushRequests$(sellerId: number, size = 1000): Observable<PushRequest[]> {
    const url = encodeEndpoint('resources/opcos/{}/push-requests', sellerId);
    const params = encodeParams({ size });

    return this._http.get<RestResponse<PushRequest[]>>(url, { params: params }).pipe(map(toData));
  }
}
