import { Injectable } from '@angular/core';

import { map, startWith, tap } from 'rxjs/operators';

import { getURIToPathInPublicApp, SESSION_TIMEOUT_PATH } from '@demica/global-configuration';
import { AppConfig } from '@demica/resources/app-config';

import { LogoutBroadcastClientService } from '../../broadcast-channel/logout-client/logout-broadcast-client.service';
import { FeatureToggleService } from '../../feature-toggle.service';
import { UserPreferences } from '../../user-preferences/user-preferences.service';
import { AuthKeycloakService } from '../auth-keycloak.service';
import { AfterLogoutEventHandlerService } from './after-logout-event-handler.service';
import { LogoutReasonStateService } from './logout-reason-state.service';
import { LogoutRedirectUrlService } from './logout-redirect-url.service';

import { Locale } from '../../../config/locale.enum';
import { locationRedirect, setIgnoreDiscardUnsavedChanges } from '../../../utils/window';

const CUSTOM_POST_LOGOUT_REDIRECT_FEATURE_TOGGLE = 'CUSTOM_POST_LOGOUT_REDIRECT';
const TIME_TO_FORCE_LOGOUT = 600;

@Injectable({
  providedIn: 'root',
})
export class LogoutRedirectInitializerService {
  private _currentLocale$ = this._userPreferencesService.locale;

  constructor(
    private _logoutRedirectUrl: LogoutRedirectUrlService,
    private _userPreferencesService: UserPreferences,
    private _featureToggleService: FeatureToggleService,
    private _authKeycloakService: AuthKeycloakService,
    private _logoutBroadcastClient: LogoutBroadcastClientService,
    private _afterLogoutHandler: AfterLogoutEventHandlerService,
    private _logoutReasonStateService: LogoutReasonStateService,
  ) {
    this._setAfterLogoutHandler();
  }

  initialize(config: AppConfig): void {
    this._initializeCustomLogoutRedirect(config);
    this._initializeLogoutListener();
  }

  private _initializeLogoutListener() {
    this._logoutBroadcastClient.logout$
      .pipe(
        map((message) => message.data),
        tap(({ reason }) => {
          this._logoutReasonStateService.setLogoutReason(reason);
          setTimeout(() => {
            setIgnoreDiscardUnsavedChanges();
            locationRedirect(this._logoutRedirectUrl.getRedirectUrl());
          }, TIME_TO_FORCE_LOGOUT);
        }),
      )
      .subscribe();
  }

  private _initializeCustomLogoutRedirect(config: AppConfig) {
    this._currentLocale$.pipe(startWith(Locale.EN_GB)).subscribe((locale) => {
      const postLogoutRedirectUrl = config?.auth?.postLogoutRedirectUrl;
      if (this._isCustomPostLogoutRedirectAvailable(postLogoutRedirectUrl)) {
        this._logoutRedirectUrl.setPostLogoutRedirect(
          getURIToPathInPublicApp(locale, postLogoutRedirectUrl),
        );
      }
      this._logoutRedirectUrl.setInactivityRedirect(
        getURIToPathInPublicApp(locale, SESSION_TIMEOUT_PATH),
      );
    });
  }

  private _isCustomPostLogoutRedirectAvailable(postLogoutRedirectUrl: string): boolean {
    return (
      this._featureToggleService.hasEnabled(CUSTOM_POST_LOGOUT_REDIRECT_FEATURE_TOGGLE) &&
      !!postLogoutRedirectUrl
    );
  }
  /*
   * Default redirect on some keycloak redirect requests
   * */
  private _setAfterLogoutHandler(): void {
    this._authKeycloakService.setAfterLogoutHandler(() => this._afterLogoutHandler.handle());
  }
}
