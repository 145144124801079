import { AbstractControl, FormGroup } from '@angular/forms';

import { PointsFormValidationMessages } from '../components/points-form/points-form.component';

import { definitionGroupToMessages } from '../../../forms/validation-messages/message-builder';
import {
  msgMaxDecimalPlacesExceeded,
  msgMaxIntegerPlacesExceeded,
  msgRequired,
} from '../../../forms/validation-messages/message-definitions';
import { hasError } from '../../../forms/validation-messages/message-predicates';

export const POINT_ALREADY_EXIST = 'pointAlreadyExist';

export const uniqPointValidator =
  (isValidFn: (formControl: AbstractControl) => boolean) => (formControl: AbstractControl) =>
    formControl && isValidFn(formControl) ? null : { [POINT_ALREADY_EXIST]: true };

export const getValidationMessages = (
  formProvider: () => FormGroup,
  statusProvider: () => boolean,
) =>
  definitionGroupToMessages<PointsFormValidationMessages>(
    {
      value: [msgRequired, msgMaxDecimalPlacesExceeded, msgMaxIntegerPlacesExceeded],
      x: [
        msgRequired,
        msgMaxDecimalPlacesExceeded,
        msgMaxIntegerPlacesExceeded,
        { func: hasError(POINT_ALREADY_EXIST), key: 'VALIDATION.POINT_ALREADY_EXIST' },
      ],
    },
    formProvider,
    statusProvider,
  );
