import { CdkDropList, DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterLinkActive, RouterModule } from '@angular/router';

import { MissingTranslationHandler, TranslateModule } from '@ngx-translate/core';

import { AccessibilityModule } from '@demica/accessibility';
import { ComponentsModule } from '@demica/components';
import { MissingTranslationService } from '@demica/core/core';
import { ToasterModule, ToasterService } from '@demica/toaster';

import { ChartExportResourceService } from './chart-export/service/chart-export-resource.service';
import { ChartExportService } from './chart-export/service/chart-export.service';
import { ChartGenerateService } from './service/chart-generate.service';
import { DataTableFocusOnSortService } from './service/data-table-focus-on-sort.service';
import { EnvironmentTransactionResourceService } from './service/environment-resource.service';
import { ModalService } from './service/modal.service';
import { PrefixTranslationService } from './service/prefix-translation.service';
import { StandardIndustryCodesService } from './service/standard-industry-codes.service';
import { TableSortService } from './service/table-sort.service';

import { AccordionComponent, NgbRefDirective } from './component/accordion/accordion.component';
import { ActionsComponent } from './component/actions/actions.component';
import { AddressComponent } from './component/address/address.component';
import { AliasMissingRowComponent } from './component/alias/alias-missing/alias-missing-row/alias-missing-row.component';
import { AliasMissingSearchComponent } from './component/alias/alias-missing/alias-missing-search/alias-missing-search.component';
import { AliasesMissingSlideinContainerComponent } from './component/alias/alias-missing/aliases-missing-slidein/aliases-missing-slidein.container';
import { AliasesMissingComponent } from './component/alias/alias-missing/aliases-missing/aliases-missing.component';
import { AliasesAwaitingApprovalRowComponent } from './component/alias/aliases-awaiting/aliases-awaiting-approval-row/aliases-awaiting-approval-row.component';
import { AliasesAwaitingApprovalSearchComponent } from './component/alias/aliases-awaiting/aliases-awaiting-approval-search/aliases-awaiting-approval-search.component';
import { AliasesAwaitingApprovalComponent } from './component/alias/aliases-awaiting/aliases-awaiting-approval/aliases-awaiting-approval.component';
import { AliasesManagementComponent } from './component/alias/aliases-management/aliases-management-component/aliases-management.component';
import { AliasesManagementCountrySelectorGroupComponent } from './component/alias/aliases-management/aliases-management-country-selector-group/aliases-management-country-selector-group.component';
import { AliasesManagementCurrencySelectorGroupComponent } from './component/alias/aliases-management/aliases-management-currency-selector-group/aliases-management-currency-selector-group.component';
import { AliasesManagementListRowComponent } from './component/alias/aliases-management/aliases-management-list-row/aliases-management-list-row.component';
import { AliasesManagementListComponent } from './component/alias/aliases-management/aliases-management-list/aliases-management-list.component';
import { AliasesManagementSearchComponent } from './component/alias/aliases-management/aliases-management-search/aliases-management-search.component';
import { AliasesManagementSlideinContainerComponent } from './component/alias/aliases-management/aliases-management-slidein/aliases-management-slidein.container';
import { ListAliasMappingTableRowComponent } from './component/alias/aliases-management/list-alias-mapping-table-row/list-alias-mapping-table-row.component';
import { ListAliasMappingsComponent } from './component/alias/aliases-management/list-alias-mappings/list-alias-mappings.component';
import { AuthorizationTokenChangeModalComponent } from './component/authorization-token-change-modal/authorization-token-change-modal.component';
import { BackButtonComponent } from './component/back-button/back-button.component';
import { ButtonControlsComponent } from './component/button-controls/button-controls.component';
import { ChartLegendItemComponent } from './component/chart-legend-item/chart-legend-item.component';
import { ClientDetailPreviewComponent } from './component/client-detail-preview/client-detail-preview.component';
import { ConfirmationModalComponent } from './component/confirmation-modal/confirmation-modal.component';
import { ContactDetailsComponent } from './component/contact-details/contact-details.component';
import { ContactTableCellComponent } from './component/contact-table-cell/contact-table-cell.component';
import { CreateSellerSlideinComponent } from './component/create-seller-slidein/create-seller-slidein.component';
import { CreateSellerFormComponent } from './component/create-seller/create-seller-form.component';
import { SellerAccountsListRowComponent } from './component/create-seller/seller-accounts-list-row.component';
import { SellerBankAccountSlideinContainerComponent } from './component/create-seller/seller-bank-account-slidein.container';
import { SellerBankAccountComponent } from './component/create-seller/seller-bank-account/seller-bank-account.component';
import { CurrenciesDropdownComponent } from './component/currencies-dropdown/currencies-dropdown.component';
import { DataTableComponent } from './component/data-table/data-table.component';
import { TextTableHeaderComponent } from './component/data-table/text-table-header.component';
import { TextTableRowComponent } from './component/data-table/text-table-row.component';
import { DateTimePickerComponent } from './component/date-time-picker/date-time-picker.component';
import { DoubleDatePickerComponent } from './component/double-date-picker/double-date-picker.component';
import { DoubleDatetimePickerComponent } from './component/double-datetime-picker/double-datetime-picker.component';
import { EntityVersionHeaderComponent } from './component/entity-version-header/entity-version-header.component';
import { FormCalendarRowComponent } from './component/form-calendar-row/form-calendar-row.component';
import { FormCheckboxRowComponent } from './component/form-checkbox-row/form-checkbox-row.component';
import { FormChipsInputRowComponent } from './component/form-chips-input-row/form-chips-input-row.component';
import { FormCurrencyAmountRowComponent } from './component/form-currency-amount-row/form-currency-amount-row.component';
import { FormDateTimePickerRowComponent } from './component/form-date-time-picker-row/form-date-time-picker-row.component';
import { FormDatepickerRowComponent } from './component/form-datepicker-row/form-datepicker-row.component';
import { FormDecimalGroupRowComponent } from './component/form-decimal-group-row/form-decimal-group-row.component';
import { FormDecimalMinMaxRowComponent } from './component/form-decimal-min-max-row/form-decimal-min-max-row.component';
import { FormDecimalRowComponent } from './component/form-decimal-row/form-decimal-row.component';
import { FormDoubleDatePickerRowComponent } from './component/form-double-date-picker-row/form-double-date-picker-row.component';
import { FormMultiSelectRowComponent } from './component/form-multi-select-row/form-multi-select-row.component';
import { FormNumberRowDefaultValidationMessagesComponent } from './component/form-number-row-default-validation-messages/form-number-row-default-validation-messages.component';
import { FormNumberRowComponent } from './component/form-number-row/form-number-row.component';
import { FormPercentageRowComponent } from './component/form-percentage-row/form-percentage-row.component';
import { FormRadioButtonGroupRowComponent } from './component/form-radio-button-group-row/form-radio-button-group-row.component';
import { FormSelectRowComponent } from './component/form-select-row/form-select-row.component';
import {
  SelectComparatorFlatIdDirective,
  SelectComparatorReadableDictionaryDirective,
} from './component/form-select-row/select-comparator.directive';
import { FormTelephoneRowComponent } from './component/form-telephone-row/form-telephone-row.component';
import { FormTextAreaComponent } from './component/form-text-area/form-text-area.component';
import { FormTextGroupRowComponent } from './component/form-text-group-row/form-text-group-row.component';
import { FormTextRowComponent } from './component/form-text-row/form-text-row.component';
import { FormTimepickerStartEndRowComponent } from './component/form-timepicker-start-end-row/form-timepicker-start-end-row.component';
import { FormTransactionInstanceComponent } from './component/form-transaction-instance/form-transaction-instance.component';
import { TransactionInstanceToStringPipe } from './component/form-transaction-instance/transaction-instance-to-string.pipe';
import { FormTreeSelectRowComponent } from './component/form-tree-select-row/form-tree-select-row.component';
import { FormUploadLogoComponent } from './component/form-upload-logo/form-upload-logo.component';
import { HeaderFundingAlertsComponent } from './component/header-funding-alerts/header-funding-alerts.component';
import { HeaderMissingFilesComponent } from './component/header-missing-files/header-missing-files.component';
import { HeaderComponent } from './component/header/header.component';
import { LineChartComponent } from './component/line-chart/line-chart.component';
import { LocalisationsDropdownComponent } from './component/localisations-dropdown/localisations-dropdown.component';
import { LogoDisplayComponent } from './component/logo-display/logo-display.component';
import { LogoComponent } from './component/logo/logo.component';
import { MenuAsideComponent } from './component/menu-aside/menu-aside.component';
import { MenuHelpComponent } from './component/menu-help/menu-help.component';
import { MenuItemComponent } from './component/menu-item/menu-item.component';
import { MenuSectionComponent } from './component/menu-section/menu-section.component';
import { MenuSignOutComponent } from './component/menu-sign-out/menu-sign-out.component';
import { ModalContainerComponent } from './component/modal-container/modal-container.component';
import { ModalExportComponent } from './component/modal-export/modal-export.component';
import { ModalLoaderComponent } from './component/modal-loader/modal-loader.component';
import { NewPushRequestModalComponent } from './component/new-push-request-modal/new-push-request-modal.component';
import { NewVersionModalComponent } from './component/new-version-modal/new-version-modal.component';
import { NotificationComponent } from './component/notification/notification.component';
import { OutletPlaceholderComponent } from './component/outlet-placeholder/outlet-placeholder.component';
import { PageNumberComponent } from './component/page-number/page-number.component';
import { PaginationComponent } from './component/page-number/pagination/pagination.component';
import { SpringPageNumberComponent } from './component/page-number/spring-page-number.component';
import { SybasePageNumberComponent } from './component/page-number/sybase-page-number.component';
import { PieChartComponent } from './component/pie-chart/pie-chart.component';
import { PointsFormComponent } from './component/points/components/points-form/points-form.component';
import { PointsListRowComponent } from './component/points/components/points-list-row/points-list-row.component';
import { PointsListComponent } from './component/points/components/points-list/points-list.component';
import { PointsComponent } from './component/points/points.component';
import { PreviewHeaderComponent } from './component/preview-header/preview-header.component';
import { ProfileDropdownComponent } from './component/profile-dropdown/profile-dropdown.component';
import { PushRequestReviewModalComponent } from './component/push-request-review-modal/push-request-review-modal.component';
import { PushRowComponent } from './component/push-row/push-row.component';
import { RangesFormComponent } from './component/ranges-form/ranges-form.component';
import { RangesListRowComponent } from './component/ranges-list-row/ranges-list-row.component';
import { RangesListComponent } from './component/ranges-list/ranges-list.component';
import { RangesComponent } from './component/ranges/ranges.component';
import { RegionsDropdownComponent } from './component/regions-dropdown/regions-dropdown.component';
import { ResponsiveUiBlockComponent } from './component/responsive-ui-block/responsive-ui-block.component';
import { RoutingSelectComponent } from './component/routing-select/routing-select.component';
import { SearchRowComponent } from './component/search-row/search-row.component';
import { SortableTableColumnComponent } from './component/sortable-table-column/sortable-table-column.component';
import { TableActionsComponent } from './component/table-actions/table-actions.component';
import { TabsComponent } from './component/tabs/tabs.component';
import { ToggleColumnsDropdownComponent } from './component/toggle-columns-dropdown/toggle-columns-dropdown.component';
import { TransitionsComponent } from './component/transitions/transitions.component';
import { TreeSelectOptionComponent } from './component/tree-select-option/tree-select-option.component';
import { ValidatedAmountWithCurrencyComponent } from './component/validated-amount-with-currency/validated-amount-with-currency.component';
import { VersionsViewHeaderComponent } from './component/versions-view-header/versions-view-header.component';
import { GlobalNotificationComponent } from './container/global-notification/global-notification.component';
import { HeaderAlertsComponent } from './container/header-alerts/header-alerts.component';
import { UserAuthContextChangeComponent } from './container/token-change-notifier/user-auth-context-change.component';
import { SlideinContainerComponent } from './slidein-container/slidein-container.component';

import { BytePipe } from './pipe/byte.pipe';
import { CurrencyPipe } from './pipe/currency.pipe';
import { DecimalValuePipe } from './pipe/decimal-value.pipe';
import { EllipsisPipe } from './pipe/ellipsis.pipe';
import { EntityNamePipe } from './pipe/entity-name.pipe';
import { EnumerationTranslatePipe } from './pipe/enumeration-translate-pipe';
import { FilterByPipe } from './pipe/filter-by.pipe';
import { FirstErrorPipe } from './pipe/first-error.pipe';
import { LocalisedDatePipe } from './pipe/localised-date.pipe';
import { LocalisedMoneyPipe } from './pipe/localised-money.pipe';
import { LocalisedNumberPipe } from './pipe/localised-number.pipe';
import { MaxPipe } from './pipe/max.pipe';
import { MenuItemCastPipe } from './pipe/menu-item-cast-pipe';
import { MenuSectionCastPipe } from './pipe/menu-section-cast-pipe';
import { OptionNamePipe } from './pipe/option-name.pipe';
import { PercentOfPipe } from './pipe/percent-of.pipe';
import { SpaceToDashesPipePipe } from './pipe/replace-spaces-pipe';
import { TransactionDataSourceCastPipe } from './pipe/transaction-data-source-cast.pipe';

import { FiltersSlideinContainerComponent } from './container/filters-slidein/filters-slidein.container';
import { PushTableContainerComponent } from './container/push-table/push-table.container';
import { CurrencyFormatterDirective } from './directive/currency-formatter.directive';
import { DecimalFormatterDirective } from './directive/decimal-formatter.directive';
import { DisableControlDirective } from './directive/disable-control.directive';
import { FileDropDirective } from './directive/file-drop.directive';
import { IfColumnToggledDirective } from './directive/if-column-toggled.directive';
import { IfHasAuthorityDirective } from './directive/if-has-authority.directive';
import { IfHasChartDataAvailableDirective } from './directive/if-has-chart-data-available.directive';
import { IfHasFeatureToggleDirective } from './directive/if-has-feature-toggle.directive';
import { IfHasMenuSectionAuthorityDirective } from './directive/if-has-menu-section-authority.directive';
import { IfSatisfiesAuthoritySpecDirective } from './directive/if-satisfies-authority-spec.directive';
import { OnlyDateInputDirective } from './directive/only-date-input/only-date-input.directive';
import { OutsideClickMonitorDirective } from './directive/outside-click-monitor.directive';
import { SelectReadonlyDirective } from './directive/select-readonly.directive';
import { SlideinUnsavedChangesDirective } from './directive/slidein-unsaved-changes.directive';
import { SortableColumnHeaderDirective } from './directive/sortable-column-header.directive';
import { ToasterAccessibilityDirective } from './directive/toaster-accessibility.directive';
import { TrimDirective } from './directive/trim.directive';
import { WarningValidationDirective } from './forms/warning-validation.directive';
import { AliasFormValidations } from './validation/alias-form-validations';
import { FileFormatSharedControlsValidations } from './validation/file-format-shared-controls-validations';
import { ReportFormValidations } from './validation/report-form-validations';
import { UserDefinitionFormValidations } from './validation/user-definition-form-validations';
import {
  NgbAccordionModule,
  NgbAlertModule,
  NgbDatepickerModule,
  NgbModalModule,
  NgbPopoverModule,
  NgbTimepickerModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

const exportedComponents = [
  PointsComponent,
  PaginationComponent,
  PageNumberComponent,
  LogoDisplayComponent,
  HeaderComponent,
  HeaderFundingAlertsComponent,
  HeaderMissingFilesComponent,
  MenuAsideComponent,
  ResponsiveUiBlockComponent,
  FormTextRowComponent,
  FormTextGroupRowComponent,
  FormTextAreaComponent,
  FormTelephoneRowComponent,
  FormSelectRowComponent,
  FormCheckboxRowComponent,
  FormUploadLogoComponent,
  AddressComponent,
  ContactDetailsComponent,
  ContactTableCellComponent,
  PreviewHeaderComponent,
  BackButtonComponent,
  DataTableComponent,
  TextTableHeaderComponent,
  TextTableRowComponent,
  TableActionsComponent,
  ActionsComponent,
  VersionsViewHeaderComponent,
  NewVersionModalComponent,
  ModalContainerComponent,
  ModalExportComponent,
  NewPushRequestModalComponent,
  ConfirmationModalComponent,
  EntityVersionHeaderComponent,
  SlideinContainerComponent,
  TabsComponent,
  PushTableContainerComponent,
  PushRowComponent,
  FormNumberRowComponent,
  FormDatepickerRowComponent,
  ClientDetailPreviewComponent,
  LineChartComponent,
  PieChartComponent,
  PushRequestReviewModalComponent,
  SearchRowComponent,
  FormDecimalRowComponent,
  FormDecimalGroupRowComponent,
  ButtonControlsComponent,
  TransitionsComponent,
  FormPercentageRowComponent,
  FormCurrencyAmountRowComponent,
  RangesComponent,
  RangesFormComponent,
  RangesListComponent,
  RangesListRowComponent,
  ModalLoaderComponent,
  FormRadioButtonGroupRowComponent,
  DoubleDatePickerComponent,
  DoubleDatetimePickerComponent,
  ValidatedAmountWithCurrencyComponent,
  AccordionComponent,
  SpringPageNumberComponent,
  SybasePageNumberComponent,
  SortableTableColumnComponent,
  OutletPlaceholderComponent,
  HeaderAlertsComponent,
  DateTimePickerComponent,
  FormDateTimePickerRowComponent,
  FormDoubleDatePickerRowComponent,
  AliasesMissingComponent,
  AliasMissingSearchComponent,
  AliasMissingRowComponent,
  AliasesAwaitingApprovalRowComponent,
  AliasesAwaitingApprovalComponent,
  AliasesAwaitingApprovalSearchComponent,
  AliasesMissingSlideinContainerComponent,
  AliasMissingRowComponent,
  AliasesManagementComponent,
  AliasesManagementSearchComponent,
  AliasesManagementListComponent,
  AliasesManagementListRowComponent,
  AliasesManagementSlideinContainerComponent,
  ListAliasMappingsComponent,
  ListAliasMappingTableRowComponent,
  AliasesAwaitingApprovalSearchComponent,
  FormMultiSelectRowComponent,
  FormChipsInputRowComponent,
  FormDecimalMinMaxRowComponent,
  ListAliasMappingTableRowComponent,
  ChartLegendItemComponent,
  FormTransactionInstanceComponent,
  CreateSellerFormComponent,
  CreateSellerSlideinComponent,
  SellerBankAccountSlideinContainerComponent,
  SellerBankAccountComponent,
  SellerAccountsListRowComponent,
  NotificationComponent,
  GlobalNotificationComponent,
  PointsListComponent,
  PointsFormComponent,
  PointsListRowComponent,
  RegionsDropdownComponent,
  FormTimepickerStartEndRowComponent,
  FormCalendarRowComponent,
  AuthorizationTokenChangeModalComponent,
  UserAuthContextChangeComponent,
  FormNumberRowDefaultValidationMessagesComponent,
  FiltersSlideinContainerComponent,
  FormTreeSelectRowComponent,
  TreeSelectOptionComponent,
  ToggleColumnsDropdownComponent,
  RoutingSelectComponent,
];

const exportedPipes = [
  TransactionInstanceToStringPipe,
  EllipsisPipe,
  LocalisedDatePipe,
  FirstErrorPipe,
  LocalisedNumberPipe,
  LocalisedMoneyPipe,
  CurrencyPipe,
  PercentOfPipe,
  DecimalValuePipe,
  BytePipe,
  MaxPipe,
  MenuItemCastPipe,
  MenuSectionCastPipe,
  SpaceToDashesPipePipe,
  EnumerationTranslatePipe,
  TransactionDataSourceCastPipe,
  OptionNamePipe,
  TransactionDataSourceCastPipe,
  FilterByPipe,
  EntityNamePipe,
];

const exportedDirectives = [
  IfHasAuthorityDirective,
  IfSatisfiesAuthoritySpecDirective,
  CurrencyFormatterDirective,
  SlideinUnsavedChangesDirective,
  IfHasChartDataAvailableDirective,
  IfHasFeatureToggleDirective,
  OnlyDateInputDirective,
  DecimalFormatterDirective,
  FileDropDirective,
  IfHasMenuSectionAuthorityDirective,
  DisableControlDirective,
  ToasterAccessibilityDirective,
  SortableColumnHeaderDirective,
  TrimDirective,
  NgbRefDirective,
  SelectComparatorReadableDictionaryDirective,
  SelectComparatorFlatIdDirective,
  IfColumnToggledDirective,
  WarningValidationDirective,
  OutsideClickMonitorDirective,
];

/*
 https://angular.io/docs/ts/latest/guide/ngmodule.html#!#shared-modules
 */
@NgModule({
  exports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    NgbAlertModule,
    NgbAccordionModule,
    ToasterModule,
    TransactionDataSourceCastPipe,
    AccessibilityModule,
    ComponentsModule,
    ...exportedPipes,
    ...exportedComponents,
    ...exportedDirectives,
  ],
  imports: [
    CommonModule,
    FormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    RouterModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    NgbPopoverModule,
    NgbModalModule,
    NgbAlertModule,
    NgbAccordionModule,
    TranslateModule.forChild({
      extend: true,
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useExisting: MissingTranslationService,
      },
    }),
    ToasterModule.forChild(),
    NgSelectModule,
    DragDropModule,
    ComponentsModule,
    AccessibilityModule,
    OverlayModule,
  ],
  providers: [
    FileFormatSharedControlsValidations,
    UserDefinitionFormValidations,
    AliasFormValidations,
    ToasterService,
    TableSortService,
    LocalisedNumberPipe,
    DecimalValuePipe,
    ModalService,
    StandardIndustryCodesService,
    CurrencyPipe,
    ChartGenerateService,
    DataTableFocusOnSortService,
    LocalisedMoneyPipe,
    EnvironmentTransactionResourceService,
    PrefixTranslationService,
    ChartExportService,
    ChartExportResourceService,
    RouterLinkActive,
    EllipsisPipe,
    EntityNamePipe,
    DatePipe,
    CdkDropList,
    ReportFormValidations,
  ],
  declarations: [
    FormTransactionInstanceComponent,
    TransactionInstanceToStringPipe,
    ProfileDropdownComponent,
    LogoComponent,
    CurrenciesDropdownComponent,
    LocalisationsDropdownComponent,
    MenuSectionComponent,
    MenuItemComponent,
    MenuSignOutComponent,
    MenuHelpComponent,
    DisableControlDirective,
    SelectReadonlyDirective,
    TransactionDataSourceCastPipe,
    AliasesManagementCurrencySelectorGroupComponent,
    AliasesManagementCountrySelectorGroupComponent,
    ...exportedPipes,
    ...exportedComponents,
    ...exportedDirectives,
  ],
})
export class SharedModule {}
