import { InjectionToken } from '@angular/core';

import { yFilesLicenseConfig } from '@demica/resources/yfiles-license-config';

export interface AppEnvironment {
  production: boolean;
  applicationVersion: string;
  profiler: boolean;
  yFilesLicenseConfig: yFilesLicenseConfig;
}

export const APP_ENVIRONMENT = new InjectionToken<AppEnvironment>('APP_ENVIRONMENT');
