import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
  EntityId,
  Environment,
  EnvironmentResourceService,
  PushEligibleEnvironment,
  RelatedSubjects,
  Version,
} from '@demica/core/core';

import { requiredMsg } from '../../forms/validation-messages/common-validation-messages';
import { requireSelect } from '../../forms/validators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export type NewPushRequestForm = FormGroup<{
  environment: FormControl<PushEligibleEnvironment>;
}>;

@Component({
  selector: 'trf-new-push-request-modal',
  templateUrl: 'new-push-request-modal.component.html',
  styleUrls: ['./new-push-request-modal.component.sass'],
})
export class NewPushRequestModalComponent implements OnInit {
  @Input()
  pushAction: (any: unknown) => Observable<unknown>;
  @Input()
  version: Version;
  @Input()
  loading: boolean;
  @Output()
  selectedEnvironment = new EventEmitter<Environment>();

  form: NewPushRequestForm = this._fb.group({
    environment: [null as PushEligibleEnvironment, [requireSelect]],
  });
  submitted = false;
  requireValidations = requiredMsg(this.form, () => this.submitted);
  validationErrors: Set<EntityId> = new Set();
  internalRelatedSubjects: RelatedSubjects[] = [];
  loadingEnvironments = true;
  environmentOptions: PushEligibleEnvironment[] = [];

  private _destroyRef = inject(DestroyRef);
  private _pushInProgress: boolean;

  constructor(
    private _fb: FormBuilder,
    private _modal: NgbActiveModal,
    private _environmentResource: EnvironmentResourceService,
  ) {}

  @Input()
  set relatedSubjects(value: RelatedSubjects[]) {
    this.internalRelatedSubjects = value;
    this.buildErrorMessage();
  }

  ngOnInit(): void {
    this._environmentResource
      .getPushEligibleEnvironments(this.version.entityId)
      .pipe(
        tap((environmentOptions) => {
          this.environmentOptions = environmentOptions;
          this.loadingEnvironments = false;
        }),
        takeUntilDestroyed(this._destroyRef),
      )
      .subscribe();

    this.form.valueChanges.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((value) => {
      if (value.environment != null) {
        this.loading = true;
        this.selectedEnvironment.emit(value.environment);
      } else {
        this.internalRelatedSubjects = [];
      }
      this.validationErrors = new Set();
    });
  }

  errorsExists(): boolean {
    return !!this.validationErrors.size;
  }

  buildErrorMessage(): void {
    const mainObjectValidationError = this.form.controls.environment.value.validationError;

    const validationErrors = this.internalRelatedSubjects
      .filter((subject) => !!subject.validationError)
      .map((subject) => subject.validationError.entityId);

    if (mainObjectValidationError) {
      validationErrors.push(mainObjectValidationError.entityId);
    }

    this.validationErrors = new Set(validationErrors);
  }

  onSave(): void {
    if (this._pushInProgress) return;
    if (this.errorsExists()) return;
    this.submitted = true;
    if (!this.form.valid) return;

    this._pushInProgress = true;
    const data = {
      environmentId: this.form.getRawValue().environment.entityId,
      versionId: this.version.entityId,
    };
    this.pushAction(data).subscribe({
      next: () => this._modal.dismiss(),
      error: (e) => console.error(e),
      complete: () => (this._pushInProgress = false),
    });
  }

  onClose(): void {
    this._modal.dismiss();
  }
}
