export interface ComponentConfiguration<
  C = any,
  I = any,
  O = { [key: string]: (data: unknown) => void },
> {
  component: C;
  inputs?: I;
  outputs?: O;
}

export function createComponentConfiguration<C, I, O>(
  config: ComponentConfiguration<C, I, O>,
): ComponentConfiguration<C, I, O> {
  return {
    component: config.component,
    inputs: config.inputs,
    outputs: config.outputs,
  };
}
