<trf-modal-container
  headerText="NEW_PUSH_REQUEST_MODAL.TITLE"
  (modalClose)="onClose()"
  testid="new-push-request-modal"
>
  <form
    [formGroup]="form"
    [ngClass]="{ 'trf-form-submitted': submitted }"
  >
    <div class="trf-section-content">
      <legend>{{ "LEGEND.REQUIRED_ASTERISK" | translate }}</legend>
    </div>
    <div class="trf-section-content">
      <div class="trf-form-fields">
        <ngp-contextual-notifications
          *ngIf="errorsExists()"
          type="warning"
          data-testid="validation-notifications"
        >
          <ul>
            <li *ngFor="let validationError of validationErrors">
              {{ "NEW_PUSH_REQUEST_MODAL.ERROR_MESSAGES." + validationError | translate }}
            </li>
          </ul>
        </ngp-contextual-notifications>

        <trf-form-select-row
          *ngIf="!loadingEnvironments"
          label="NEW_PUSH_REQUEST_MODAL.ENVIRONMENT_LABEL"
          labelClasses="required"
          defaultLabel="NEW_PUSH_REQUEST_MODAL.SELECT_ENVIRONMENT"
          [control]="form.controls.environment"
          [options]="environmentOptions"
          [validations]="requireValidations"
          testid="environment"
        ></trf-form-select-row>

        <ngp-inline-loader [loading]="loading || loadingEnvironments"></ngp-inline-loader>

        <div
          *ngIf="internalRelatedSubjects && internalRelatedSubjects.length && !loading"
          class="pushed-objects"
        >
          <h5>{{ "NEW_PUSH_REQUEST_MODAL.PUSHED_OPCOS" | translate }}</h5>
          <ul class="list-unstyled">
            <li *ngFor="let relatedSubject of internalRelatedSubjects">
              {{ relatedSubject.objectName }} ({{ relatedSubject.code }})
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="trf-modal-controls-divider trf-section-buttons space-between">
      <ngp-button
        key="NEW_PUSH_REQUEST_MODAL.BUTTON_CANCEL"
        classes="tertiary"
        (click)="onClose()"
        testid="push-request-modal-cancel"
      ></ngp-button>

      <ngp-button
        key="NEW_PUSH_REQUEST_MODAL.BUTTON_PUSH"
        [classes]="loading || errorsExists() ? 'inactive' : 'primary'"
        (click)="onSave()"
        testid="push-request-modal-save"
      ></ngp-button>
    </div>
  </form>
</trf-modal-container>
