import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ReceivableType } from '@demica/resources/common';

import { toData } from '../../../../service/rest/response-mapping';

import { EntityId } from '../../../../interface/has-entity-id.interface';
import { IsValidResponse } from '../../../../model/is-valid-response.interface';
import { RestResponse } from '../../../../model/response.interface';
import {
  AvailabilityResponse,
  CodeAvailabilityResponse,
} from '../../../../model/rest-response/availability-response';
import { encodeParams } from '../../../../security/encode-params';
import { encodeEndpoint } from '../../../../security/encode-url';
import { AssetTypeSearchParam } from '../model/asset-type-search-param';
import { ReceivableTypeTemplates } from '../model/receivable-type-templates.interface';
import { TemplateReceivableTypeGroup } from '../model/template-receivable-type-group.interface';

@Injectable()
export class ReceivableTypeResourceService {
  constructor(private _http: HttpClient) {}

  getReceivableTypes(
    transactionId: number,
    entityRevision: number,
    versionPreviewMode: boolean,
    searchParams?: AssetTypeSearchParam,
    sort?: string,
  ): Observable<ReceivableType[]> {
    const params = encodeParams({ sort });
    const url = versionPreviewMode
      ? encodeEndpoint(
          'resources/transactions/{}/entity-revisions/{}/receivable-types/search',
          transactionId,
          entityRevision,
        )
      : encodeEndpoint('resources/transactions/{}/receivable-types/search', transactionId);
    return this._http
      .post<RestResponse<ReceivableType[]>>(url, searchParams, { params })
      .pipe(map(toData));
  }

  deleteReceivableType(
    transactionId: number,
    receivableTypeId: EntityId,
    entityRevision: number,
  ): Observable<ReceivableType> {
    const params = encodeParams({ entityRevision });
    const url = encodeEndpoint(
      'resources/transactions/{}/receivable-types/{}',
      transactionId,
      receivableTypeId,
    );
    return this._http.delete<RestResponse<ReceivableType>>(url, { params }).pipe(map(toData));
  }

  getReceivableType(
    transactionId: number,
    receivableTypeId: EntityId,
    entityRevision: number,
    versionPreviewMode: boolean,
  ): Observable<ReceivableType> {
    const url = versionPreviewMode
      ? encodeEndpoint(
          'resources/transactions/{}/entity-revisions/{}/receivable-types/{}',
          transactionId,
          entityRevision,
          receivableTypeId,
        )
      : encodeEndpoint(
          'resources/transactions/{}/receivable-types/{}',
          transactionId,
          receivableTypeId,
        );
    return this._http.get<RestResponse<ReceivableType>>(url).pipe(map(toData));
  }

  postReceivableType(transactionId: number, data: ReceivableType): Observable<ReceivableType> {
    const url = encodeEndpoint('resources/transactions/{}/receivable-types', transactionId);
    return this._http.post<RestResponse<ReceivableType>>(url, data).pipe(map(toData));
  }

  putReceivableType(
    transactionId: number,
    receivableTypeId: EntityId,
    data: ReceivableType,
  ): Observable<ReceivableType> {
    const url = encodeEndpoint(
      'resources/transactions/{}/receivable-types/{}',
      transactionId,
      receivableTypeId,
    );
    return this._http.put<RestResponse<ReceivableType>>(url, data).pipe(map(toData));
  }

  getReceivableTypesTemplates(): Observable<ReceivableTypeTemplates[]> {
    const url = encodeEndpoint('resources/templates/receivable-type-groups/not-empty-groups-names');
    return this._http.get<RestResponse<TemplateReceivableTypeGroup[]>>(url).pipe(
      map(toData),
      map((receivableTypeTemplates) =>
        receivableTypeTemplates.map((rtt) => ({
          entityId: rtt.entityId,
          key: rtt.name,
        })),
      ),
    );
  }

  postReceivableTypesFromTemplate(
    transactionId: number,
    data: unknown,
  ): Observable<ReceivableType> {
    const url = encodeEndpoint(
      'resources/transactions/{}/receivable-types/create-from-template',
      transactionId,
    );
    return this._http.post<RestResponse<ReceivableType>>(url, data).pipe(map(toData));
  }

  checkAllCodesAvailableFromTemplate(
    receivableTypeTemplateGroupId: EntityId,
    transactionEntityRevision: number,
    transactionId: number,
  ): Observable<IsValidResponse> {
    const params = encodeParams({ receivableTypeTemplateGroupId, transactionEntityRevision });
    const url = encodeEndpoint(
      'resources/transactions/{}/receivable-types/validate-from-template',
      transactionId,
    );
    return this._http.get<RestResponse<IsValidResponse>>(url, { params }).pipe(map(toData));
  }

  checkReceivableTypeCodeAvailable(
    code: string,
    transactionId: number,
    receivableTypeId?: number,
  ): Observable<CodeAvailabilityResponse> {
    const params = encodeParams({ code, receivableTypeId });
    const url = encodeEndpoint(
      'resources/transactions/{}/receivable-types-code-availability',
      transactionId,
    );
    return this._http
      .get<RestResponse<CodeAvailabilityResponse>>(url, { params })
      .pipe(map(toData));
  }

  checkAnyReceivableTypeAvailable(transactionId: number): Observable<AvailabilityResponse> {
    const url = encodeEndpoint(
      'resources/transactions/{}/receivable-types/any-available',
      transactionId,
    );
    return this._http.get<RestResponse<AvailabilityResponse>>(url).pipe(map(toData));
  }
}
