import { Component, Input } from '@angular/core';

@Component({
  selector: 'ngp-overlay-loader',
  templateUrl: 'overlay-loader.component.html',
  styleUrls: ['./overlay-loader.component.sass'],
})
export class OverlayLoaderComponent {
  @Input() loading = false;
}
