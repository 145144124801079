import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { yFilesLicenseConfig } from '../model/yfiles-license-config.model';

@Injectable({
  providedIn: 'root',
})
export class YFilesLicenseConfigResourceService {
  constructor(private _http: HttpClient) {}

  getYFilesLicenseConfig$(): Observable<yFilesLicenseConfig> {
    return this._http.get<yFilesLicenseConfig>('/assets/yfiles-license-config.json');
  }
}
