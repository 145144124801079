import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormGroupDirective,
  ValidationErrors,
  Validators,
} from '@angular/forms';

import { getFormControlName } from '@demica/core/core';

import { hasError, hasMaxLengthError } from '../../forms/validation-messages/message-predicates';
import { ValidationMessage } from '../../forms/validation-messages/validation-message.interface';

@Component({
  selector: 'trf-form-telephone-row',
  templateUrl: 'form-telephone-row.component.html',
  styleUrls: ['./form-telephone-row.component.sass'],
})
export class FormTelephoneRowComponent implements OnInit {
  @Input()
  numberControl: FormControl;
  @Input()
  countryControl: FormControl;
  @Input()
  label: string;
  @Input()
  countryCodeLabel?: string;
  @Input()
  countryCodePlaceholder?: string;
  @Input()
  numberPlaceholder?: string;

  form: FormGroup;
  formCountryControlName: string;
  formCountryControl: AbstractControl;
  formNumberControlName: string;
  formNumberControl: AbstractControl;

  maxLength = hasMaxLengthError(
    () => this.form,
    () => true,
  );
  codePattern = hasError('pattern')(
    () => this.form,
    () => true,
  );
  requireCC = hasError('require-country-code')(
    () => this.form,
    () => true,
  );
  requireNumber = hasError('require-number')(
    () => this.form,
    () => true,
  );

  countryCodeValidators: ValidationMessage[] = [
    { func: this.codePattern.bind(this), key: 'VALIDATION.TELEPHONE_CODE_FORMAT' },
    { func: this.requireCC.bind(this), key: 'VALIDATION.COUNTRY_CODE_REQUIRED' },
  ];
  numberValidators: ValidationMessage[] = [
    { func: this.maxLength.bind(this), key: 'VALIDATION.MAX_TELEPHONE_NUMBER_LENGTH' },
    { func: this.requireNumber.bind(this), key: 'VALIDATION.TELEPHONE_NUMBER_REQUIRED' },
  ];

  countryCodeChanged: string = null;

  constructor(private fgd: FormGroupDirective) {}

  ngOnInit(): void {
    this.createFormFieldsWithValidators();
  }

  get prefixValid(): boolean {
    return !this.formCountryControl.invalid;
  }

  validateCountryCode(countryCodeField: AbstractControl): ValidationErrors {
    let hasChanged = false;
    if (this.countryCodeChanged !== countryCodeField.value) {
      this.countryCodeChanged = countryCodeField.value;
      hasChanged = true;
    }

    if (this.formNumberControl.value.trim() !== '' && countryCodeField.value.trim() === '')
      return { 'require-country-code': true };

    if (hasChanged) this.formNumberControl.updateValueAndValidity();

    return null;
  }

  validateNumber(numberField: AbstractControl): ValidationErrors {
    if (this.formCountryControl.value.trim() !== '' && numberField.value.trim() === '')
      return { 'require-number': true };

    this.formCountryControl.updateValueAndValidity();

    return null;
  }

  onInputBlur(input: AbstractControl): void {
    input.setValue(input.value.trim());
  }

  // TODO: countryCodeField and numberField should be remove after EPIC TRFV2-22133 is finished
  private createFormFieldsWithValidators(): void {
    this.form = this.fgd.form;

    this.crateCountryFormField();
    this.crateNumberFormField();
  }

  private crateCountryFormField(): void {
    this.formCountryControlName = getFormControlName(this.countryControl);
    this.formCountryControl = this.countryControl || this.form.get(this.formCountryControlName);

    this.formCountryControl.setValidators([
      Validators.pattern(/^\d{0,4}$/),
      this.validateCountryCode.bind(this),
    ]);
  }

  private crateNumberFormField(): void {
    this.formNumberControlName = getFormControlName(this.numberControl);
    this.formNumberControl = this.numberControl || this.form.get(this.formNumberControlName);

    this.formNumberControl.setValidators([
      Validators.maxLength(30),
      this.validateNumber.bind(this),
    ]);
  }
}
