import { Inject, Injectable } from '@angular/core';

import { from, Observable } from 'rxjs';
import { mapTo, switchMap, tap } from 'rxjs/operators';

import {
  AnalyticsRequestsBufferService,
  AnalyticsService,
  AppConfigService,
  YFilesLicenseConfigService,
  AuthService,
  BroadcastChannelInitializerService,
  DictionaryResourceService,
  DictionaryService,
  InactivityLogoutInitializerService,
  KeepAliveSessionInitializerService,
  LOCALE_LOADER,
  LocaleLoader,
  LocalisationService,
  UserAuthContextChangesService,
  UserService,
} from '@demica/core/core';
import { GlobalErrorHandler } from '@demica/error-handler';
import { LoggerService, RemoteLoggerService } from '@demica/logger';
import { License } from '@demica/yfiles';

/**
 * Service for controlled application initialization.
 *
 * Services that have logic that should be run on app startup but are not injected anywhere else should be injected here.
 * Services that require fine-grained control over their dependencies creation should be configured here.
 *
 * For deeper integration with Angular's bootstrapping logic see app.initialization.ts
 */
@Injectable({ providedIn: 'root' })
export class AppBootstrapService {
  constructor(
    private auth: AuthService,
    private appConfigService: AppConfigService,
    private yFilesLicenseConfigService: YFilesLicenseConfigService,
    private userService: UserService,
    private dictionaryResource: DictionaryResourceService,
    private dictionaryService: DictionaryService,
    private readonly globalErrorHandler: GlobalErrorHandler,
    private readonly logger: LoggerService,
    private readonly remoteLogger: RemoteLoggerService,
    private readonly analyticsBuffer: AnalyticsRequestsBufferService,
    private readonly analyticsService: AnalyticsService,
    private readonly broadcastChannelInitializer: BroadcastChannelInitializerService,
    private readonly inactivityLogoutInitializer: InactivityLogoutInitializerService,
    private readonly keepAliveSessionInitializer: KeepAliveSessionInitializerService,
    private readonly userAuthContextChangesService: UserAuthContextChangesService,
    @Inject(LOCALE_LOADER) private readonly localeLoader: LocaleLoader,
  ) {}

  initialize(): Observable<true> {
    return this.appConfigService.appConfig$.pipe(
      switchMap((config) =>
        from(LocalisationService.registerSupportedLocalesData(this.localeLoader, config)).pipe(
          mapTo(config),
        ),
      ),
      tap((config) => {
        this.logger.setLevel(config.logger.level);
        this.logger.setConsoleEnabled(config.logger.console);
        this.globalErrorHandler.setEnabled(config.logger.errorHandler.enabled);
        this.remoteLogger.setEndpoint(config.logger.endpoint);
        this.analyticsBuffer.initBuffer(
          config.analytics.bufferSize,
          config.analytics.bufferTimeout,
        );
        this.analyticsService.initialize(config.analytics.activeConfigurations);
        this.userService.initialize();
        this.broadcastChannelInitializer.initialize();
        this.inactivityLogoutInitializer.initialize();
        this.keepAliveSessionInitializer.initialize();
        this.userAuthContextChangesService.initialize();
      }),
      switchMap(() => this.yFilesLicenseConfigService.yFilesLicenseConfig$),
      tap((yFilesConfig) => (License.value = yFilesConfig)),
      switchMap(() => this.userService.currentUser),
      switchMap(() => this.dictionaryResource.getDictionaries()),
      tap((dictionaries) => this.dictionaryService.initialize(dictionaries)),
      mapTo(true),
    );
  }
}
