/*
  Directory name for trf client specific files: theme, translations etc...
 */
import * as pathConfig from './global-paths-configuration.json';

function globalConfiguration(): string {
  return 'global-configuration';
}

const {
  PUBLIC_APP_PATH,
  SESSION_TIMEOUT_PATH,
  POST_LOGOUT,
  CLIENT_ASSETS_DIR,
  PRIVACY_NOTICE_PATH,
} = pathConfig;

const getURIToPathInPublicApp = (lang: string, path: string) =>
  [window.location.origin, PUBLIC_APP_PATH, lang, path].join('/');

const getPublicSessionTimeoutURI = (lang: string) =>
  getURIToPathInPublicApp(lang, SESSION_TIMEOUT_PATH);
const getPublicPostLogoutPageURI = (lang: string) => getURIToPathInPublicApp(lang, POST_LOGOUT);

/* used in other places
1  app-config.json
2  {theme}/vars.css => --trf-brand-logo
3  index.html => favicon + link:css:varsc.css
4  <root>/deployment/docker/configure-nginx.ts

 */

export {
  PUBLIC_APP_PATH,
  SESSION_TIMEOUT_PATH,
  POST_LOGOUT,
  CLIENT_ASSETS_DIR,
  PRIVACY_NOTICE_PATH,
  getURIToPathInPublicApp,
  getPublicSessionTimeoutURI,
  getPublicPostLogoutPageURI,
  globalConfiguration,
};
