import { BehaviorSubject, Observable } from 'rxjs';

import { Order, SortParams } from '../interface/sort-params.interface';
import { Sorter } from '../interface/sorter.interface';
import { DatePoint } from '../model/date-point';
import { reverseComparator } from './collection';

export class DataSorterUtils<DATA_TYPE> implements Sorter<DATA_TYPE> {
  private sortResultSubject = new BehaviorSubject<DATA_TYPE[]>([]);

  constructor(private sortParams: SortParams) {}

  sortData(data: DATA_TYPE[]): void {
    const result = this.sort(this.sortParams, data);
    this.sortResultSubject.next(result);
  }

  sortDataByParams(params: SortParams, data: DATA_TYPE[]): void {
    this.sortParams = params;
    const result = this.sort(params, data);
    this.sortResultSubject.next(result);
  }

  getResultSorterObservable(): Observable<DATA_TYPE[]> {
    return this.sortResultSubject.asObservable();
  }

  private sort(params: SortParams, data: DATA_TYPE[]): DATA_TYPE[] {
    const comparator =
      params.order === Order.ASC ? params.comparator : reverseComparator(params.comparator);
    return data.sort(comparator);
  }
}

export function sortByDateAsc<DATA_TYPE extends DatePoint<unknown>>(
  a: DATA_TYPE,
  b: DATA_TYPE,
): number {
  return new Date(a.date).valueOf() - new Date(b.date).valueOf();
}

export function sortByDateDesc<DATA_TYPE extends DatePoint<unknown>>(
  a: DATA_TYPE,
  b: DATA_TYPE,
): number {
  return new Date(b.date).valueOf() - new Date(a.date).valueOf();
}

export function getLastValues<DATA_TYPE extends DatePoint<unknown>>(
  valueChart: DATA_TYPE[],
): DATA_TYPE['values'] {
  if (valueChart?.length === 1) {
    return valueChart[0].values;
  } else if (valueChart?.length > 1) {
    return valueChart[valueChart.length - 1].values;
  } else {
    return [];
  }
}
