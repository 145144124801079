import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { maxIntegerValue, TypedChanges } from '@demica/core/core';

import { findIndexOf, getValidationMessages, uniqPointValidator } from '../../helpers';

import { ValidationMessage } from '../../../../forms/validation-messages/validation-message.interface';
import { validateNotEmpty } from '../../../../forms/validators';
import { PointValue } from '../../../../model/pointValue';
import { EditedPoint } from '../../model/edited-point.interface';

type PointValueType = number | string;

export type PointsForm = FormGroup<{
  x: FormControl<PointValueType>;
  value: FormControl<PointValueType>;
}>;

export type PointsFormValidationMessages = Partial<
  Record<keyof PointsForm['value'], ValidationMessage[]>
>;

@Component({
  selector: 'trf-points-form',
  templateUrl: './points-form.component.html',
  styleUrls: ['./points-form.component.sass'],
})
export class PointsFormComponent implements OnChanges {
  @Input()
  pointToEdit: EditedPoint;
  @Input()
  points: PointValue[];
  @Input()
  pointLabel: string;
  @Input()
  pointPlaceholder: string;
  @Input()
  numberOfDecimalPlacesForValue: number;
  @Input()
  numberOfDecimalPlacesForX: number;

  @Output()
  editComplete = new EventEmitter<EditedPoint>();

  submitted = false;
  isXReadOnly: boolean;

  readonly maxIntegerValue = maxIntegerValue;

  form: PointsForm = this._fb.group({
    x: [
      null as PointValueType,
      [validateNotEmpty, uniqPointValidator((fc: AbstractControl) => this.isPointUnique(fc))],
    ],
    value: [null as PointValueType, [validateNotEmpty]],
  });

  validations: PointsFormValidationMessages = getValidationMessages(
    () => this.form,
    () => this.submitted,
  );

  constructor(private _fb: FormBuilder) {}

  ngOnChanges(changes: TypedChanges<PointsFormComponent>): void {
    if (changes.pointToEdit) {
      this._initForm();
      this._shouldBeReadOnly();
    }
  }

  cancel(): void {
    this.editComplete.emit();
  }

  save(): void {
    this.submitted = true;
    if (this.form.invalid) return;
    this.editComplete.emit({ ...this.pointToEdit, point: this.form.getRawValue() });
  }

  isPointUnique(fc: AbstractControl): boolean {
    if (!fc.value || !Array.isArray(this.points)) return true;
    const existingPoints = [...this.points];
    if (this.pointToEdit.index !== -1) existingPoints.splice(this.pointToEdit.index, 1);
    return findIndexOf(existingPoints, { x: fc.value }) === -1;
  }

  private _shouldBeReadOnly(): void {
    if (this.pointToEdit.index > 0) this.isXReadOnly = false;
    if (this.pointToEdit.point.x === '0') this.isXReadOnly = true;
  }

  private _initForm(): void {
    this.form.patchValue({
      ...this.pointToEdit.point,
      value: this.pointToEdit.point.value,
    });
  }
}
