import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { encodeEndpoint, PostResponse } from '@demica/resources/common';

import { AnalyticsRequest } from '../model/analytics.interface';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsResourceService {
  constructor(private _http: HttpClient) {}

  sendAnalyticsBeacon(payload: AnalyticsRequest): boolean {
    const url = encodeEndpoint('resources/analytics');
    const blob = new Blob([JSON.stringify(payload)], { type: 'application/json' });

    return navigator.sendBeacon(url, blob);
  }

  // was makeRequest > _makeRequest > persistAnalyticsEvents$
  persistAnalyticsEvents$(payload: AnalyticsRequest): Observable<PostResponse> {
    const url = encodeEndpoint('resources/analytics');

    return this._http.post<PostResponse>(url, payload);
  }
}
