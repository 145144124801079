import { CdkAccordionModule } from '@angular/cdk/accordion';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TableKeyHandlerDirective, TableRowAttributesSetterDirective } from '@demica/accessibility';

import { ButtonComponent } from './components/button/button.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { DataExportComponent } from './components/data-export/data-export.component';
import { DragAndDropNestedItemComponent } from './components/drag-and-drop-nested/drag-and-drop-nested-item/drag-and-drop-nested-item.component';
import { DragAndDropNestedComponent } from './components/drag-and-drop-nested/drag-and-drop-nested.component';
import { EmptyComponent } from './components/empty/empty.component';
import { InlineLoaderWithStatusComponent } from './components/inline-loader-with-status/inline-loader-with-status.component';
import { InlineLoaderComponent } from './components/inline-loader/inline-loader.component';
import { NotAvailableResourceComponent } from './components/not-available-resource/not-available-resource.component';
import { NotDefinedComponent } from './components/not-defined/not-defined.component';
import { ContextualNotificationBodyComponent } from './components/notifications/contextual-notification-body/contextual-notification-body.component';
import { ContextualNotificationsComponent } from './components/notifications/contextual-notifications/contextual-notifications.component';
import { CustomWarningNotificationComponent } from './components/notifications/custom-warning-notification/custom-warning-notification.component';
import { InlineNotificationComponent } from './components/notifications/inline-notification/inline-notification.component';
import { OverlayLoaderComponent } from './components/overlay-loader/overlay-loader.component';
import { RadioButtonComponent } from './components/radio-button/radio-button.component';
import { ReadonlyTextControlComponent } from './components/readonly/readonly-text-control/readonly-text-control.component';
import { SvgIconComponent } from './components/svg-icon/svg-icon.component';
import { FilterTabsPipe } from './components/tabs/filter-tabs.pipe';
import { TabComponent } from './components/tabs/tab/tab.component';
import { NgpTabsComponent } from './components/tabs/tabs/tabs.component';
import { TextEllipsisComponent } from './components/text-ellipsis/text-ellipsis/text-ellipsis.component';
import { ToggleIconComponent } from './components/toggle-icon/toggle-icon.component';
import { TooltipIconComponent } from './components/tooltip-icon/tooltip-icon.component';
import { TranslatableListComponent } from './components/translatable-list/translatable-list.component';
import { TooltipComponent } from './directives/tooltip/tooltip-component/tooltip.component';

import { DefaultTranslatePipe } from './pipe/default-translate.pipe';
import { TextEllipsisPipe } from './pipe/text-ellipsis.pipe';

import { OverlayLoaderWrapperDirective } from './directives/overlay-loader/overlay-loader-wrapper.directive';
import { DragAndDropNestedDirective } from './directives/tooltip/drag-and-drop-nested.directive';
import { NgpTooltipOnHoverDirective } from './directives/tooltip/ngp-tooltip-on-hover.directive';

export const exportedComponents = [
  ButtonComponent,
  SvgIconComponent,
  CheckboxComponent,
  DataExportComponent,
  InlineNotificationComponent,
  ContextualNotificationBodyComponent,
  ContextualNotificationsComponent,
  RadioButtonComponent,
  EmptyComponent,
  InlineLoaderComponent,
  InlineLoaderWithStatusComponent,
  TooltipIconComponent,
  NgpTabsComponent,
  TabComponent,
  DragAndDropNestedComponent,
  DragAndDropNestedItemComponent,
  NotDefinedComponent,
  TextEllipsisComponent,
  NotAvailableResourceComponent,
  TranslatableListComponent,
  CustomWarningNotificationComponent,
  ToggleIconComponent,
  ReadonlyTextControlComponent,
  OverlayLoaderComponent,
];

export const exportedDirectives = [
  DragAndDropNestedDirective,
  NgpTooltipOnHoverDirective,
  OverlayLoaderWrapperDirective,
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    OverlayModule,
    DragDropModule,
    RouterModule,
    TableKeyHandlerDirective,
    TableRowAttributesSetterDirective,
    CdkAccordionModule,
  ],
  exports: [...exportedComponents, ...exportedDirectives],
  declarations: [
    DefaultTranslatePipe,
    TextEllipsisPipe,
    TooltipComponent,
    FilterTabsPipe,
    ...exportedDirectives,
    ...exportedComponents,
  ],
})
export class ComponentsModule {}
