import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

import { forkJoin, Observable, of } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { EntityReference, FeatureToggleService, ORGANIZATION_UNITS } from '@demica/core/core';
import {
  CalendarResourceService,
  CurrentRegionCalendarResourceService,
} from '@demica/resources/calendar';

import { ValidationMessage } from '../../forms/validation-messages/validation-message.interface';
import { CalendarGroup } from './calendar-group.model';

@Component({
  selector: 'trf-form-calendar-row',
  templateUrl: 'form-calendar-row.component.html',
  styleUrls: ['./form-calendar-row.component.sass'],
})
export class FormCalendarRowComponent implements OnInit {
  @Input()
  name?: string;
  @Input()
  control?: FormControl;
  @Input()
  label: string;
  @Input()
  labelClasses?: string;
  @Input()
  defaultLabel?: string;
  @Input()
  translationPrefix?: string | string[];
  @Input()
  validations?: ValidationMessage[];
  @Input()
  disabled: boolean;
  @Input()
  helperToolTip?: string;
  @Input()
  clearable = true;
  @Input()
  searchable = true;
  @Input()
  infoTooltip?: string;
  @Input()
  ariaDescText?: string;
  @Input()
  ariaDescId?: string;
  @Input()
  regionId: number = null;
  @Input()
  transactionId?: number;
  @Input()
  transactionRevision?: number;
  @Input()
  versionPreviewMode?: boolean;

  @Output()
  selected = new EventEmitter<unknown>();

  options$: Observable<CalendarGroup[]>;
  groupByKey = 'calendars';
  loading = false;

  constructor(
    private calendarResourceService: CalendarResourceService,
    private currentRegionCalendarResourceService: CurrentRegionCalendarResourceService,
    private translateService: TranslateService,
    private featureToggleService: FeatureToggleService,
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.options$ = forkJoin([this.getTransactionCalendars(), this.getRegionCalendars()]).pipe(
      map(([transactionCalendars, regionCalendars]): CalendarGroup[] => {
        const groups: CalendarGroup[] = [];

        if (transactionCalendars.length) {
          groups.push({
            groupName: this.translateService.instant('CALENDAR_DROPDOWN.TRANSACTION_CALENDARS'),
            calendars: transactionCalendars,
          });
        }

        if (regionCalendars.length) {
          groups.push({
            groupName: this.translateService.instant('CALENDAR_DROPDOWN.REGION_CALENDARS'),
            calendars: regionCalendars,
          });
        }

        return groups;
      }),
      finalize(() => {
        this.loading = false;
      }),
    );
  }

  private getTransactionCalendars(): Observable<EntityReference[]> {
    if (this.transactionId) {
      return this.calendarResourceService.getCalendarsDictionary$(
        this.transactionId,
        this.transactionRevision,
        this.versionPreviewMode && !!this.transactionRevision,
      );
    }

    return of([]);
  }

  private getRegionCalendars(): Observable<EntityReference[]> {
    if (this.featureToggleService.hasEnabled(ORGANIZATION_UNITS)) {
      return this.currentRegionCalendarResourceService.getCalendarsDictionary$(this.regionId);
    }

    return of([]);
  }
}
