import { DictionaryEntry, EntityReference } from '@demica/resources/common';

import { FeeKind } from './fee-kind.enum';

export enum TransactionTypes {
  'STRUCTURED_RF' = 1,
  'TRANSACTION_LEVEL_FUNDING' = 3,
}

export interface FeeTypeResponse extends EntityReference {
  enabled: boolean;
  defaultTranslation: string;
  translationKey: string;
  feeKinds: FeeKind[];
  transactionTypes: TransactionTypes[];
}

export type FeeType = FeeTypeResponse &
  DictionaryEntry & {
    disabled: boolean;
  };
