import { BidiModule } from '@angular/cdk/bidi';
import { CDK_DRAG_CONFIG } from '@angular/cdk/drag-drop';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
  MissingTranslationHandler,
  TranslateLoader,
  TranslateModule,
  TranslatePipe,
  TranslateService,
} from '@ngx-translate/core';

import { DELEGATE_TRANSLATE_PIPE, SVG_ICON_HREF } from '@demica/components';
import {
  ANALYTICS_STORE,
  AnalyticsStoreService,
  AppConfigService,
  AuthService,
  BlobErrorHttpInterceptor,
  ConnectionVerifier,
  CoreModule,
  CustomTranslateLoader,
  FakeTranslationGeneratorService,
  HttpErrorInterceptor,
  IndexedDBService,
  MENU_ITEMS_CONFIG_TOKEN,
  MissingTranslationService,
  MockHttpInterceptor,
  COOKIE_CONSENT_PROVIDER,
  OneTrustService,
  StatesService,
  ThemingService,
  UserRequestAuditInterceptor,
  cookieConsentFactory,
  REMOTE_CONFIG_PROVIDER,
  APP_ENVIRONMENT,
  APP_INITIALIZER_AFTER_CONFIG,
  appConfigInitializerFactory,
} from '@demica/core/core';
import { ErrorHandlerModule } from '@demica/error-handler';
import { DefaultLoggerService, LoggerService } from '@demica/logger';
import { TranslationsResourceService } from '@demica/resources/translations';
import { SharedModule } from '@demica/shared';
import { ToasterModule } from '@demica/toaster';

import { AppComponent } from './app.component';

import { environment } from '../environments/environment';
import { appInitializerFactory } from './app.initializer';
import { routing } from './app.routes';
import { AuthModule } from './auth.module';
import { menuConfig } from './config/menu-config';
import { DevToolsModule } from './dev-tools/dev-tools.module';
import { HealthCheckModule } from './feature-modules/health-check/health-check.module';
import { InsurersModule } from './feature-modules/insurers/insurers.module';
import { NoAccessModule } from './feature-modules/no-access/no-access.module';
import { OnboardingModule } from './feature-modules/onboarding/view/onboarding.module';
import { LayoutAuthComponent } from './pages/auth/auth';
import { NgSelectModule } from '@ng-select/ng-select';

const featureModules = [InsurersModule, OnboardingModule, NoAccessModule, HealthCheckModule];

@NgModule({
  declarations: [AppComponent, LayoutAuthComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    DevToolsModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        deps: [TranslationsResourceService, AppConfigService, FakeTranslationGeneratorService],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: MissingTranslationService,
      },
    }),
    CoreModule,
    SharedModule,
    ...featureModules,
    routing,
    NgSelectModule,
    FormsModule,
    ErrorHandlerModule,
    AuthModule,
    BidiModule,
    ToasterModule.forRoot(),
  ],
  providers: [
    ConnectionVerifier,
    {
      provide: APP_ENVIRONMENT,
      useValue: environment,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appConfigInitializerFactory,
      deps: [REMOTE_CONFIG_PROVIDER, APP_INITIALIZER_AFTER_CONFIG],
      multi: true,
    },
    {
      provide: APP_INITIALIZER_AFTER_CONFIG,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
    {
      provide: APP_INITIALIZER_AFTER_CONFIG,
      useFactory: (dbService: AnalyticsStoreService) => () => {
        return dbService.initialize().subscribe();
      },
      deps: [ANALYTICS_STORE],
      multi: true,
    },
    { provide: COOKIE_CONSENT_PROVIDER, useClass: OneTrustService },
    {
      provide: APP_INITIALIZER,
      useFactory: cookieConsentFactory,
      deps: [COOKIE_CONSENT_PROVIDER, AuthService],
      multi: true,
    },
    { provide: ANALYTICS_STORE, useClass: IndexedDBService },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MockHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UserRequestAuditInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BlobErrorHttpInterceptor,
      multi: true,
    },
    {
      provide: DELEGATE_TRANSLATE_PIPE,
      useClass: TranslatePipe,
    },
    {
      provide: MENU_ITEMS_CONFIG_TOKEN,
      useValue: menuConfig,
    },
    {
      provide: LoggerService,
      useClass: DefaultLoggerService,
    },
    { provide: CDK_DRAG_CONFIG, useValue: { zIndex: 1071 } },

    {
      provide: SVG_ICON_HREF,
      deps: [ThemingService],
      useFactory: (themingService: ThemingService) => themingService.spriteFileLocation,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
  constructor(private statesService: StatesService) {
    statesService.init();
  }
}
