<trf-modal-container
  headerText="PUSH_REQUEST_REVIEW_MODAL.TITLE"
  (modalClose)="onClose()"
  testid="push-request-review-modal"
>
  <div class="trf-section-content mt-3">
    <div
      *ngIf="displayAlreadyReviewedWarning"
      class="alert alert-warning"
      role="alert"
    >
      <p>{{ "PUSH_REQUEST_REVIEW_MODAL.ALREADY_REVIEWED_ALERT" | translate }}</p>
    </div>

    <ngp-inline-loader [loading]="loading"></ngp-inline-loader>

    <form
      *ngIf="!loading"
      [formGroup]="form"
      [ngClass]="{ 'trf-form-submitted': submitted }"
    >
      <div class="trf-form-fields">
        <ng-container
          *ngFor="let relatedGroup of form.controls.relatedVersions.controls; let i = index"
        >
          <form
            class="related-versions"
            [formGroup]="relatedGroup"
          >
            <div
              class="related-versions"
              [attr.testid]="'version-' + relatedGroup.controls.subjectVersionId.value"
              [attr.datasubjecttype]="relatedGroup.controls.subjectType.value"
            >
              <span
                class="trf-link"
                (click)="onVersionClick(relatedGroup)"
              >
                {{ relatedGroup.controls.label.value }}
              </span>
              <div class="radio-button-group">
                <ngp-radio-button
                  label="PUSH_REQUEST_REVIEW_MODAL.APPROVE_VERSION"
                  name="approve"
                  [value]="true"
                  [attr.data-testId]="'version-approve-' + i"
                ></ngp-radio-button>

                <ngp-radio-button
                  label="PUSH_REQUEST_REVIEW_MODAL.REJECT_VERSION"
                  name="approve"
                  [value]="false"
                  [attr.data-testId]="'version-reject-' + i"
                ></ngp-radio-button>
              </div>
            </div>
          </form>
        </ng-container>

        <div class="push-to-environment">
          {{ "PUSH_REQUEST_REVIEW_MODAL.PUSH_TO_ENVIRONMENT" | translate }}
          <span class="bold">{{ pushRequest.environment.name }}</span>
        </div>

        <div class="radio-button-group">
          <ngp-radio-button
            label="PUSH_REQUEST_REVIEW_MODAL.APPROVE_PUSH_REQUEST"
            name="approvePush"
            [value]="true"
            testid="approve-push-request"
          ></ngp-radio-button>

          <ngp-radio-button
            label="PUSH_REQUEST_REVIEW_MODAL.REJECT_PUSH_REQUEST"
            name="approvePush"
            [value]="false"
            testid="reject-push-request"
          ></ngp-radio-button>
        </div>
        <div class="comment">
          <trf-form-text-area
            label="PUSH_REQUEST_REVIEW_MODAL.COMMENT"
            name="comment"
            testid="push-request-review-comment"
          ></trf-form-text-area>
        </div>
      </div>
    </form>
  </div>

  <div class="trf-modal-controls-divider py-3 trf-section-buttons space-between">
    <ngp-button
      classes="tertiary"
      key="PUSH_REQUEST_REVIEW_MODAL.BUTTON_CANCEL"
      (click)="onClose()"
      testid="push-request-review-modal-cancel"
    ></ngp-button>

    <ngp-button
      classes="primary {{ loading ? 'inactive' : '' }}"
      key="PUSH_REQUEST_REVIEW_MODAL.BUTTON_PUSH"
      (click)="onSave()"
      testid="push-request-review-modal-save"
    ></ngp-button>
  </div>
</trf-modal-container>
