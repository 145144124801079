import { from } from 'rxjs';
import { map, toArray } from 'rxjs/operators';

import { ChartDataPoint } from '@demica/core/core';

import { MULTIPLE_AXES_X } from '../line-chart-consts';
import { VerticalAxisLineChartValues } from '../vertical-axis-line-chart-values';

const AXIS_X = 'x';

export class Axis {
  private axisY: string;
  private axisX: string;
  private multipleData: boolean;

  withAxisX(value: string) {
    this.axisX = value;

    return this;
  }

  withAxisY(value: string) {
    this.axisY = value;

    return this;
  }

  withMultipleData(isMultipleData: boolean) {
    this.multipleData = isMultipleData;

    return this;
  }

  getAxisY(): string {
    return this.axisY;
  }

  getAxisX(): string {
    return this.multipleData ? this.axisY + MULTIPLE_AXES_X : AXIS_X;
  }
}

export function createAxesMap(axes: Set<Axis>): Map<string, string> {
  const data = new Map<string, string>();
  axes.forEach((axis) => data.set(axis.getAxisY(), axis.getAxisX()));
  return data;
}

export function createVerticalAxisLineChartValues(
  values: string[],
  key: string,
): VerticalAxisLineChartValues {
  return new VerticalAxisLineChartValues(values, key + MULTIPLE_AXES_X);
}

export function getAxisXValues(entry: [string, ChartDataPoint[]]) {
  const [key, datapoints] = entry;
  return from(datapoints).pipe(
    map((datapoint) => datapoint.date),
    toArray(),
    map((values) => new VerticalAxisLineChartValues(values, key + MULTIPLE_AXES_X)),
  );
}
