import { EntityReference, Transaction } from '@demica/core/core';
import { TransactionTypes } from '@demica/resources/fees';

export function isTLFTransaction(transaction: Transaction) {
  return transaction.type.entityId === TransactionTypes.TRANSACTION_LEVEL_FUNDING;
}

export function getTransactionTypeAsEntityReference(
  transactionType: TransactionTypes,
): EntityReference {
  return {
    entityId: transactionType,
    name: TransactionTypes[transactionType],
  };
}
