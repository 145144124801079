import { AppInitializerAfterConfig } from '../token/app-initializer-after-config.token';
import { RemoteConfigProvider } from '../token/remote-config-provider.token';

export function appConfigInitializerFactory(
  remoteConfigProvider: RemoteConfigProvider[],
  configInitializers: AppInitializerAfterConfig[],
) {
  return async () => {
    await Promise.all(remoteConfigProvider.map((provider) => provider.configAsPromise));

    for (const initializer of configInitializers) {
      await initializer();
    }
  };
}
