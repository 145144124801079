import { Component, Input, OnChanges } from '@angular/core';

import { TypedChanges } from '@demica/core/core';

@Component({
  selector: 'ngp-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.sass'],
})
export class ButtonComponent implements OnChanges {
  @Input()
  classes = '';
  @Input()
  icon?: string;
  @Input()
  key?: string = '';
  @Input()
  type?: string;

  disabled = false;

  ngOnChanges(changes: TypedChanges<ButtonComponent>) {
    if (changes.classes) {
      this.disabled = !!this.classes && this.classes.includes('inactive');
    }
  }

  preventClickEvent(event: Event) {
    if (this.disabled) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }
}
