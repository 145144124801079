import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfig, AppConfigResource, FallbackConfig } from '@demica/resources/app-config';

export const APP_CONFIG_FALLBACK_PROPERTIES: (keyof FallbackConfig)[] = [
  'authorizationUrl',
  'decimalCurrencies',
  'featureToggles',
];

@Injectable({
  providedIn: 'root',
})
export class AppConfigFallbackService {
  constructor(private _appConfigResource: AppConfigResource) {}

  mergeBaseConfigWithFallback$(baseConfig: AppConfig): Observable<AppConfig> {
    if (this._hasNeededProperties(baseConfig)) {
      return of(baseConfig);
    }

    return this._appConfigResource.getFallbackConfigFromAssets$().pipe(
      map((fallbackConfig) => ({
        ...fallbackConfig,
        ...baseConfig,
      })),
    );
  }

  private _hasNeededProperties(baseConfig: AppConfig): boolean {
    const properties = Object.keys(baseConfig);

    return APP_CONFIG_FALLBACK_PROPERTIES.every((property) => properties.includes(property));
  }
}
