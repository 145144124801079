import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { from, Observable, throwError } from 'rxjs';
import { catchError, delay, mergeMap, tap } from 'rxjs/operators';

import { AuthService } from '../auth/auth.service';
import { TokenStoreService } from '../auth/token-store.service';

import { isAuthExcludedResource } from '../../config/auth-excluded-resources';
import { AuthInterceptorRetryStrategy } from '../../model/auth/auth-interceptor-retry.strategy';
import { LOGOUT_REASONS } from '../auth/logout-redirect/logout-reasons.enum';

// Time for token to be propagated from keycloak to TokenStore after it's received from API
const TOKEN_UPDATE_PROPAGATION_WINDOW = 500;

@Injectable({ providedIn: 'root' })
export class OidcAuthInterceptorRetryStrategy implements AuthInterceptorRetryStrategy {
  constructor(private auth: AuthService, private tokenStore: TokenStoreService) {}

  // whenever get 401, wait 500ms so keycloak token expire event will:
  // * refresh token and
  // * propagate token between tabs
  renewTokenAndTryAgain(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return from(Promise.resolve()).pipe(
      delay(TOKEN_UPDATE_PROPAGATION_WINDOW),
      tap(() => console.warn('renew token try')),
      mergeMap(() =>
        next.handle(this.configureRequest(req)).pipe(
          catchError((err) => {
            // second try was also unauthenticated, giving up
            if (err.status === 401) this.auth.signOut(LOGOUT_REASONS.UNAUTHORIZED);

            return throwError(err);
          }),
        ),
      ),
    );
  }

  public pickErrorHandler(
    url: string,
    retryHandler: (err: HttpErrorResponse) => Observable<HttpEvent<unknown>>,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    logoutHandler: (err: HttpErrorResponse) => Observable<never>,
  ): (err: HttpErrorResponse) => Observable<HttpEvent<unknown>> {
    return retryHandler;
  }

  public pickFinalizer(
    url: string,
    retryFinalizer: () => boolean,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    logoutFinalizer: () => void,
  ): () => void {
    return retryFinalizer;
  }

  private configureRequest(req: HttpRequest<unknown>) {
    return isAuthExcludedResource(req.url) ? req : this.addAuthorizationHeader(req);
  }

  private addAuthorizationHeader(req: HttpRequest<unknown>) {
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${this.tokenStore.getToken()}`,
      },
    });
  }
}
