import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { toData } from '../../../../service/rest/response-mapping';

import { RestResponse } from '../../../../model/response.interface';
import {
  CodeAvailabilityResponse,
  NameAvailabilityResponse,
} from '../../../../model/rest-response/availability-response';
import { encodeParams } from '../../../../security/encode-params';
import { encodeEndpoint } from '../../../../security/encode-url';

@Injectable()
export class OrganisationResourceService {
  constructor(private http: HttpClient) {}

  /**
   *
   * WHEN MOVING ME TO @resources please move instead specific methods
   * into proper contexts like clients, sellers
   *
   */
  checkClientNameAvailable(name: string, clientId?: number): Observable<boolean> {
    const params = encodeParams({ name, clientId });
    const url = encodeEndpoint('resources/organizations/clients/name-availability');
    return this.http
      .get<RestResponse<NameAvailabilityResponse>>(url, { params })
      .pipe(map((resp) => resp.data.nameAvailable));
  }

  checkClientCodeAvailable(code: string, clientId?: number) {
    const params = encodeParams({ code, clientId });
    const url = encodeEndpoint('resources/organizations/clients/code-availability');
    return this.http.get<RestResponse<CodeAvailabilityResponse>>(url, { params }).pipe(map(toData));
  }

  // SAME IN! demica/libs/resources/src/lib/seller/service/seller-resource.service.ts
  // see: checkSellerNameAvailability$
  checkOpcoNameAvailable(name: string, opcoId?: number) {
    const url = encodeEndpoint('resources/organizations/clients/name-availability');
    const params = encodeParams({ name, opcoId });

    return this.http
      .get<RestResponse<NameAvailabilityResponse>>(url, { params })
      .pipe(map((resp) => resp.data.nameAvailable));
  }

  checkOpcoCodeAvailable(code: string, opcoId?: number) {
    const params = encodeParams({ code, opcoId });
    const url = encodeEndpoint('resources/organizations/clients/code-availability');
    return this.http.get<RestResponse<CodeAvailabilityResponse>>(url, { params }).pipe(map(toData));
  }
}
