import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, ReplaySubject, Subject } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { ApiVersionNumber } from '@demica/resources/common';

import { PageLoaderService } from '../page-loader.service';
import { PAGE_SIZE, PageService } from '../page.service';
import { SybasePageResourceService } from './sybase-page-resource.service';

import {
  extractPageEventResult,
  isSamePageParamsId,
  PageEventContainer,
} from '../page-event-container';
import { PageNumberParams } from '../page-number-params';
import { PageableTableParameters } from '../page-params.interface';
import { SybasePage } from './sybase-page-rows.interface';

const MAX_RECEIVED_ITEMS = 251;
const PAGES_PER_REQUEST = Math.floor(MAX_RECEIVED_ITEMS / PAGE_SIZE);

interface MemoizedState<RESULTS> {
  resultSet: RESULTS[];
  pageNumberParams: PageNumberParams;
  pageParams: PageableTableParameters;
  restUrl: string;
}

@Injectable()
export class SybasePageService<RESULTS> extends PageService<RESULTS> {
  private pageParamsReplaySubject = new ReplaySubject<PageEventContainer<PageNumberParams>>(1);
  private resultSetSubject = new Subject<PageEventContainer<RESULTS[]>>();
  private errorSubject = new Subject<unknown>();

  private pageNumberParams: PageNumberParams;
  private params: {
    pageParams: PageableTableParameters;
    restUrl: string;
  };

  private stateStack: MemoizedState<RESULTS>[] = [];

  private resultSet: RESULTS[] = [];
  private index = MAX_RECEIVED_ITEMS;
  private sortParamsChanged = false;

  constructor(
    private pageResourcesService: SybasePageResourceService,
    private pageLoaderService: PageLoaderService,
  ) {
    super();
  }

  getPageResultSetObservable(): Observable<RESULTS[]> {
    return this.resultSetSubject.pipe(map(extractPageEventResult));
  }

  getPageResultSetObservableFilteredById(pageParamsId: string): Observable<RESULTS[]> {
    return this.resultSetSubject.pipe(
      filter(isSamePageParamsId(pageParamsId)),
      map(extractPageEventResult),
    );
  }

  getPageErrorHandleObservable(): Observable<unknown> {
    return this.errorSubject.asObservable();
  }

  getPageParamsObservable(): Observable<PageNumberParams> {
    return this.pageParamsReplaySubject.pipe(map(extractPageEventResult));
  }

  getPageParamsObservableFilteredById(pageParamsId: string): Observable<PageNumberParams> {
    return this.pageParamsReplaySubject.pipe(
      filter(isSamePageParamsId(pageParamsId)),
      map(extractPageEventResult),
    );
  }

  private hasSortParamsChanged(pageParams: PageableTableParameters) {
    if (this.params === undefined) {
      return false;
    }

    return (
      this.params.pageParams.keysetPageRequest.sort.column !==
        pageParams.keysetPageRequest.sort.column ||
      this.params.pageParams.keysetPageRequest.sort.direction !==
        pageParams.keysetPageRequest.sort.direction
    );
  }

  resolvePage(pageNumber: number, totalItems: number, blockFetch = false): void {
    this.pageNumberParams = this.getPageNumberParams(pageNumber, totalItems);
    this.updateCurrentPageNumberParam();

    if (!blockFetch && this.shouldPostForNewData()) {
      this.getData();
    } else {
      this.resultSetSubject.next({
        pageParams: this.params.pageParams,
        result: this.sliceResult(this.pageNumberParams.startIndex, this.pageNumberParams.endIndex),
      });
    }

    this.pageParamsReplaySubject.next({
      pageParams: this.params.pageParams,
      result: this.pageNumberParams,
    });
  }

  reset() {
    if (this.pageNumberParams) {
      this.pageNumberParams.currentPage = 1;
    }
  }

  private shouldPostForNewData(): boolean {
    const maxCurrentOffsetIndex = this.index - PAGE_SIZE * 2;
    const maxTotalIndex = this.index;
    return (
      this.pageNumberParams.startIndex > maxCurrentOffsetIndex &&
      maxCurrentOffsetIndex < maxTotalIndex &&
      this.resultSet.length % MAX_RECEIVED_ITEMS === 0
    );
  }

  getFirstPage(
    pageParams: PageableTableParameters,
    restUrl: string,
    apiVersion?: ApiVersionNumber,
  ) {
    this.updateParams(pageParams, restUrl);
    this.updateCurrentPageNumberParam();

    this.getData(apiVersion);
  }

  updateParams(pageParams: PageableTableParameters, restUrl: string) {
    this.pageNumberParams = null;
    this.sortParamsChanged = this.hasSortParamsChanged(pageParams);
    pageParams.keysetPageRequest.limit = MAX_RECEIVED_ITEMS;
    this.params = { pageParams, restUrl };
    this.resultSet = [];
    this.index = MAX_RECEIVED_ITEMS;
  }

  /**
   * This method should be used by nested page instances to save the previous state that will need to be reverted
   * {@param until} should be Observable that broadcast message when state should be reverted
   */
  memoizeState(until?: Observable<unknown>) {
    this.stateStack.push({
      resultSet: this.resultSet,
      pageNumberParams: this.pageNumberParams,
      pageParams: { ...this.params.pageParams },
      restUrl: this.params.restUrl,
    });
    if (until) until.pipe(take(1)).subscribe(this.revertState.bind(this));
  }

  revertState() {
    if (this.stateStack.length === 0) {
      console.warn('SybasePageService#revertState(): state stack is empty, can not revert');
      return;
    }

    const state = this.stateStack.pop();
    this.resultSet = state.resultSet;
    this.pageNumberParams = state.pageNumberParams;
    this.params = {
      pageParams: state.pageParams,
      restUrl: state.restUrl,
    };
  }

  private buildHttpParams(maxReceivedItems: number): HttpParams {
    return new HttpParams().set('limit', String(maxReceivedItems));
  }

  private getSybaseData(
    pageParams: PageableTableParameters,
    restUrl: string,
    apiVersion?: ApiVersionNumber,
  ): Observable<SybasePage<RESULTS[]>> {
    return this.pageResourcesService.postForPageResources<RESULTS>(pageParams, restUrl, apiVersion);
  }

  private getData(apiVersion?: ApiVersionNumber) {
    this.pageLoaderService.show(this.params.pageParams);
    this.getSybaseData(this.params.pageParams, this.params.restUrl, apiVersion).subscribe(
      (result) => {
        this.resultSet = this.resultSet.concat(result.rows);
        this.pageLoaderService.hide(this.params.pageParams);

        if (this.index > 0 && result.rows.length > 0) {
          this.params.pageParams.keysetPageRequest.lastRow = result.lastRowParameters;
          this.resolvePage(this.getPageNumberOrFirst(), (this.index = this.resultSet.length));
        } else if (result.rows.length === 0) {
          /* Block further "recursive" (resolvePage calls getData; getData calls resolvePage)
             requests for pages if the current request returned 0 rows.

             TODO: in the future in 2.11+ this code should be removed as this implementation
             still has some Sybase quirks - e.g. the UI (pagination) on first search shows 1..10 pages
             but after selecting the tenth page new pages in pagination can show up - 1..20
             if there is enough data.
             This is a terrible UX. Either we should get total rows number from backend and base
             pagination on that or change the UX if total rows number cannot be retrieved because
             of backend limitations. */
          const blockFetch = true;

          this.resolvePage(this.getPageNumberOrFirst(), this.resultSet.length, blockFetch);
        }
      },
      (error) => this.handleException(error),
    );
  }

  private handleException(error: unknown) {
    if (error instanceof HttpErrorResponse) {
      this.pageLoaderService.hide(this.params.pageParams);
      this.errorSubject.next(error);
    }
  }

  private sliceResult(start: number, end: number): RESULTS[] {
    return this.resultSet.slice(start, end);
  }

  private getPageNumberOrFirst() {
    if (this.sortParamsChanged) {
      this.sortParamsChanged = false;
      return 1;
    }
    return this.pageNumberParams ? this.pageNumberParams.currentPage : 1;
  }

  private updateCurrentPageNumberParam() {
    if (this.pageNumberParams == null || this.pageNumberParams.currentPage == 0) {
      this.params.pageParams.keysetPageRequest.pageNumber = 0;
    } else {
      this.params.pageParams.keysetPageRequest.pageNumber = Math.floor(
        this.pageNumberParams.currentPage / PAGES_PER_REQUEST,
      );
    }
  }
}
