import { DATE_PIPE_DEFAULT_OPTIONS, DatePipe, DatePipeConfig } from '@angular/common';
import { Inject, Optional, Pipe, PipeTransform } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { isDefined, LocalisationService } from '@demica/core/core';

export type SupportedFormatWidth =
  | 'short'
  | 'medium'
  | 'long'
  | 'shortDate'
  | 'shortTime'
  | 'mediumTime'
  | 'longTime';

@Pipe({
  name: 'localisedDate',
  pure: false,
})
export class LocalisedDatePipe implements PipeTransform {
  ready$ = new BehaviorSubject(false);
  private _datePipe: DatePipe;
  private _ready = false;

  constructor(
    private localisationService: LocalisationService,
    @Optional() @Inject(DATE_PIPE_DEFAULT_OPTIONS) private options?: DatePipeConfig,
  ) {
    this.localisationService.locale.subscribe((locale) => {
      this._datePipe = new DatePipe(locale, options?.timezone);
      this._ready = true;
      this.ready$.next(true);
    });
  }

  transform(value: string | Date, format: SupportedFormatWidth = 'shortDate'): string {
    if (this._ready) {
      if (!isDefined(value)) return '';
      return this._datePipe.transform(value, format);
    }
  }
}
