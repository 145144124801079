import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  DeleteResponse,
  encodeEndpoint,
  encodeParams,
  EntityId,
  EntityReference,
  RestResponse,
  toData,
} from '@demica/resources/common';

import { Calendar } from '../model/calendar.interface';
import { RegionCalendar } from '../model/region-calendar.interface';

@Injectable({
  providedIn: 'root',
})
export class CurrentRegionCalendarResourceService {
  constructor(private _http: HttpClient) {}

  getCalendarsDictionary$(regionId: number | null = null): Observable<EntityReference[]> {
    const url =
      regionId === null
        ? encodeEndpoint('resources/regions/calendars/dictionary')
        : encodeEndpoint('resources/regions/{}/calendars/dictionary', regionId);

    return this._http.get<RestResponse<EntityReference[]>>(url).pipe(map(toData));
  }

  getCalendars$(): Observable<RegionCalendar[]> {
    const url = encodeEndpoint('resources/regions/calendars');

    return this._http.get<RestResponse<RegionCalendar[]>>(url).pipe(
      map(toData),
      map((calendars) => calendars.map((calendar) => this._sortUsageRelations(calendar))),
    );
  }

  getCalendar$(calendarId: EntityId): Observable<RegionCalendar> {
    const url = encodeEndpoint('resources/regions/calendars/{}', calendarId);

    return this._http.get<RestResponse<RegionCalendar>>(url).pipe(
      map(toData),
      map((calendar) => this._sortUsageRelations(calendar)),
    );
  }

  // TODO: define return type
  checkCalendarCodeAvailability$(code: string, calendarId?: EntityId): Observable<unknown> {
    const url = encodeEndpoint('resources/regions/calendars-code-availability');
    const params = encodeParams({ code, calendarId });

    return this._http.get<RestResponse<unknown>>(url, { params }).pipe(map(toData));
  }

  createCalendar$(data: Calendar): Observable<Calendar> {
    const url = encodeEndpoint('resources/regions/calendars');

    return this._http.post<RestResponse<Calendar>>(url, data).pipe(map(toData));
  }

  updateCalendar$(calendarId: EntityId, data: Calendar): Observable<Calendar> {
    const url = encodeEndpoint('resources/regions/calendars/{}', calendarId);

    return this._http.put<RestResponse<Calendar>>(url, data).pipe(map(toData));
  }

  removeCalendar$(calendarId: EntityId, entityRevision: number): Observable<DeleteResponse> {
    const url = encodeEndpoint('resources/regions/calendars/{}', calendarId);
    const params = encodeParams({ entityRevision });

    return this._http.delete<RestResponse<DeleteResponse>>(url, { params }).pipe(map(toData));
  }

  private _sortUsageRelations(regionCalendar: RegionCalendar): RegionCalendar {
    const usageRelations = [...regionCalendar.usageRelations].sort((calendarA, calendarB) =>
      this._compareUsageRelations(calendarA.errorCodesList, calendarB.errorCodesList),
    );

    return {
      ...regionCalendar,
      usageRelations,
    };
  }

  private _compareUsageRelations(errorsA: string[], errorsB: string[]): number {
    const priorityError = 'DEFAULT_REGION_CALENDAR_UNABLE_TO_REMOVE';

    const countA = errorsA.filter((error) => error === priorityError).length;
    const countB = errorsB.filter((error) => error === priorityError).length;

    return countB - countA;
  }
}
