export type LiabilityControlNames = 'ratiosDSO' | 'funding';

/*
  TODO prettier update to support satisfies
  then type can be removed https://support.demica.com/browse/TRFV2-27834
 */
export const liabilityDictionaryToControlName: Record<number, LiabilityControlNames> = {
  1: 'ratiosDSO',
  2: 'funding',
}; // satisfies Record<number, LiabilityControlNames>;
