import { Directive, EventEmitter, Input, Output } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormGroup,
  ValidatorFn,
} from '@angular/forms';

import { identity } from '@demica/core/core';

import {
  TFormControls,
  TParentFormGroup,
  TParentFormGroupValue,
  TypedDynamicFormDefinition,
} from './typed-form-dynamic.model';
import { ValidationMessage } from './validation-messages/validation-message.interface';

export interface ControlDefinition<TParentModel> {
  name: keyof TParentModel;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue: any;
  validations?: ValidatorFn[];
  asyncValidations?: AsyncValidatorFn[];
  // eslint-disable-next-line @typescript-eslint/ban-types
  messages?: ValidationMessage[] | Function;
  defaultLabel?: string;
  showPercentLabel?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: any;
}

export interface DynamicFormGroup<TParentModel = any> {
  name: string;
  controls: ControlDefinition<TParentModel>[];
  validations?: ValidatorFn[];
  asyncValidations?: AsyncValidatorFn[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
}

// TODO: TRFV2-3891 Refactor to proper types from "any"
// This class should probably have a generic type

@Directive()
export abstract class DynamicFormDirective<
  TParentModel extends TFormControls<TParentModel> = any,
  TFormValueKey extends keyof TParentFormGroupValue<TParentModel> = any,
> {
  @Input()
  parentForm: TParentFormGroup<TParentModel>;
  @Input()
  submitted: boolean;
  @Input()
  disableValueStoring = false;

  @Input()
  set isActive(isActive: boolean) {
    this.formGroup = this.createFormGroup();

    isActive ? this.addGroup() : this.removeGroup();

    this.groupVisibilityChanged.emit();
  }

  @Input()
  set initialValue(newValue: TParentFormGroupValue<TParentModel>) {
    this._setInitialValue(newValue);
  }

  @Input()
  set isDisabled(isDisabled: boolean) {
    this._isDisabled = isDisabled;

    if (this.isAlreadyInForm()) {
      isDisabled ? this.disableGroup() : this.enableGroup();
    }
  }

  @Output()
  groupVisibilityChanged = new EventEmitter<void>();

  _initialValue: TParentFormGroupValue<TParentModel> = {} as any;
  _isDisabled: boolean;
  formGroup: TypedDynamicFormDefinition<Pick<TParentFormGroupValue<TParentModel>, TFormValueKey>>;
  validations: Partial<
    Record<keyof FormGroup<TParentModel>['value'][TFormValueKey], ValidationMessage[]>
  > = {};
  oldValue: TParentFormGroupValue<TParentModel>;

  private get _untypedParentForm(): TParentFormGroup<any> {
    return this.parentForm as TParentFormGroup<any>;
  }

  private initialValueSet = false;

  constructor(private fb: FormBuilder) {}

  /**
   * Internal (protected) method for setting the initial value.
   * This is a workaround for es5 compilation bug where TS is unable to
   * walk up the prototype chain for virtual properties (setters & getters)
   * See https://github.com/microsoft/TypeScript/issues/338 for more info.
   */
  protected _setInitialValue(newValue: TParentFormGroupValue<TParentModel>) {
    this._initialValue = newValue;

    if (!this.initialValueSet) {
      this.updateFormGroupValues();
      this.initialValueSet = true;
    }
  }

  addGroup() {
    if (!this.isAlreadyInForm()) {
      this.addControls();
      this.listenOnChange();
      this.addValidations();
      this.restoreValue();
    }

    this._isDisabled ? this.disableGroup() : this.enableGroup();
    this.updateFormGroupValues();
  }

  removeGroup() {
    if (this.isAlreadyInForm()) {
      this.storeValue();
      this.removeControls();
      this.removeValidations();
    }
  }

  disableGroup() {
    this._untypedParentForm.get(this.formGroup.name).disable();
  }

  enableGroup() {
    this._untypedParentForm.get(this.formGroup.name).enable();
  }

  isAlreadyInForm(): boolean {
    return !!this.formGroup && !!this._untypedParentForm.get(this.formGroup.name);
  }

  storeValue() {
    if (!this.disableValueStoring)
      this.oldValue = this._untypedParentForm.get(this.formGroup.name).value;
  }

  restoreValue() {
    if (!this.disableValueStoring) this.formGroup.value = this.oldValue;
  }

  addControls() {
    this._untypedParentForm.addControl(this.formGroup.name, this.createControls());
  }

  listenOnChange() {
    this._untypedParentForm
      .get(this.formGroup.name)
      .valueChanges.subscribe((value) =>
        this.onFormChange(value, this._untypedParentForm.get(this.formGroup.name)),
      );
  }

  removeControls() {
    this._untypedParentForm.removeControl(this.formGroup.name);
  }

  addValidations() {
    this.formGroup.controls.forEach((control: ControlDefinition<TParentModel>) =>
      this.createValidations(control),
    );
  }

  removeValidations() {
    this.validations = {};
  }

  updateFormGroupValues() {
    let value: TParentFormGroupValue<TParentModel>;
    if (!this.disableValueStoring) value = this.oldValue;
    value = value || (this._initialValue && this._initialValue[this.formGroup.name]) || {};

    const group = this._untypedParentForm.get(this.formGroup.name);
    if (group) {
      this.restoreDefaultValues();

      (group as FormGroup).patchValue(value);
      group.updateValueAndValidity();
    }
  }

  createControls() {
    type FormBuilderOptions = [any, ValidatorFn[], AsyncValidatorFn[]];
    const controls = {} as Record<keyof TParentModel, FormBuilderOptions>;

    this.formGroup.controls.forEach((control: ControlDefinition<TParentModel>) => {
      controls[control.name] = [
        control.defaultValue,
        control.validations,
        control.asyncValidations,
      ];
    });

    const group = this.fb.group(controls);

    group.setValidators(this.formGroup.validations);
    group.setAsyncValidators(this.formGroup.asyncValidations);

    return group;
  }

  createValidations(control: ControlDefinition<TParentModel>) {
    if (control.messages instanceof Function) {
      this.validations[control.name] = control.messages(
        this._untypedParentForm.get(this.formGroup.name),
        () => this.submitted,
      );
    }
  }

  createFormGroup() {
    const formGroup = this.getFormGroup();

    formGroup.controls.forEach((control) => {
      control.validations = control.validations || [];
      control.asyncValidations = control.asyncValidations || [];
      control.messages = control.messages || identity;
    });

    return formGroup;
  }

  restoreDefaultValues() {
    this.formGroup.controls.forEach((control: ControlDefinition<TParentModel>) => {
      this._untypedParentForm
        .get(this.formGroup.name)
        .get(control.name as string)
        .setValue(control.defaultValue);
    });
  }

  onFormChange(
    value: TParentFormGroup<TParentModel>['value'][TFormValueKey],
    form: AbstractControl,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  ) {}

  abstract getFormGroup(): TypedDynamicFormDefinition<
    Pick<TParentFormGroupValue<TParentModel>, TFormValueKey>
  >;
}
