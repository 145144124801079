import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormGroup,
  FormGroupDirective,
  ValidatorFn,
  FormControl,
  ValidationErrors,
} from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { ValidationMessage } from '../../forms/validation-messages/validation-message.interface';
import { endDateAfterStartDateValidator } from '../../forms/validators';

@Component({
  selector: 'trf-form-double-date-picker-row',
  templateUrl: './form-double-date-picker-row.component.html',
  styleUrls: ['./form-double-date-picker-row.component.sass'],
})
export class FormDoubleDatePickerRowComponent implements OnInit, OnDestroy {
  @Input()
  labelDateFrom: string;
  @Input()
  labelDateTo: string;
  @Input()
  dateFromName: string;
  @Input()
  dateToName: string;
  @Input()
  dateFromControl: FormControl;
  @Input()
  dateToControl: FormControl;
  @Input()
  fromValidationMsg?: ValidationMessage[];
  @Input()
  toValidationMsg?: ValidationMessage[];
  @Input()
  dateFromTestIdName? = 'date-from';
  @Input()
  dateToTestIdName? = 'date-to';
  @Input()
  disabled: boolean;

  fromControl: FormControl;
  toControl: FormControl;
  form: UntypedFormGroup;

  private destroyed$ = new Subject<void>();

  constructor(private fgd: FormGroupDirective) {}

  ngOnInit(): void {
    this.form = this.fgd.form;
    this.fromControl = this.dateFromControl ?? (this.form.get(this.dateFromName) as FormControl);
    this.toControl = this.dateToControl ?? (this.form.get(this.dateToName) as FormControl);
    this.addValidator();
    this.initControlsSynchronizationHandler();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private addValidator(): void {
    const validators: ValidatorFn[] = [];
    if (this.toControl.validator) {
      validators.push(this.toControl.validator);
    }

    validators.push(() => endDateAfterStartDateValidator(this.fromControl, this.toControl));

    this.toControl.setValidators(validators);
  }

  private initControlsSynchronizationHandler(): void {
    this.fromControl.valueChanges
      .pipe(
        tap(() => this.toControl.updateValueAndValidity()),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }
}
