<div
  class="toast-container"
  [ngClass]="[toasterConfig.positionClass]"
>
  <div
    toasterComp
    *ngFor="let toast of toasts"
    class="toast"
    data-testId="toast"
    [toast]="toast"
    [toasterConfig]="toasterConfig"
    [@toastState]="toasterConfig.animation"
    [titleClass]="toasterConfig.titleClass"
    [messageClass]="toasterConfig.messageClass"
    [ngClass]="[toasterConfig.iconClasses[toast.type], toasterConfig.typeClasses[toast.type]]"
    (click)="click(toast)"
    (clickEvent)="childClick($event)"
    (removeToastEvent)="removeToast($event)"
  >
  </div>
</div>
