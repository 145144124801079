import { Directive, ElementRef, Host, Renderer2 } from '@angular/core';

@Directive({
  selector: '[ngpOverlayLoaderWrapper]',
})
export class OverlayLoaderWrapperDirective {
  constructor(@Host() public _elementRef: ElementRef, renderer: Renderer2) {
    renderer.addClass(_elementRef.nativeElement, 'overlay-loader-wrapper');
  }
}
