import { Component, Input, OnInit } from '@angular/core';

import { AppConfigService, ThemingService } from '@demica/core/core';

@Component({
  selector: 'trf-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.sass'],
})
export class LogoComponent implements OnInit {
  @Input()
  sidebarCollapsed: boolean;

  logoImg: string = null;
  iconImg: string = null;

  logoImgPath = this.theme.logoImgLocation;
  logoIconPath = this.theme.logoIconLocation;

  constructor(private config: AppConfigService, private theme: ThemingService) {}

  public ngOnInit() {
    this.config.configAsPromise.then((c) => {
      this.logoImg = c.menu.logo;
      this.iconImg = c.menu.icon;
    });
  }
}
