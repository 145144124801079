import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApprovePush, PostResponse, toData } from '@demica/resources/common';

import { apiUrl } from '../../config/api.config';
import { RelatedVersion } from '../../model/related-version.interface';
import { RestResponse } from '../../model/response.interface';

@Injectable()
export class PushRequestService {
  constructor(private http: HttpClient) {}

  getRelatedVersions(pushRequestId: number): Observable<RelatedVersion[]> {
    return this.http
      .get(`${apiUrl}/resources/push-requests/${pushRequestId}/related-versions`)
      .pipe(map((r: RestResponse<RelatedVersion[]>) => r.data));
  }

  approvePushRequest(pushRequestId: number, data: ApprovePush): Observable<PostResponse> {
    return this.http
      .post<RestResponse<PostResponse>>(
        `${apiUrl}/resources/push-requests/${pushRequestId}/approval`,
        data,
      )
      .pipe(map(toData));
  }
}
