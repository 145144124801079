import { EntityId } from '../interface/has-entity-id.interface';

const RESOURCES = 'resources';

export const pagingUrls = {
  DASHBOARD_DEBTORS: `${RESOURCES}/dashboards/portfolio/debtors`,
  DASHBOARD_DEBTORS_GROUPS: `${RESOURCES}/dashboards/portfolio/debtor-groups`,
  JOURNAL_SERVICE_STATUS_FILE: `${RESOURCES}/steps/search`,
  DASHBOARD_RECEIVABLE: `${RESOURCES}/dashboards/portfolio/receivables`,
  DASHBOARD_LIMITS: `${RESOURCES}/limits`,
  DASHBOARD_DEBTORS_GROUPS_RECEIVABLES: (id: EntityId) =>
    `${RESOURCES}/dashboards/portfolio/debtor-groups/${id}/receivables`,
  DASHBOARD_DEBTORS_RECEIVABLES: (id: EntityId) =>
    `${RESOURCES}/dashboards/portfolio/debtors/${id}/receivables`,
  MISSING_FILES: `${RESOURCES}/dashboards/missing-files-status`,
};
