export const ACTION_EDIT: ActionId = { id: 1, hideWhenObjectIsUsed: false };
export const ACTION_REMOVE: ActionId = { id: 2, hideWhenObjectIsUsed: true };
export const ACTION_PREVIEW: ActionId = { id: 3, hideWhenObjectIsUsed: false };
export const ACTION_UNLINK: ActionId = { id: 4, hideWhenObjectIsUsed: true };
export const ACTION_PROMOTE: ActionId = { id: 5, hideWhenObjectIsUsed: false };
export const ACTION_CREATE: ActionId = { id: 6, hideWhenObjectIsUsed: false };
export const ACTION_UNLINK_ALWAYS: ActionId = { id: 7, hideWhenObjectIsUsed: false };
export const ACTION_REPLACE_WITH_TEMPLATE: ActionId = { id: 8, hideWhenObjectIsUsed: false };
export const ACTION_PAUSE: ActionId = { id: 9, hideWhenObjectIsUsed: false };
export const ACTION_UNPAUSE: ActionId = { id: 10, hideWhenObjectIsUsed: false };
export const ACTION_EXPORT: ActionId = { id: 11, hideWhenObjectIsUsed: false };
export const ACTION_PUSH: ActionId = { id: 13, hideWhenObjectIsUsed: false };

export interface Action<T = unknown> {
  titleKey?: string;
  handler: (actionSubject: T) => void;
  icon?: string;
  inactive?: (actionSubject: T) => boolean;
  hidden?: (actionSubject: T) => boolean;
  testId?: string;
  requiredAuthority?: string;
  class?: string;
  tooltipPosition?: string;
  tooltipContent?: string;
  actionId?: ActionId;
  tooltipHandler?: (actionSubject: T) => string | null;
  inactiveWithMessages?: boolean;
  messages?: string[];
}

export interface ActionId {
  id: number;
  hideWhenObjectIsUsed: boolean;
}

export type Actions<T = unknown> = Record<string, Action<T>>;

// typed solution
// ref:
// https://stackoverflow.com/questions/55754822/expected-3-type-arguments-but-got-1-but-it-should-infer-2-types
// https://stackoverflow.com/questions/60919163/typescript-generic-type-parameters-are-inferred-with-functions-but-not-type
// https://www.typescriptlang.org/docs/handbook/release-notes/typescript-4-9.html#the-satisfies-operator
// https://github.com/microsoft/TypeScript/issues/51556
export function forTableRow<Row>() {
  return { createActions: <T extends string>(partial: Record<T, Action<Row>>) => partial };
}

// // test
// type TestRowType = { test1: string };
//
// // 1. actions is object with typed "test" literal
// const actions = forTableRow<TestRowType>().createActions({
//   test: {
//     // 2. row is type of TestRowType
//     handler: (row) => row,
//   },
// });
