import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { CurrencyCode, EntityId, Range } from '@demica/core/core';

import { definitionsToMessages } from '../../forms/validation-messages/message-builder';
import {
  msgMaxDecimalPlacesExceeded,
  msgMaxIntegerPlacesExceeded,
  msgRequired,
} from '../../forms/validation-messages/message-definitions';
import { validateNotEmpty } from '../../forms/validators';
import { RANGE_TYPE, RATE_TYPE } from '../../model/range.constants';
import { rangeValidations } from '../../model/range.interface';

export type RangesForm = FormGroup<{
  lowerRange: FormControl<string>;
  upperRange: FormControl<string>;
  value: FormControl<string>;
}>;

type RangesConfig = {
  lowerRange: boolean;
  upperRange: boolean;
  value: boolean;
};

type RangesTypeConfig = {
  percentage: RangesConfig;
  mandatory: RangesConfig;
};

@Component({
  selector: 'trf-ranges-form',
  templateUrl: './ranges-form.component.html',
  styleUrls: ['./ranges-form.component.sass'],
})
export class RangesFormComponent implements OnInit {
  @Input()
  range: Range;
  @Input()
  versionPreviewMode: boolean;
  @Input()
  previewMode: boolean;
  @Input()
  rangeType: EntityId;
  @Input()
  rateType: EntityId;
  @Input()
  valueLabel: string;
  @Input()
  lowerRangeFormLabel: string;
  @Input()
  upperRangeFormLabel: string;
  @Input()
  numberOfDecimalPlacesForValue: number;
  @Input()
  numberOfDecimalPlacesForRangePoints: number;
  @Input()
  maxValueForRangePoints: number;
  @Input()
  removeTrailingZeros = false;
  @Input()
  currency: CurrencyCode;
  @Input()
  acceptNegativeValues: boolean;
  @Input()
  validateMinMaxValue: boolean;

  @Output()
  saveButtonClick = new EventEmitter<Range>();
  @Output()
  cancelButtonClick = new EventEmitter<void>();

  loading = true;
  submitted = false;

  form: RangesForm = this._fb.group({
    lowerRange: [null],
    upperRange: [null],
    value: [null],
  });

  rangeValidation = rangeValidations(this.form, () => this.submitted);
  requiredValidation = definitionsToMessages(
    [msgRequired, msgMaxDecimalPlacesExceeded, msgMaxIntegerPlacesExceeded],
    () => this.form,
    () => this.submitted,
  );

  config: RangesTypeConfig = {
    percentage: {
      lowerRange: false,
      upperRange: false,
      value: true,
    },
    mandatory: {
      lowerRange: true,
      upperRange: false,
      value: true,
    },
  };

  constructor(private _fb: FormBuilder) {}

  ngOnInit(): void {
    if (this._isRangeTypePercentage()) {
      this.config.percentage.lowerRange = true;
      this.config.percentage.upperRange = true;
      this.config.mandatory.upperRange = true;
    }
    this.config.percentage.value = this.rateType !== RATE_TYPE.amount;

    this._setupValidators();
    this.form.patchValue(this.range);
    this.form.updateValueAndValidity();

    this.loading = false;
  }

  onSave(): void {
    this.submitted = true;
    if (this.form.valid) {
      this.saveButtonClick.emit(this.form.getRawValue() as Range);
    }
  }

  onCancel(): void {
    this.cancelButtonClick.emit();
  }

  private _isRangeTypePercentage(): boolean {
    return this.rangeType === RANGE_TYPE.percentage;
  }

  private _isNotRateTypeAmount(): boolean {
    return this.rateType !== RATE_TYPE.amount;
  }

  private _setupValidators(): void {
    const upperRangeValidators = [];
    const valueValidators = [];

    if (this._isRangeTypePercentage()) {
      upperRangeValidators.push(validateNotEmpty, Validators.min(0), Validators.max(100));
    }

    if (this.validateMinMaxValue) {
      if (this._isNotRateTypeAmount()) {
        valueValidators.push(Validators.min(0), Validators.max(100));
      } else {
        valueValidators.push(Validators.min(0));
      }
    }

    upperRangeValidators.push(this._rangesValidator.bind(this));
    valueValidators.push(validateNotEmpty);

    this.form.controls.upperRange.setValidators(upperRangeValidators);
    this.form.controls.value.setValidators(valueValidators);
  }

  private _rangesValidator(control: AbstractControl): Record<string, boolean> | null {
    if (!this.form) {
      return null;
    }
    const lowerRange = this.form.controls.lowerRange;
    if (!lowerRange) {
      return null;
    }

    const lowerRangeValue = lowerRange.value ? Number(lowerRange.value) : null;
    const upperRangeValue = control.value ? Number(control.value) : null;

    if (
      lowerRangeValue !== null &&
      upperRangeValue !== null &&
      lowerRangeValue >= upperRangeValue
    ) {
      return { 'upper-lower-than-lower': true };
    }
    return null;
  }
}
