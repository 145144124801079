import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';

import { Subject } from 'rxjs';

import {
  errorNotification,
  getFormControlName,
  NotificationEntry,
  Notifications,
} from '@demica/core/core';

import { ngbDateMsg } from '../../forms/validation-messages/common-validation-messages';
import { ValidationMessage } from '../../forms/validation-messages/validation-message.interface';

const incorrectDateValidationError = errorNotification('VALIDATION.DATE_CORRECT');
const missingDateError = errorNotification('VALIDATION.PROVIDE_AT_LEAST_ONE_DATE');

const dateFromGreaterError = (key: string) => errorNotification(key);
const missingFromError = (key: string) => errorNotification(key);
const missingToError = (key: string) => errorNotification(key);

interface DoubleDates {
  dateFrom: Date;
  dateTo: Date;
}

@Component({
  selector: 'trf-double-date-picker',
  templateUrl: './double-date-picker.component.html',
  styleUrls: ['./double-date-picker.component.sass'],
})
export class DoubleDatePickerComponent implements OnInit {
  @Input()
  labelDateFrom: string;

  @Input()
  labelDateTo: string;

  @Input()
  clearSubject: Subject<void>;

  @Input()
  dateFromControl: FormControl;

  @Input()
  dateToControl: FormControl;

  @Input()
  disabled? = false;

  @Input()
  dateFromTestIdName? = 'date-from';

  @Input()
  dateToTestIdName? = 'date-to';

  @Input()
  requireAtLeastOneDate = false;

  @Input()
  bothRequired = false;

  @Input()
  requiredFromMsgErrorKey = 'VALIDATION.PROVIDE_FROM_DATE';

  @Input()
  requiredToMsgErrorKey = 'VALIDATION.PROVIDE_TO_DATE';

  @Input()
  dateRangeMsgErrorKey = 'DASHBOARD_RECEIVABLES.DATE_VALIDATION_ERROR_MESSAGE';

  group: FormGroup;
  dates: DoubleDates = {
    dateFrom: null,
    dateTo: null,
  };

  dateFromControlName: string;
  dateToControlName: string;

  calendarScheduleStartDateValidations: ValidationMessage[];
  calendarScheduleEndDateValidations: ValidationMessage[];

  notifications = new Notifications();

  private _destroyRef = inject(DestroyRef);

  constructor(private _fgd: FormGroupDirective) {}

  ngOnInit(): void {
    this._createFormControls();

    this.calendarScheduleStartDateValidations = this._createDateValidationMessages();
    this.calendarScheduleEndDateValidations = this._createDateValidationMessages();

    this.clearSubject?.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
      this.notifications.clear();
    });

    this.group.valueChanges.subscribe((value) => {
      this.dates.dateFrom =
        value[this.dateFromControlName] === null
          ? null
          : this._transformDate(value[this.dateFromControlName]);
      this.dates.dateTo =
        value[this.dateToControlName] === null
          ? null
          : this._transformDate(value[this.dateToControlName]);
      this._handleValueChanges(this.dates);
    });
  }

  private _createDateValidationMessages(): ValidationMessage[] {
    return ngbDateMsg(this.group, () => false);
  }

  private _transformDate(date: Date): Date {
    if (date instanceof Date) {
      return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
    }
    return date;
  }

  private _createFormControls(): void {
    this.group = this._fgd.form;

    this.dateFromControlName = getFormControlName(this.dateFromControl);
    this.dateToControlName = getFormControlName(this.dateToControl);
  }

  private _hasDatePickerInvalid(control: AbstractControl): boolean {
    return control.getError('ngbDate');
  }

  private _handleValueChanges(value: DoubleDates): void {
    this.notifications.clear();

    this._invalidDateValidation();
    this._missingDateValidation(value);
    this._fromDateGreaterThanToDateValidation(value);
  }

  private _invalidDateValidation(): void {
    if (
      this._hasDatePickerInvalid(this.dateFromControl) ||
      this._hasDatePickerInvalid(this.dateToControl)
    ) {
      this._addNotification(incorrectDateValidationError);
    }
  }

  private _missingDateValidation(value: DoubleDates): void {
    const dateFromValue = value.dateFrom;
    const dateToValue = value.dateTo;
    const dateFromTouched = this.dateFromControl.touched;
    const dateToTouched = this.dateToControl.touched;

    const missingDate = this.requireAtLeastOneDate && !dateFromValue && !dateToValue;
    const missingAny =
      this.bothRequired && ((!dateFromValue && dateFromTouched) || (!dateToValue && dateToTouched));

    if (missingDate) {
      this._addNotification(missingDateError);
    }
    this._changeErrorStatus('missingDate', missingDate);

    if (missingAny) {
      this._addNotification(
        dateFromValue
          ? missingToError(this.requiredToMsgErrorKey)
          : missingFromError(this.requiredFromMsgErrorKey),
      );
    }

    this._changeErrorFieldStatus(this.dateFromControl, 'require', missingAny && !dateFromValue);
    this._changeErrorFieldStatus(this.dateToControl, 'require', missingAny && !dateToValue);
  }

  private _fromDateGreaterThanToDateValidation(value: DoubleDates): void {
    const dateFromGreater =
      value.dateFrom !== null && value.dateTo !== null && value.dateFrom > value.dateTo;

    if (dateFromGreater) {
      this._addNotification(dateFromGreaterError(this.dateRangeMsgErrorKey));
    }

    this._changeErrorStatus('dateFromGreater', dateFromGreater);
  }

  private _addNotification(entry: NotificationEntry): void {
    if (!this.notifications.hasAnyError()) {
      this.notifications.add(entry);
    }
  }

  private _changeErrorStatus(errorKey: string, errorExists: boolean): void {
    this._changeErrorFieldStatus(this.dateFromControl, errorKey, errorExists);
    this._changeErrorFieldStatus(this.dateToControl, errorKey, errorExists);
  }

  private _changeErrorFieldStatus(
    control: AbstractControl,
    errorKey: string,
    errorExists: boolean,
  ): void {
    const errors = control.errors ?? {};

    if (errorExists) {
      errors[errorKey] = true;
    } else {
      delete errors[errorKey];
    }

    control.setErrors(Object.keys(errors).length ? errors : null);
  }
}
