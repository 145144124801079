const analyticsEndpoint = '/resources/analytics';

const configFile = '/assets/client-assets/app-config.json';
const globalConfigFile = '/assets/global-app-config.json';
const yFilesLicenseConfigFile = '/assets/yfiles-license-config.json';

const authExcludedResources = [
  analyticsEndpoint,
  configFile,
  globalConfigFile,
  yFilesLicenseConfigFile,
];

export function isAuthExcludedResource(endpoint: string): boolean {
  return authExcludedResources.some((unsecuredResource) => !!endpoint.match(unsecuredResource));
}
