import { Inject, Injectable } from '@angular/core';

import { lastValueFrom, Observable, of } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';

import { LogArea, LoggerService } from '@demica/logger';
import {
  YFilesLicenseConfigResourceService,
  yFilesLicenseConfig,
} from '@demica/resources/yfiles-license-config';

import { APP_ENVIRONMENT, AppEnvironment } from '../../token/environment.token';
import { RemoteConfigProvider } from '../../token/remote-config-provider.token';

@Injectable({ providedIn: 'root' })
export class YFilesLicenseConfigService implements RemoteConfigProvider {
  /**
   * configAsPromise exposed as a JS Promise
   *
   * @type {Promise<yFilesLicenseConfig>}
   * @memberof yFilesLicenseConfigService
   */
  configAsPromise: Promise<yFilesLicenseConfig>;
  /**
   * yFilesLicenseConfig as RXjs Observable
   *
   * @type {Observable<yFilesLicenseConfig>}
   * @memberof yFilesLicenseConfigService
   */
  yFilesLicenseConfig$: Observable<yFilesLicenseConfig>;

  constructor(
    private _yFilesLicenseConfigResource: YFilesLicenseConfigResourceService,
    @Inject(APP_ENVIRONMENT) private readonly environment: AppEnvironment,
    private logger: LoggerService,
  ) {
    this._initializeYFilesLicenseConfig();
    this._exposeYFilesLicenseConfigAsPromise();
  }

  private _initializeYFilesLicenseConfig(): void {
    this.yFilesLicenseConfig$ = this._yFilesLicenseConfigResource.getYFilesLicenseConfig$().pipe(
      catchError(() => {
        this.logger.addError({
          message: 'yFiles License configuration was not provided',
          area: LogArea.ERROR_HANDLER,
        });
        return of(this.environment.yFilesLicenseConfig || {});
      }),
      shareReplay(),
    );
  }

  private _exposeYFilesLicenseConfigAsPromise(): void {
    this.configAsPromise = lastValueFrom(this.yFilesLicenseConfig$);
  }
}
