import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounce, filter, map, startWith, switchMap, tap } from 'rxjs/operators';

import {
  ajaxDelay,
  ComponentConfiguration,
  DictionaryEntry,
  generateUUID,
  HasEntityId,
  maxPercentageValue,
  removeTrailingZerosNumber,
  SellerCodeValidator,
  SlideinService,
} from '@demica/core/core';
import { Seller, SellerBankAccount, SellerResourceService } from '@demica/resources/seller';

import { TextTableHeaderComponent } from '../data-table/text-table-header.component';
import { SellerAccountsListRowComponent } from './seller-accounts-list-row.component';

import { isControlValid$ } from '../../forms/dynamic-form-utils';
import { FormStatus } from '../../forms/model/form-status.interface';
import { definitionGroupToMessages } from '../../forms/validation-messages/message-builder';
import {
  msgDefaultMaxLength,
  msgEmailFormat,
  msgMaxLength,
  msgMaxNumber,
  msgMinLength,
  msgRequired,
} from '../../forms/validation-messages/message-definitions';
import { hasError } from '../../forms/validation-messages/message-predicates';
import {
  requireSelect,
  validateDefaultMaxLength,
  validateEmail,
  validateMinLength,
  validateNotEmpty,
} from '../../forms/validators';
import { SicCode } from '../../model/sic-codes-translation';
import {
  maxSellerCodeLength,
  maxSellerExternalSystemReferenceLength,
  maxSellerPostCodeLength,
  minSellerNameLength,
  sellerCinLength,
} from '../../validation/seller-validation.consts';
import { ACTION_REMOVE, Actions } from '../actions/model/actions.interface';
import { DataSource } from '../data-table/data-source.interface';
import { CreateSellerForm, CreateSellerFormValidationMessages } from './create-seller-form.model';
import { SellerBankAccountSlideinContainerComponent } from './seller-bank-account-slidein.container';

@Component({
  selector: 'trf-create-seller-form',
  templateUrl: './create-seller-form.component.html',
  styleUrls: ['./create-seller-form.component.sass'],
})
export class CreateSellerFormComponent implements OnInit {
  @Input()
  clientName: string;
  @Input()
  countryOptions: HasEntityId[] = [];
  @Input()
  standardIndustryCodeOptions: SicCode[] = [];
  @Input()
  legalStatusOptions: DictionaryEntry[];
  @Input()
  sellerId: number = null;
  @Input()
  hasEnabledSellerAccounts: boolean;
  @Input()
  partialsEnabled: boolean;

  @Output()
  sellerClose = new EventEmitter<void>();
  @Output()
  save = new EventEmitter<Seller>();

  loadedSeller: Seller;

  readonly maxSellerCodeLength = maxSellerCodeLength;
  readonly maxSellerPostCodeLength = maxSellerPostCodeLength;
  readonly sellerCinLength = sellerCinLength;
  readonly maxSellerESRLength = maxSellerExternalSystemReferenceLength;

  validations = this._initValidationMessages();

  isNameWarningVisible$: Observable<boolean>;
  loading = true;
  extendedSeller = false;
  submitted = false;
  disabledSaveButton = false;

  form: CreateSellerForm = this._fb.group({
    clientName: [''],
    name: ['', [validateNotEmpty]],
    code: [
      '',
      [validateNotEmpty],
      [
        this.sellerId
          ? this._sellerCodeValidator.validate.bind(this._sellerCodeValidator)
          : this._sellerCodeValidator
              .validateWithId(() => this.sellerId)
              .bind(this._sellerCodeValidator),
      ],
    ],
    country: [null as HasEntityId, [requireSelect]],
    minimalPartialPortionThreshold: [null],
    contactPersonFirstName: ['', [validateMinLength(minSellerNameLength)]],
    contactPersonLastName: ['', [validateMinLength(minSellerNameLength)]],
    address1: ['', [validateNotEmpty]],
    address2: [''],
    address3: [''],
    address4: [''],
    telephoneCountryCode: ['', [validateDefaultMaxLength]],
    telephoneNumber: ['', [validateDefaultMaxLength]],
    email: ['', [validateEmail]],
  });

  dataSource: DataSource<SellerBankAccount[]> = {
    data: new BehaviorSubject([]),
  };
  headerConfig: ComponentConfiguration;
  rowConfig: ComponentConfiguration;
  bankAccountData: SellerBankAccount[] = [];
  isPartialPortionThresholdWarning$: Observable<boolean> = combineLatest([
    isControlValid$(this.form.controls.minimalPartialPortionThreshold),
    this._isPartialPortionThresholdValue$(),
  ]).pipe(map(([valid, warning]) => valid && warning));

  constructor(
    private _fb: FormBuilder,
    private _sellerCodeValidator: SellerCodeValidator,
    private _sellerResource: SellerResourceService,
    private _slideInService: SlideinService,
  ) {}

  ngOnInit(): void {
    this.form.controls.clientName.setValue(this.clientName);
    this.form.controls.clientName.disable();

    if (this.hasEnabledSellerAccounts) {
      this.addAdditionalFields();
      this.headerConfig = this._configureTableHeader();
      this.rowConfig = this._configureTableRows();
      this.extendedSeller = true;

      if (!this.bankAccountData.length) {
        this.disabledSaveButton = true;
      }
    }

    if (this.sellerId) {
      this._sellerResource.getSeller$(this.sellerId).subscribe((seller) => {
        this.loadedSeller = seller;

        if (this.hasEnabledSellerAccounts) {
          this.bankAccountData = seller.bankAccounts;
          this.dataSource.data.next(this.bankAccountData);
        }

        if (seller.minimalPartialPortionThreshold) {
          this.loadedSeller.minimalPartialPortionThreshold = removeTrailingZerosNumber(
            seller.minimalPartialPortionThreshold,
          );
        }

        if (this.bankAccountData.length) {
          this.disabledSaveButton = false;
        }

        this.form.patchValue(this.loadedSeller);
      });
    }
    this.loading = false;

    this.isNameWarningVisible$ = combineLatest([
      isControlValid$(this.form.controls.name),
      this._isNameAvailable$(),
    ]).pipe(map(([valid, available]) => valid && !available));
  }

  addAdditionalFields(): void {
    this.form.addControl('postCode', new FormControl(null));
    this.form.addControl(
      'customerIdentificationNumber',
      new FormControl(null, Validators.maxLength(sellerCinLength)),
    );
    this.form.addControl('standardIndustryCode', new FormControl(null));
    this.form.addControl('pointOfContact', new FormControl(null, validateNotEmpty));
    this.form.addControl(
      'externalSystemReference',
      new FormControl(null, Validators.maxLength(maxSellerExternalSystemReferenceLength)),
    );
    this.form.addControl('legalStatus', new FormControl(null));
    this.form.addControl('localTaxReferenceNumber', new FormControl(null));
  }

  onClose(): void {
    this.sellerClose.emit();
  }

  onSave(): void {
    if (this.disabledSaveButton) return;
    this.submitted = true;

    if (this.form.status !== FormStatus.PENDING) {
      this.saveSeller();
    } else {
      const sub = this.form.statusChanges
        .pipe(
          filter((s) => s !== FormStatus.PENDING),
          tap(() => {
            sub.unsubscribe();
            this.saveSeller();
          }),
        )
        .subscribe();
    }
  }

  saveSeller(): void {
    if (this.form.valid) {
      const formValue = this.form.getRawValue();
      let payload: Seller = {
        ...formValue,
        entityId: null,
        clientId: null,
        entityRevision: null,
      };

      if (this.loadedSeller) {
        payload = {
          ...payload,
          entityId: this.loadedSeller.entityId,
          clientId: this.loadedSeller.clientId,
          entityRevision: this.loadedSeller.entityRevision,
        };
      }

      if (this.extendedSeller) {
        if (!this.bankAccountData.length) return;
        this._removeTemporaryId();

        payload = {
          ...payload,
          bankAccounts: [...this.bankAccountData],
        };
      }

      this.disabledSaveButton = true;
      this.save.emit(payload);
    }
  }

  addAccount(): void {
    const slideInRef = this._slideInService.openSlideIn(SellerBankAccountSlideinContainerComponent);
    slideInRef.onSuccess = this._onBankAccountAdd;
  }

  editAccount = (bankAccountToEdit: SellerBankAccount): void => {
    const slideInRef = this._slideInService.openSlideIn(SellerBankAccountSlideinContainerComponent);
    slideInRef.instance.bankAccountData = bankAccountToEdit;

    const index = this._findBankAccountIndex(bankAccountToEdit);

    slideInRef.onSuccess = (editedOpcoBankAccount: SellerBankAccount) => {
      this._onBankAccountEdited(editedOpcoBankAccount, index);
    };
  };

  private _isNameAvailable$(): Observable<boolean> {
    return this.form.controls.name.valueChanges.pipe(
      filter((name) => !!name),
      debounce(() => ajaxDelay),
      switchMap((value) => this._sellerResource.checkSellerNameAvailability$(value, this.sellerId)),
    );
  }

  private _onBankAccountEdited = (editedBankAccount: SellerBankAccount, index: number): void => {
    this.bankAccountData[index] = editedBankAccount;
    this.dataSource.data.next(this.bankAccountData);
  };

  private _onBankAccountAdd = (newBankAccount: SellerBankAccount): void => {
    newBankAccount.tempId = generateUUID();

    this.bankAccountData.push(newBankAccount);
    this.dataSource.data.next(this.bankAccountData);
    this.disabledSaveButton = false;
  };

  private _onBankAccountRemove = (bankAccountToRemove: SellerBankAccount): void => {
    const index = this._findBankAccountIndex(bankAccountToRemove);
    if (index > -1) {
      this.bankAccountData.splice(index, 1);
      this.dataSource.data.next(this.bankAccountData);
    }
    if (!this.bankAccountData.length) {
      this.disabledSaveButton = true;
    }
  };

  private _configureTableHeader(): ComponentConfiguration {
    return {
      component: TextTableHeaderComponent,
      inputs: {
        columns: [
          { nameKey: 'SELLER_BANK_ACCOUNT.TABLE_COLUMN_BANK_ACCOUNT_NAME', classes: 'text-wrap' },
          { nameKey: 'SELLER_BANK_ACCOUNT.TABLE_COLUMN_BANK_ACCOUNT_NUMBER', classes: 'text-wrap' },
          {
            nameKey: 'SELLER_BANK_ACCOUNT.TABLE_COLUMN_DISCOUNT_BRANCH_CODE_ROUTING_NUMBER',
            classes: 'text-wrap',
          },
          { nameKey: 'SELLER_BANK_ACCOUNT.TABLE_COLUMN_SWIFT_BIC_CODE', classes: 'text-wrap' },
          { nameKey: 'SELLER_BANK_ACCOUNT.TABLE_COLUMN_DISCOUNT_TYPE' },
          {
            nameKey: 'SELLER_BANK_ACCOUNT.TABLE_COLUMN_DISCOUNT_ACCOUNT_HOLDER',
            classes: 'text-wrap',
          },
          { nameKey: 'SELLER_BANK_ACCOUNT.TABLE_COLUMN_DISCOUNT_BANK_NAME', classes: 'text-wrap' },
          { nameKey: 'SELLER_BANK_ACCOUNT.TABLE_COLUMN_DISCOUNT_ADDRESS' },
          { nameKey: 'SELLER_BANK_ACCOUNT.TABLE_COLUMN_ACTIONS', classes: 'actions fixed-width' },
        ],
      },
    };
  }

  private _configureTableRows(): ComponentConfiguration {
    const rowActions: Actions = {
      edit: {
        titleKey: 'SELLER_BANK_ACCOUNT.TABLE_ACTION_EDIT',
        handler: this.editAccount,
        icon: 'pencil-alt',
        testId: 'action-edit',
      },
      remove: {
        titleKey: 'SELLER_BANK_ACCOUNT.TABLE_ACTION_REMOVE',
        handler: this._onBankAccountRemove,
        icon: 'trash-alt',
        testId: 'action-delete',
        actionId: ACTION_REMOVE,
      },
    };

    return {
      component: SellerAccountsListRowComponent,
      inputs: {
        actions: rowActions,
      },
    };
  }

  private _findBankAccountIndex(bankAccount: SellerBankAccount): number {
    return this.bankAccountData.findIndex((oba) => {
      if (bankAccount.id) {
        return oba.id === bankAccount.id;
      } else {
        return oba.tempId === bankAccount.tempId;
      }
    });
  }

  private _removeTemporaryId(): void {
    this.bankAccountData.map((ba) => {
      if (ba.tempId) {
        delete ba.tempId;
      }
    });
  }

  private _initValidationMessages(): CreateSellerFormValidationMessages {
    return definitionGroupToMessages<CreateSellerFormValidationMessages>(
      {
        required: [msgRequired],
        maxLength: [msgDefaultMaxLength],
        contactName: [msgDefaultMaxLength, msgMinLength(minSellerNameLength)],
        name: [msgRequired, msgDefaultMaxLength],
        code: [
          msgRequired,
          msgDefaultMaxLength,
          msgMinLength(minSellerNameLength),
          { func: hasError('opco-code-unavailable'), key: 'ERRORS.OPCO_CODE_NOT_UNIQUE' },
        ],
        address1: [msgRequired, msgDefaultMaxLength],
        email: [msgEmailFormat, msgDefaultMaxLength],
        postCode: [msgMaxLength(maxSellerPostCodeLength)],
        customerIdentificationNumber: [msgMaxLength(sellerCinLength)],
        pointOfContact: [msgRequired],
        externalSystemReference: [msgMaxLength(maxSellerExternalSystemReferenceLength)],
        minimalPartialPortionThreshold: [msgMaxNumber(maxPercentageValue)],
      },
      () => this.form,
      () => this.submitted,
    );
  }

  private _isPartialPortionThresholdValue$(): Observable<boolean> {
    return this.form.controls.minimalPartialPortionThreshold.valueChanges.pipe(
      startWith(this.form.controls.minimalPartialPortionThreshold.getRawValue()),
      map((value: number) => value !== null && value > 0),
    );
  }
}
