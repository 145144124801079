import { Inject, Injectable } from '@angular/core';

import { from, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfigService } from './app-config/app-config.service';

import {
  MenuItemConfig,
  MenuItemsConfig,
  MenuOptionType,
  MenuSectionConfig,
} from '../config/menu-option.interface';
import { MENU_ITEMS_CONFIG_TOKEN } from '../di/menu-config.token';
import { MenuOption, MenuOptions } from '../model/menu-options';
import { AuthoritySet } from '../security/authority-set';

@Injectable()
export class MenuService {
  currentMenu = new ReplaySubject<MenuOption[]>(1);

  constructor(
    private config: AppConfigService,
    @Inject(MENU_ITEMS_CONFIG_TOKEN) private menuConfig: MenuItemsConfig,
  ) {}

  setCurrentMenu(menu: MenuOption[]) {
    this.currentMenu.next(menu);
  }

  getOptions(): Promise<MenuOption[]> {
    // TODO: TRFV2-3891 Refactor to proper types from "any"
    // eslint-disable-next-line
    const excludeOptions = (obj: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { _, ...excluded } = obj;
      return excluded;
    };

    return this.config.configAsPromise.then((c) =>
      c.menu.sections.map((section) => {
        const menuItems = section.options.map((value) => {
          const excluded = excludeOptions(value);
          const sectionConfig = <MenuSectionConfig>this.menuConfig[section.id];
          return {
            ...excluded,
            element: sectionConfig.options[value.id],
          } as MenuOption;
        });

        const elementExcluded = excludeOptions(this.menuConfig[section.id]);
        const sectionExcluded = excludeOptions(section);

        return {
          ...sectionExcluded,
          element: elementExcluded,
          options: menuItems,
        } as MenuOption;
      }),
    );
  }

  public getAuthorisedOptions(authorities: string[]) {
    return from(this.getOptions()).pipe(
      map((options) =>
        new MenuOptions(options).filter((o) => this.optionAuthorised(o, authorities)).asArray(),
      ),
    );
  }

  private optionAuthorised(option: MenuOption, authorities: string[]): boolean {
    const hasMenuOptionAuthority = (m: MenuOption, a: string[]) => {
      const opt = <MenuItemConfig>m.element;
      const requiredAuth = opt.permissions.requiredAuthority;
      return (
        !requiredAuth ||
        (typeof requiredAuth === 'string'
          ? a.indexOf(requiredAuth) !== -1
          : new AuthoritySet(requiredAuth).isSatisfiedBy(a))
      );
    };

    if (option.element.type === MenuOptionType.ITEM) {
      return hasMenuOptionAuthority(option, authorities);
    }

    return !!(
      option.element.type === MenuOptionType.SECTION &&
      option.options.find((menuOption) => hasMenuOptionAuthority(menuOption, authorities))
    );
  }
}
