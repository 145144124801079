export const maxSellerBankAccountNameLength = 60;
export const minSellerBankAccountNameLength = 2;
export const maxSellerBankAccountNumberLength = 35;
export const maxSwiftBicCodeLength = 11;
export const maxBranchCodeRoutingNumberLength = 15;
export const maxSellerBankNameLength = 60;
export const maxBankAddressLength = 40;
export const maxPostCodeLength = 30;
export const maxSellerAccountHolder = 70;

export const maxLengthSellerBankAccountConfig = {
  maxSellerBankAccountNameLength,
  minSellerBankAccountNameLength,
  maxSellerBankAccountNumberLength,
  maxSwiftBicCodeLength,
  maxBranchCodeRoutingNumberLength,
  maxSellerBankNameLength,
  maxBankAddressLength,
  maxPostCodeLength,
  maxSellerAccountHolder,
};
