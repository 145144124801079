import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { EntityId } from '../../interface/has-entity-id.interface';
import { DictionaryEntry } from '../../model/dictionary-entry.interface';
import { Environment, TransactionEnvironmentMapping } from '../../model/environment.interface';
import { PushEligibleEnvironment } from '../../model/push-eligible-environment.interface';
import { RestResponse } from '../../model/response.interface';
import { encodeParams } from '../../security/encode-params';
import { encodeEndpoint } from '../../security/encode-url';
import { toData } from './response-mapping';

@Injectable()
export class EnvironmentResourceService {
  constructor(private http: HttpClient, private translate: TranslateService) {}

  getPushEligibleEnvironments(versionId: number) {
    const params = encodeParams({ versionId });
    const url = encodeEndpoint('resources/environments/push-eligible');
    return this.http
      .get<RestResponse<PushEligibleEnvironment[]>>(url, { params })
      .pipe(map(toData));
  }

  getAllAssignedTransactionEnvironmentsForGivenUser(transactionId: EntityId) {
    const url = encodeEndpoint(
      'resources/transactions/{}/user-assigned-environments',
      transactionId,
    );
    return this.http.get<RestResponse<Environment[]>>(url).pipe(map(toData));
  }

  getAssignedTransactionEnvironments(transactionIds: EntityId[]) {
    const data = { transactionIds };
    const url = encodeEndpoint('resources/transactions/assigned-environments');
    return this.http.post<RestResponse<Environment[]>>(url, data).pipe(map(toData));
  }

  getAssignedTransactionEnvironmentsMapping(transactionIds: EntityId[]) {
    const data = { transactionIds };
    const url = encodeEndpoint('resources/transactions/assigned-environments-mapping');
    return this.http
      .post<RestResponse<TransactionEnvironmentMapping[]>>(url, data)
      .pipe(map(toData));
  }

  getAllEnvironmentTypes() {
    const url = encodeEndpoint('resources/transactions/available-environment-types');
    return this.http.get<RestResponse<DictionaryEntry[]>>(url).pipe(
      map((r) => r.data),
      map((environmentTypes) =>
        environmentTypes.map((environmentType) => ({
          entityId: environmentType.entityId,
          key: environmentType.entityId,
        })),
      ),
    );
  }
}
