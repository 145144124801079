import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { DictionaryEntry, DictionaryService, EmittedFormValue } from '@demica/core/core';
import { SellerBankAccount } from '@demica/resources/seller';

import { definitionGroupToMessages } from '../../../forms/validation-messages/message-builder';
import {
  msgMaxLength,
  msgMinLength,
  msgRequired,
} from '../../../forms/validation-messages/message-definitions';
import { requireSelect, validateNotEmpty } from '../../../forms/validators';
import {
  maxBankAddressLength,
  maxBranchCodeRoutingNumberLength,
  maxLengthSellerBankAccountConfig,
  maxPostCodeLength,
  maxSellerAccountHolder,
  maxSellerBankAccountNameLength,
  maxSellerBankAccountNumberLength,
  maxSellerBankNameLength,
  maxSwiftBicCodeLength,
  minSellerBankAccountNameLength,
} from '../validation/seller-bank-account-validation-constants';
import {
  SellerBankAccountForm,
  SellerBankAccountFormValidationMessages,
} from './seller-bank-account.model';

function maxLength(max: number) {
  return Validators.maxLength(max);
}

@Component({
  selector: 'trf-seller-bank-account',
  templateUrl: './seller-bank-account.component.html',
  styleUrls: ['./seller-bank-account.component.sass'],
})
export class SellerBankAccountComponent implements OnInit {
  @Input()
  bankAccountData: SellerBankAccount;

  @Input()
  submitted: boolean;

  @Output()
  dataChanged = new EventEmitter<EmittedFormValue<SellerBankAccount>>();

  readonly maxLengthConfig = maxLengthSellerBankAccountConfig;

  validationMessages = this._initValidationMessages();

  sellerBankAccountTypes = this._dictionaryService.getSellerBankAccountTypes();

  form: SellerBankAccountForm = this._fb.group({
    bankAccountName: [
      '',
      [
        validateNotEmpty,
        maxLength(maxSellerBankAccountNameLength),
        Validators.minLength(minSellerBankAccountNameLength),
      ],
    ],
    bankAccountNumber: ['', [validateNotEmpty, maxLength(maxSellerBankAccountNumberLength)]],
    branchCodeOrRoutingNumber: ['', [maxLength(maxBranchCodeRoutingNumberLength)]],
    swiftBic: ['', [maxLength(maxSwiftBicCodeLength)]],
    bankAccountType: [null as DictionaryEntry, [requireSelect]],
    accountHolder: ['', [validateNotEmpty, maxLength(maxSellerAccountHolder)]],
    bankName: ['', [maxLength(maxSellerBankNameLength)]],
    address1: ['', [maxLength(maxBankAddressLength)]],
    address2: ['', [maxLength(maxBankAddressLength)]],
    address3: ['', [maxLength(maxBankAddressLength)]],
    address4: ['', [maxLength(maxBankAddressLength)]],
    postCode: ['', [maxLength(maxPostCodeLength)]],
  });

  constructor(private _fb: FormBuilder, private _dictionaryService: DictionaryService) {}

  ngOnInit(): void {
    if (this.bankAccountData) {
      this.form.patchValue(this.bankAccountData);
    }

    this.form.statusChanges.subscribe(() => {
      this.dataChanged.emit({
        data: {
          ...this.bankAccountData,
          ...this.form.value,
        },
        isValid: this.form.valid,
      });
    });
  }

  private _initValidationMessages(): SellerBankAccountFormValidationMessages {
    return definitionGroupToMessages<SellerBankAccountFormValidationMessages>(
      {
        bankAccountName: [
          msgRequired,
          msgMaxLength(maxSellerBankAccountNameLength),
          msgMinLength(minSellerBankAccountNameLength),
        ],
        bankAccountNumber: [msgRequired, msgMaxLength(maxSellerBankAccountNumberLength)],
        swiftBic: [msgMaxLength(maxSwiftBicCodeLength)],
        branchCodeOrRoutingNumber: [msgMaxLength(maxBranchCodeRoutingNumberLength)],
        accountHolder: [msgRequired, msgMaxLength(maxSellerAccountHolder)],
        bankAddress: [msgMaxLength(maxBankAddressLength)],
        postCode: [msgMaxLength(maxPostCodeLength)],
        bankAccountType: [msgRequired],
        bankName: [msgMaxLength(maxSellerBankNameLength)],
      },
      () => this.form,
      () => this.submitted,
    );
  }
}
