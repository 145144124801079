<ng-container [formGroup]="group">
  <div
    class="column-label"
    [ngClass]="{ 'row-disabled': control.disabled || disabled }"
  >
    <div
      *ngIf="!!label"
      class="column-label"
    >
      <label
        [attr.class]="labelClasses"
        [attr.id]="label"
      >
        {{ label | translate }}
      </label>
      <ngp-tooltip-icon
        *ngIf="showHelperToolTip()"
        [tooltipKey]="helperToolTip"
        [iconAriaDesc]="ariaDescText"
      ></ngp-tooltip-icon>
    </div>
    <div class="column-input">
      <ng-select
        #select
        class="form-control"
        role="combobox"
        aria-haspopup="listbox"
        bindValue="id"
        [attr.tabindex]="control.disabled || disabled ? -1 : 0"
        [attr.aria-expanded]="select.isOpen"
        [attr.aria-describedby]="formControlName + '_notification'"
        [attr.aria-labelledby]="label ? label : defaultLabel"
        [attr.aria-required]="labelClasses?.includes('required')"
        [formControlName]="formControlName"
        [items]="treeOptions"
        [placeholder]="defaultLabel | translate"
        [searchable]="searchable"
        [searchFn]="searchTerm"
        [clearable]="clearable"
        [clearOnBackspace]="false"
        [tabIndex]="-1"
        [groupBy]="groupByKey"
        [labelForId]="formControlName"
        [notFoundText]="'NG_SELECT.NOT_FOUND' | translate"
        [loading]="loading"
        [readonly]="readonly"
        (ngModelChange)="onSelected($event)"
        (close)="select.element.focus()"
        (keydown.delete)="select.clearModel()"
        (open)="markAsDirty()"
      >
        <ng-template
          ng-option-tmp
          let-item="item$"
          let-index="index"
        >
          <span
            class="ng-option-label"
            [attr.title]="item.value?.label"
          >
            <trf-tree-select-option
              [option]="item.value"
              [selected]="item.selected"
              data-testid="select-option-text"
            ></trf-tree-select-option>
          </span>
        </ng-template>
      </ng-select>

      <div class="validation-error">
        <ngp-inline-notification
          [id]="formControlName + '_notification'"
          [message]="validations | firstError: (fieldPath ? fieldPath : formControlName)"
        >
        </ngp-inline-notification>
      </div>
    </div>

    <div
      *ngIf="infoTooltip"
      class="column-helper info"
    >
      <ngp-tooltip-icon
        [tooltipKey]="infoTooltip"
        [reversed]="true"
      ></ngp-tooltip-icon>
    </div>
  </div>
</ng-container>
