import { EntityId, Range } from '@demica/core/core';

import { buildMessages } from '../forms/validation-messages/message-builder';
import {
  msgMaxDecimalPlacesExceeded,
  msgMaxIntegerPlacesExceeded,
  msgMaxNumber,
  msgMinNumber,
  msgRequired,
} from '../forms/validation-messages/message-definitions';
import { hasError } from '../forms/validation-messages/message-predicates';
import { maxPercentageValue, minPercentageValue } from '../validation/funding-validation-constants';
import { RANGE_TYPE } from './range.constants';

export interface RangeRow extends Range {
  rangePercentage: boolean;
  valuePercentage: boolean;
}

export interface RangeParams {
  calculation: EntityId;
  rangeType: EntityId;
  rateType: EntityId;
}

export const createEmptyRange = (): Range => ({
  lowerRange: '',
  upperRange: '',
  value: '',
});

export const createFirstRange = (rangeType: EntityId, setDefaultUpper = true): Range => ({
  lowerRange: '0',
  upperRange: setDefaultUpper && rangeType === RANGE_TYPE.percentage ? '100' : '',
  value: '',
});

export const rangeValidations = buildMessages([
  msgRequired,
  msgMinNumber(minPercentageValue),
  msgMaxNumber(maxPercentageValue),
  msgMaxDecimalPlacesExceeded,
  msgMaxIntegerPlacesExceeded,
  {
    func: hasError('upper-lower-than-lower'),
    key: 'VALIDATION.RANGE_UPPER_MUST_BE_GREATER_THAN_LOWER',
  },
]);
