import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'ng-select[readonly]',
})
export class SelectReadonlyDirective {
  @Input()
  @HostBinding('class.ng-select-readonly')
  readonly = false;
}
