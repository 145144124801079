import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Route, RouterModule } from '@angular/router';

import { TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { ComponentsModule, DELEGATE_TRANSLATE_PIPE, SVG_ICON_HREF } from '@demica/components';
import {
  COOKIE_CONSENT_PROVIDER,
  CssVariableService,
  OneTrustService,
  ThemingService,
  publicAppCookieConsentFactory,
} from '@demica/core/core';
import {
  CLIENT_ASSETS_DIR,
  POST_LOGOUT,
  PRIVACY_NOTICE_PATH,
  PUBLIC_APP_PATH,
  SESSION_TIMEOUT_PATH,
} from '@demica/global-configuration';

import { AppInitializerComponent } from './public-app/app-initializer.component';
import { PublicAppComponent } from './public-app/public-app.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, `./assets/${CLIENT_ASSETS_DIR}/i18n/`, '.json');
}

export const publicAppRoutes: Route[] = [
  {
    path: `${PUBLIC_APP_PATH}/:lang`,
    component: AppInitializerComponent,
    children: [
      {
        path: SESSION_TIMEOUT_PATH,
        loadChildren: () =>
          import('./modules/session-timeout/session-timeout.module').then(
            (m) => m.SessionTimeoutModule,
          ),
      },
      {
        path: PRIVACY_NOTICE_PATH,
        loadChildren: () =>
          import('./modules/privacy-policy/privacy-notice.module').then(
            (m) => m.PrivacyNoticeModule,
          ),
      },
      {
        path: POST_LOGOUT,
        loadChildren: () =>
          import('./modules/post-logout/post-logout.module').then((m) => m.PostLogoutModule),
      },
    ],
  },
];

@NgModule({
  imports: [
    BrowserModule,
    RouterModule.forRoot(publicAppRoutes),
    HttpClientModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ComponentsModule,
  ],
  providers: [
    CssVariableService,
    ThemingService,
    { provide: COOKIE_CONSENT_PROVIDER, useClass: OneTrustService },
    {
      provide: APP_INITIALIZER,
      useFactory: publicAppCookieConsentFactory,
      deps: [COOKIE_CONSENT_PROVIDER],
      multi: true,
    },
    {
      provide: DELEGATE_TRANSLATE_PIPE,
      useClass: TranslatePipe,
    },
    {
      provide: SVG_ICON_HREF,
      deps: [ThemingService],
      useFactory: (themingService: ThemingService) => themingService.spriteFileLocation,
    },
  ],
  declarations: [PublicAppComponent, AppInitializerComponent],
  bootstrap: [PublicAppComponent],
})
export class PublicAppModule {}
