/**
 * These constants contain only a subset of real enum values
 * and they are used only to improve readability in
 * funding-fees-form.component.ts
 *
 * Field values are entityId of given option
 */
export const FUNDING_FEE_TYPE = {
  variableInterest: 5,
};

export const FUNDING_CALCULATION = {
  calculatedFee: 1,
  external: 2,
};

export enum FUNDING_AMOUNT_TYPE {
  none = 1,
  facalityAmount = 2,
  drawnAmount = 3,
  undrawnAmount = 4,
  transferred = 5,
}

export const FUNDING_AMOUNT_CURRENCY = {
  consolidatedAmount: 1,
  specificCurrency: 2,
};
