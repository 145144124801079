<trf-slidein-container
  headerText="OPCO_DETAILS_SLIDEIN.TITLE"
  [ngClass]="{ wide: hasEnabledSellerAccounts }"
  (slideinClose)="onClose()"
>
  <trf-create-seller-form
    trfSlideinFormChanges
    [countryOptions]="countryOptions"
    [standardIndustryCodeOptions]="sicCodesOptions"
    [legalStatusOptions]="legalStatusOptions"
    [clientName]="client.name"
    [sellerId]="sellerId"
    [hasEnabledSellerAccounts]="hasEnabledSellerAccounts"
    [partialsEnabled]="partialsEnabled"
    (sellerClose)="onClose()"
    (save)="onSave($event)"
  ></trf-create-seller-form>
</trf-slidein-container>
