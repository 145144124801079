import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective } from '@angular/forms';

import { emitChangeEvent } from '@demica/components';
import { addDotToPrefix, getFormControlName, TypedChanges } from '@demica/core/core';

import { OptionNamePipe } from '../../pipe/option-name.pipe';

import { ValidationMessage } from '../../forms/validation-messages/validation-message.interface';
import { SelectItem, TreeSelectConverter, TreeStructuredData } from './tree-select.converter';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'trf-form-tree-select-row',
  templateUrl: 'form-tree-select-row.component.html',
  styleUrls: ['./form-tree-select-row.component.sass'],
  providers: [OptionNamePipe, TreeSelectConverter],
})
export class FormTreeSelectRowComponent<T> implements OnInit, OnChanges {
  @ViewChild('select')
  select: NgSelectComponent;

  @Input()
  control: FormControl;
  @Input()
  fieldPath?: string;
  @Input()
  label: string;
  @Input()
  labelClasses?: string;
  @Input()
  defaultLabel?: string;
  @Input()
  translationPrefix?: string | string[];
  @Input()
  options: Array<TreeStructuredData<T>> = [];
  @Input()
  validations?: ValidationMessage[];
  @Input()
  disabled: boolean;
  @Input()
  readonly: boolean;
  @Input()
  helperToolTip?: string;
  @Input()
  groupByKey: string = null;
  @Input()
  clearable = true;
  @Input()
  searchable = true;
  @Input()
  infoTooltip?: string;
  @Input()
  ariaDescText?: string;
  @Input()
  ariaDescId?: string;
  @Input()
  loading?: boolean;
  @Input()
  idProvider: (item: TreeStructuredData<T>) => string;
  @Input()
  labelProvider: (item: TreeStructuredData<T>) => string;

  @Output()
  selected = new EventEmitter<unknown>();

  group: FormGroup;
  formControlName = '';
  dirty = false;
  treeOptions: SelectItem<T>[] = [];

  constructor(
    private _fgd: FormGroupDirective,
    private _optionName: OptionNamePipe,
    private _treeSelectConverter: TreeSelectConverter,
  ) {
    this.searchTerm = this.searchTerm.bind(this);
  }

  ngOnChanges(changes: TypedChanges<FormTreeSelectRowComponent<T>>): void {
    if (changes.control) {
      this.formControlName = getFormControlName(this.control);
    }

    if (changes.disabled) {
      changes.disabled.currentValue ? this.control.disable() : this.control.enable();
    }

    if (changes.options) {
      this._transformOptions();
    }
  }

  ngOnInit(): void {
    this.group = this._fgd.form;
    this.translationPrefix = addDotToPrefix(this.translationPrefix);
  }

  onSelected(event: unknown): void {
    if (this.options?.length > 0 && this.dirty) {
      emitChangeEvent(this.select.element);
      this.selected.emit(event);
    }
  }

  showHelperToolTip(): string | undefined {
    return this.helperToolTip;
  }

  searchTerm(term: string, item: unknown): boolean {
    const termToCompare = term.toLowerCase();
    const optionNameToCompare = this._optionName
      .transform(item, this.translationPrefix)
      .toLowerCase();
    return optionNameToCompare.includes(termToCompare);
  }

  markAsDirty(): void {
    this.dirty = true;
  }

  clear(): void {
    this.select.handleClearClick();
  }

  private _transformOptions(): void {
    if (this.options) {
      this.treeOptions = this._treeSelectConverter.convert<T>(
        this.options,
        (item) => this.idProvider(item),
        (item) => this.labelProvider(item),
      );
    }
  }

  get prefix(): string | string[] {
    if (!this.translationPrefix) return undefined;

    return this.translationPrefix[this.translationPrefix.length - 1] === '.'
      ? this.translationPrefix
      : this.translationPrefix + '.';
  }
}
