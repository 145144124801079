import { Injectable } from '@angular/core';

import { from, lastValueFrom, Observable } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';

import { CLIENT_ASSETS_DIR } from '@demica/global-configuration';
import { AppConfig, AppConfigResource } from '@demica/resources/app-config';

import { AppConfigFallbackService } from './app-config-fallback.service';

import { Locale } from '../../config/locale.enum';
import { RemoteConfigProvider } from '../../token/remote-config-provider.token';
import { normalizeLanguageList } from '../../utils/normalize-language-list';

@Injectable({ providedIn: 'root' })
export class AppConfigService implements RemoteConfigProvider {
  /**
   * AppConfig exposed as a JS Promise
   *
   * @type {Promise<AppConfig>}
   * @memberof AppConfigService
   */
  configAsPromise: Promise<AppConfig>;
  /**
   * AppConfig as RXjs Observable
   *
   * @type {Observable<AppConfig>}
   * @memberof AppConfigService
   */
  appConfig$: Observable<AppConfig>;

  constructor(
    private _appConfigResource: AppConfigResource,
    private _fallback: AppConfigFallbackService,
  ) {
    this._initializeAppConfig();
    this._exposeAppConfigAsPromise();
  }

  getSupportedLocalisations$(): Observable<Locale[]> {
    return from(this.configAsPromise).pipe(
      map((conf: AppConfig) =>
        normalizeLanguageList(
          conf.localisation.enabledLocalisations,
          conf.localisation.mockedLocalisations,
        ),
      ),
    );
  }

  getMockedLocalisations$(): Observable<string[]> {
    return from(this.configAsPromise).pipe(
      map((conf: AppConfig) => normalizeLanguageList(conf.localisation.mockedLocalisations)),
    );
  }

  getLanguageFileURL(reqLang: Locale): string {
    return `/assets/${CLIENT_ASSETS_DIR}/i18n/${reqLang}.json`;
  }

  private _initializeAppConfig(): void {
    this.appConfig$ = this._appConfigResource.getAppConfig$().pipe(
      switchMap((config) => this._fallback.mergeBaseConfigWithFallback$(config)),
      shareReplay(),
    );
  }

  private _exposeAppConfigAsPromise(): void {
    this.configAsPromise = lastValueFrom(this.appConfig$);
  }
}
