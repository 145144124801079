import { EntityId, HasEntityId } from '@demica/resources/common';

import { isDefined } from '../../utils/is-defined.utils';

// return all available filters if nothing is selected
export function selectedOrAllAvailableFilters(
  controlValue: HasEntityId,
  availableFilters: HasEntityId[],
): EntityId[] {
  return isDefined(controlValue)
    ? [controlValue.entityId]
    : availableFilters.map((value) => value.entityId);
}

export function selectedArrayOrAllAvailableFilters(
  controlValues: HasEntityId[],
  availableFilters: HasEntityId[],
): EntityId[] {
  return isDefined(controlValues) && controlValues.length
    ? controlValues.map((value) => value.entityId)
    : availableFilters.map((value) => value.entityId);
}
