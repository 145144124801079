import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

import {
  AllProd,
  DashboardFiltersResourceService,
  Environment,
  FilterParameters,
  FilterResource,
  SlideinContainerAbstractClass,
  TransactionFilterResource,
} from '@demica/core/core';

import { GlobalFilters } from '../../model/global-filters';

export type FiltersForm = FormGroup<{
  availableEnvironments: FormControl<Environment[]>;
  environments: FormControl<Environment[]>;
  clients: FormControl<FilterResource[]>;
  opcos: FormControl<FilterResource[]>;
  transactions: FormControl<TransactionFilterResource[]>;
}>;

@Component({
  selector: 'trf-filters-slidein',
  templateUrl: './filters-slidein.container.html',
  styleUrls: ['./filters-slidein.container.sass'],
})
export class FiltersSlideinContainerComponent
  extends SlideinContainerAbstractClass
  implements OnInit
{
  @Input()
  initialFilterParameters: FilterParameters;
  @Input()
  authority: string;
  @Input()
  opcosEnabled = true;

  environments: Environment[];
  clients: FilterResource[];
  opcos: FilterResource[];
  transactions: TransactionFilterResource[];

  loading = true;

  form: FiltersForm = this._fb.group({
    environments: [null],
    clients: [null],
    opcos: [null],
    transactions: [null],
    availableEnvironments: [null],
  });

  constructor(
    private _fb: FormBuilder,
    private _dashboardFiltersResourceService: DashboardFiltersResourceService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.form.patchValue(this.initialFilterParameters);
    this._dashboardFiltersResourceService.getAvailableFilters().subscribe((availableFilters) => {
      this.environments = availableFilters.environments;
      this.environments.unshift(AllProd);
      this.clients = availableFilters.clients;
      this.opcos = availableFilters.opcos;
      this.transactions = availableFilters.transactions;
      this.loading = false;
    });
  }

  onApply(): void {
    this.modal.close(this._prepareFilterResults(this.form.getRawValue()));
  }

  clearAllFilters(): void {
    this.form.reset({
      environments: [AllProd],
      clients: [],
      opcos: [],
      transactions: [],
      availableEnvironments: this.initialFilterParameters.availableEnvironments,
    });
  }

  private _prepareFilterResults(currentFilterParameters: FilterParameters): GlobalFilters {
    return {
      currentFilterParameters: currentFilterParameters,
      initialFilterParameters: {
        availableEnvironments: currentFilterParameters.availableEnvironments,
        environments: this.environments,
        clients: this.clients,
        opcos: this.opcos,
        transactions: this.transactions,
      },
    };
  }
}
