import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { combineLatest, Subject } from 'rxjs';
import { skip, takeUntil, tap } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import {
  generateUUID,
  LocalisationService,
  UserPreferences,
  ThemingService,
  MoneyService,
} from '@demica/core/core';

import { ChartGenerateService } from '../../service/chart-generate.service';

import { ExportableChart } from '../../model/chart-component.interface';

import { ChartType } from '../../model/chart-type';
import { convertMapToKeyValueArray } from './convert-map-to-key-value-array';
import { HorizontalLine } from './horizontal-line.interface';
import { MULTIPLE_AXES_X } from './line-chart-consts';
import { LineChartData } from './line-chart-data';
import { ChartAPI, ChartConfiguration } from 'c3';

@Component({
  selector: 'trf-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.sass'],
})
export class LineChartComponent implements ExportableChart, AfterViewInit, OnDestroy, OnChanges {
  @Input()
  chartId: string = generateUUID();

  @Input()
  chartData: LineChartData;

  @Input()
  xSeries: string;
  /** Enables you to avoid re-calculation to user currency if that's already been done in the parent component  */
  @Input()
  convertChartDataToUserCurrency = true;

  @Input()
  axesXMap: Map<string, string>;

  @ViewChild('chartContainer', { static: true })
  chartContainer: ElementRef;

  chartColors: string[];
  legend: string[] = [];

  chartConfiguration: ChartConfiguration;
  private _chart: ChartAPI;
  private _isFirstChange = false;
  private _currency: string;

  private _destroyed$ = new Subject<void>();
  private _toggledLegendItems: string[] = [];

  constructor(
    private _translateService: TranslateService,
    private _localisationService: LocalisationService,
    private _userPreferences: UserPreferences,
    private _moneyService: MoneyService,
    private _themingService: ThemingService,
    private _chartGenerateService: ChartGenerateService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this._isFirstChange = changes.chartData.isFirstChange();
    if (!this._isFirstChange) {
      this.generateChart();
    }
  }

  ngAfterViewInit(): void {
    combineLatest([this._localisationService.locale, this._userPreferences.currency])
      .pipe(
        tap(([, currency]) => {
          this._currency = currency;
        }),
        skip(1),
        takeUntil(this._destroyed$),
      )
      .subscribe(([, currency]) => {
        this.convertHorizontalLinesToGivenCurrency(currency);
        this.convertLineChartValuesToGivenCurrency(currency);
        this.generateChart();
      });

    if (this._isFirstChange) {
      this.generateChart();
    }
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
    this._chart?.destroy();
  }

  getAxes() {
    if (this.chartData.hasAdditionalAxisValuesDefined()) {
      return {
        [this._translateService.instant(this.chartData.getYAxisAdditionalLabel())]:
          this.chartData.hasAdditionalAxisValuesDefined() ? 'y2' : null,
      };
    } else {
      return { ['']: '' };
    }
  }

  translateMap(map: Map<string, string>): Map<string, string> {
    const resultDataArray = new Map();
    if (map != null) {
      map.forEach((value, key) =>
        resultDataArray.set(this._translateService.instant(key), this.translateValue(value)),
      );
    }
    return resultDataArray;
  }

  translateValue(value: string) {
    const lastIndexOf = value.lastIndexOf(MULTIPLE_AXES_X);
    return lastIndexOf > -1
      ? this._translateService.instant(value.slice(0, lastIndexOf)) + value.slice(lastIndexOf)
      : this._translateService.instant(value);
  }

  generateChart() {
    this.chartConfiguration = {
      bindto: '.line-chart-id' + this.chartId,
      data: {
        xs: this.chartData.hasVerticalAxisValuesDefined()
          ? convertMapToKeyValueArray(this.translateMap(this.axesXMap))
          : null,
        x: this.xSeries,
        columns: this.getChartValues(),
        names: this.chartData.getLabelsNames(),
        axes: this.getAxes(),
        type: this.chartData.getChartType(),
        types: this.getChartValueTypes(),
        groups: [this.chartData.getChartValueGroups()],
        order: this.chartData.getCustomOrder()
          ? this.chartData.getCustomOrder()
          : (firstLabel, secondLabel) => this.chartData.orderLabels(firstLabel, secondLabel),
        colors: this.chartData.getColors()
          ? this.chartData.getColors()
          : this.chartData.getDataCustomColors(this._themingService.chartColors),
        color: this.chartData.getColorGenerator(),
        hide: this.getHidden(),
      },
      bar: {
        width: {
          ratio: this.chartData.getBarValuesLength() >= 12 ? 0.25 : 0.65,
        },
      },
      axis: {
        x: {
          type: this.chartData.getAxisType(),
          tick: this.chartData.getXAxesTick(),
          label: {
            text: this.chartData.getXAxisLabel(),
            position: 'outer-center',
          },
        },
        y: this.chartData.getYAxisConfiguration(),
        y2: this.chartData.getY2AxisConfiguration(),
      },
      grid: {
        x: {
          show: this.chartData.showXAxisGridLines(),
        },
        y: {
          show: this.chartData.showYAxisGridLines(),
          lines: this.getHorizontalLines(),
        },
      },
      legend: {
        position: this.chartData.getLegend().position,
        show: this.chartData.getLegend().show,
        hide: this.chartData.getHiddenCharts(),
        item: {
          onmouseover: (id) => {
            if (!this._toggledLegendItems.find((value) => value === id)) {
              this._chart.focus(id);
            }
            if (this.chartData.hasLegendTooltip()) {
              this.chartData
                .getLegendTooltip()
                .showTooltip(this.chartData.getLegendTooltip().legendTooltipSource[id]);
            }
          },
          onmouseout: () => {
            this._chart.revert();
            if (this.chartData.hasLegendTooltip()) {
              this.chartData.getLegendTooltip().revert();
            }
          },
          onclick: (id) => {
            const exists = this._toggledLegendItems.find((storedId) => storedId === id);
            if (!exists) {
              this._toggledLegendItems.push(id);
              this._chart.defocus(id);
              this.focusOthersDataSets(id);
            } else {
              this._toggledLegendItems = this._toggledLegendItems.filter(
                (storedId) => storedId !== exists,
              );
              this._chart.focus(id);
            }
            this._chart.toggle(id);
          },
        },
      },
      line: {
        connectNull: true,
      },
      point: {
        show: this.chartData.showChartLinePoints(),
        focus: {
          expand: {
            enabled: false,
          },
        },
      },
      tooltip: {
        order: this.chartData.getTooltipLabelOrder(),
        format: this.chartData.getTooltipOptions(),
        contents: this.chartData.getCustomTooltip(),
      },
      /*
        It seems that this onredered callback working not as expected. It looks like a c3js bug.
        SetTimeout fix the problem.
         */
      onrendered: () =>
        setTimeout(
          () => this.chartData.getOnRenderedCallback() && this.chartData.getOnRenderedCallback()(),
        ),
    };

    this._chart = this._chartGenerateService.generate(this.chartConfiguration);
  }

  getChartApi(): ChartAPI {
    return this._chart;
  }

  getChartValueTypes() {
    const obj: Record<string, string> = {};
    this.chartData.getLineChartValues().forEach((lineChartValue) => {
      if (lineChartValue.chartType && lineChartValue.name) {
        obj[this._translateService.instant(lineChartValue.name)] = lineChartValue.chartType;
      }
    });
    return obj;
  }

  getChartContainer(): ElementRef {
    return this.chartContainer;
  }

  getChartDataForExport(): LineChartData {
    return this.chartData;
  }

  getChartType(): ChartType {
    return ChartType.LINE;
  }

  private focusOthersDataSets(dataSetId: string) {
    interface HasId {
      id: string;
    }

    const dataSets = <HasId[]>(<unknown>this._chart.data());
    const dataSetsExceptToggledOf = dataSets.filter((d) => d.id !== dataSetId);

    const setsToFocus: string[] = [];

    dataSetsExceptToggledOf.forEach((d) => {
      if (!this._toggledLegendItems.find((ids) => ids === d.id)) {
        setsToFocus.push(d.id);
      }
    });

    this._chart.focus(setsToFocus);
  }

  private convertHorizontalLinesToGivenCurrency(userCurrency: string) {
    if (this.convertChartDataToUserCurrency) {
      this.chartData.getYAxisLines().forEach((horizontalLine) => {
        if (horizontalLine.currency)
          this._moneyService
            .convertMoney(
              {
                amount: horizontalLine.value,
                currency: horizontalLine.currency,
                referenceCurrencyRate: 1,
                referenceCurrency: horizontalLine.currency,
              },
              userCurrency,
            )
            .subscribe((calculatedValue) => {
              // FIXME Horizontal Line should be treated as immutable
              horizontalLine.value = calculatedValue.amount;
              horizontalLine.currency = calculatedValue.currency;
            });
      });
    }
  }

  private convertLineChartValuesToGivenCurrency(userCurrency: string) {
    if (this.convertChartDataToUserCurrency) {
      this.chartData.getLineChartValues().forEach((lineChartValue) => {
        lineChartValue.values.forEach((value, index) => {
          if (lineChartValue.currency)
            this._moneyService
              .convertMoney(
                {
                  amount: Number(value),
                  currency: lineChartValue.currency,
                  referenceCurrencyRate: 1,
                  referenceCurrency: lineChartValue.currency,
                },
                userCurrency,
              )
              .subscribe((calculatedValue) => {
                lineChartValue.values[index] = String(calculatedValue.amount);
              });
        });

        if (lineChartValue.currency)
          // FIXME Line Chart Value should be treated as immutable
          lineChartValue.currency = userCurrency;
      });
    }
  }

  private getChartValues() {
    const chartValues = this.chartData.getChartValues();
    if (chartValues) {
      chartValues.forEach((value) => {
        if (value[0] === 'y2') {
          value[0] = this.translateValue(this.chartData.getYAxisAdditionalLabel());
        } else {
          value[0] = this.translateValue(value[0]);
        }
      });
    }
    return chartValues;
  }

  private getHorizontalLines() {
    const translatedHorizontalLines: HorizontalLine[] = [];
    if (this.chartData.getYAxisLines())
      this.chartData.getYAxisLines().forEach((line) =>
        translatedHorizontalLines.push({
          text: this._translateService.instant(line.text),
          value: line.value,
          position: line.position,
        }),
      );
    return translatedHorizontalLines;
  }

  private getHidden(): string[] {
    return this.chartData.getHidden().map((name) => this.translateValue(name));
  }
}
