import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { byProperty, DictionaryService } from '@demica/core/core';
import { CLIENT_ASSETS_DIR } from '@demica/global-configuration';

import { SicCode } from '../model/sic-codes-translation';

@Injectable({
  providedIn: 'root',
})
export class StandardIndustryCodesService {
  constructor(
    private dictionaryService: DictionaryService,
    private http: HttpClient,
    private translate: TranslateService,
  ) {}

  standardIndustryCodesAsSelectOptions(): Observable<SicCode[]> {
    const sicCodes = this.dictionaryService.getStandardIndustryCodes();
    return this.http
      .get(`/assets/${CLIENT_ASSETS_DIR}/i18n/sicCodes/${this.translate.currentLang}.json`)
      .pipe(
        map((translations) =>
          Object.entries(translations)
            .map(([key, name]) => {
              const temp = sicCodes.find((code) => code.key === key);
              return {
                ...temp,
                name,
              };
            })
            .sort(byProperty('key')),
        ),
        map((translations) =>
          translations.map((translation) => ({
            entityId: translation.entityId,
            key: `${translation.key} - ${translation.name}`,
          })),
        ),
      );
  }
}
