import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  CodeAvailabilityResponse,
  DeleteResponse,
  encodeEndpoint,
  encodeParams,
  EntityId,
  PostResponse,
  PutResponse,
  RestResponse,
  toData,
} from '@demica/resources/common';

import { TemplateCalendarFinalVersion } from '../model/template-calendar-final-version.interface';
import {
  TemplateCalendarBase,
  TemplateCalendarDetailsDto,
  TemplateCalendarDto,
} from '../model/template-calendar.interface';

@Injectable({
  providedIn: 'root',
})
export class CalendarTemplateResourceService {
  constructor(private _http: HttpClient) {}

  getCalendars$(): Observable<TemplateCalendarBase[]> {
    const url = encodeEndpoint('resources/templates/calendars');

    return this._http.get<RestResponse<TemplateCalendarBase[]>>(url).pipe(map(toData));
  }

  getFinalCalendarsForWorkingDay$(workingDay: boolean): Observable<TemplateCalendarFinalVersion[]> {
    const params = encodeParams({ workingDay });
    const url = encodeEndpoint('resources/templates/calendars/finalVersion');

    return this._http
      .get<RestResponse<TemplateCalendarFinalVersion[]>>(url, { params })
      .pipe(map((result) => toData(result)));
  }

  createCalendar$(data: TemplateCalendarDetailsDto): Observable<PostResponse> {
    const url = encodeEndpoint('resources/templates/calendars');

    return this._http.post<RestResponse<PostResponse>>(url, data).pipe(map(toData));
  }

  getCalendar$(calendarId: EntityId): Observable<TemplateCalendarDto> {
    const url = encodeEndpoint('resources/templates/calendars/{}', calendarId);

    return this._http.get<RestResponse<TemplateCalendarDto>>(url).pipe(map(toData));
  }

  updateCalendar$(calendarId: EntityId, data: TemplateCalendarDetailsDto): Observable<PutResponse> {
    const url = encodeEndpoint('resources/templates/calendars/{}', calendarId);

    return this._http.put<RestResponse<PutResponse>>(url, data).pipe(map(toData));
  }

  deleteCalendar$(calendarId: EntityId, entityRevision: number): Observable<DeleteResponse> {
    const params = encodeParams({ entityRevision });
    const url = encodeEndpoint('resources/templates/calendars/{}', calendarId);

    return this._http.delete<RestResponse<DeleteResponse>>(url, { params }).pipe(map(toData));
  }

  promoteCalendar$(calendarId: EntityId, data: unknown): Observable<PostResponse> {
    const url = encodeEndpoint('resources/templates/calendars/{}/promote', calendarId);

    return this._http.post<RestResponse<PostResponse>>(url, data).pipe(map(toData));
  }

  checkCalendarNameAvailable$(
    name: string,
    templateId?: EntityId,
  ): Observable<CodeAvailabilityResponse> {
    const params = encodeParams({ name, templateId });
    const url = encodeEndpoint('resources/templates/calendars/name-availability');

    return this._http
      .get<RestResponse<CodeAvailabilityResponse>>(url, { params })
      .pipe(map(toData));
  }
}
