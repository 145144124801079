import { AppEnvironment } from '@demica/core/core';

import { environment as overrides } from '../generated/environments';

export const environment: AppEnvironment = Object.assign(
  {
    production: true,
    profiler: false,
    yFilesLicenseConfig: {},
  },
  overrides,
);
