<ng-container [formGroup]="group">
  <div>
    <div
      *ngIf="!!label || allowEmptyLabel"
      class="column-label"
      [ngClass]="{ 'row-disabled': group.get(formControlName).disabled || disable }"
    >
      <label
        *ngIf="!!label || allowEmptyLabel"
        [for]="formControlName"
        [class]="labelClasses"
      >
        {{ label ? (label | translate) : "&nbsp;" }}
      </label>
    </div>

    <div class="text-row-wrapper">
      <div class="column-input">
        <input
          #textInput
          class="form-control {{ inputClasses }}"
          type="text"
          trfTrim
          [id]="formControlName"
          [maxlength]="maxLength"
          [disableControl]="disable"
          [formControlName]="formControlName"
          [placeholder]="placeholder | translate"
          [readonly]="readonly"
          [attr.aria-describedby]="
            formControlName +
            '_notification' +
            ' ' +
            formControlName +
            FormRowClass.warningNotificationIdPostfix
          "
          [attr.aria-required]="labelClasses?.includes('required')"
        />
        <div class="validation-error">
          <ngp-inline-notification
            [id]="formControlName + '_notification'"
            [message]="validations | firstError: formControlName"
          >
          </ngp-inline-notification>
          <ng-container #additionalValidationContainer></ng-container>
        </div>
      </div>
      <div
        *ngIf="infoTooltip"
        class="column-helper info"
      >
        <ngp-tooltip-icon
          [reversed]="true"
          [tooltipKey]="infoTooltip"
        ></ngp-tooltip-icon>
      </div>
    </div>
  </div>
</ng-container>
