import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroupDirective, FormControl, FormGroup } from '@angular/forms';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { errorNotification, Notifications } from '@demica/core/core';

import { maxPercentageValue } from '../../validation/funding-validation-constants';
import BigNumber from 'bignumber.js';

@Component({
  selector: 'trf-form-decimal-min-max-row',
  templateUrl: './form-decimal-min-max-row.component.html',
  styleUrls: ['./form-decimal-min-max-row.component.sass'],
})
export class FormDecimalMinMaxRowComponent implements OnInit, OnDestroy {
  @Input()
  nameMinControl: FormControl;
  @Input()
  nameMaxControl: FormControl;
  @Input()
  labelMin: string;
  @Input()
  labelMax: string;
  @Input()
  isPercentage?: boolean;

  percentageMaxValue = maxPercentageValue;

  showError = false;
  group: FormGroup;

  notifications = new Notifications();

  private errors = errorNotification('');
  private destroySubject = new Subject<void>();
  private groupErrorCode = 'error';

  constructor(private fgd: FormGroupDirective) {}

  ngOnInit() {
    this.errors.key = this.isPercentage
      ? 'DASHBOARD_LIMITS.PERCENTAGE_VALIDATION_ERROR_MESSAGE'
      : 'DASHBOARD_LIMITS.AMOUNT_VALIDATION_ERROR_MESSAGE';

    this.group = this.fgd.form;

    this.group.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe(() => {
      const maximumAmountValue: number = this.nameMaxControl.value;
      if (maximumAmountValue || maximumAmountValue === 0) {
        const maximumAmount = new BigNumber(maximumAmountValue);
        const minimumAmount = new BigNumber(this.nameMinControl.value);

        if (minimumAmount.isGreaterThan(maximumAmount)) {
          this.showGroupFormError(true);
        } else {
          this.showGroupFormError(false);
        }
      } else {
        this.showGroupFormError(false);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  private showGroupFormError(showError: boolean) {
    if (showError) {
      this.notifications.add(this.errors);
    } else {
      this.notifications.remove(this.errors);
    }
    const setFormFieldErrors = (formControl: FormControl, hasError: boolean) => {
      const errors = formControl.errors ?? {};

      if (hasError) {
        errors[this.groupErrorCode] = true;
      } else if (errors[this.groupErrorCode]) {
        delete errors[this.groupErrorCode];
      }

      formControl.setErrors(Object.keys(errors).length ? errors : null);
    };

    setFormFieldErrors(this.nameMinControl, showError);
    setFormFieldErrors(this.nameMaxControl, showError);
  }
}
