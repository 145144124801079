<trf-form-select-row
  [name]="name"
  [control]="control"
  [label]="label"
  [labelClasses]="labelClasses"
  [defaultLabel]="defaultLabel"
  [translationPrefix]="translationPrefix"
  [options]="options$ | async"
  [validations]="validations"
  [disabled]="disabled"
  [helperToolTip]="helperToolTip"
  [groupByKey]="groupByKey"
  [clearable]="clearable"
  [searchable]="searchable"
  [infoTooltip]="infoTooltip"
  [ariaDescText]="ariaDescText"
  [ariaDescId]="ariaDescId"
  [loading]="loading"
  (selected)="this.selected.emit($event)"
></trf-form-select-row>
