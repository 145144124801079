import { Injectable } from '@angular/core';

import { HasEntityId } from '../interface/has-entity-id.interface';
import { DictionaryEntry } from '../model/dictionary-entry.interface';
import { isKey } from '../utils/object';

// checked every dictionary marked with that key, and it is only used in modules we are working on
const readableDictionaryKey = 'name';

@Injectable()
export class DictionaryService {
  dictionariesData: Record<string, DictionaryEntry[]>;

  initialize(dictionariesData: Record<string, DictionaryEntry[]>) {
    this.dictionariesData = dictionariesData;
  }

  getBusinessDayConventions(): DictionaryEntry[] {
    return this.getDictionary('BusinessDayConvention', readableDictionaryKey);
  }

  getMonths(): DictionaryEntry[] {
    return this.getDictionary('Month', readableDictionaryKey);
  }

  getWeeks(): DictionaryEntry[] {
    return this.getDictionary('WeekNumber', readableDictionaryKey);
  }

  getDaysOfWeek(): DictionaryEntry[] {
    return this.getDictionary('DayOfWeek', readableDictionaryKey);
  }

  getFileFormatTypes(): DictionaryEntry[] {
    return this.getDictionary('FileFormatType');
  }

  getFileFormatFileTypes(): DictionaryEntry[] {
    return this.getDictionary('FileFormatFileType');
  }

  getValidationRules(): DictionaryEntry[] {
    return this.getDictionary('ValidationRule');
  }

  getInheritanceRules(): DictionaryEntry[] {
    return this.getDictionary('InheritanceRule');
  }

  getInheritedColumnTypes(): DictionaryEntry[] {
    return this.getDictionary('UserDefinedInheritedColumnTypes');
  }

  getFieldDelimiters(): DictionaryEntry[] {
    return this.getDictionary('FieldDelimiter', 'delimiter');
  }

  getStringDelimiters(): DictionaryEntry[] {
    return this.getDictionary('StringDelimiter');
  }

  getAutoDefaultingRules(): DictionaryEntry[] {
    return this.getDictionary('AutoDefaultingRule');
  }

  getNegativeInvoiceActions(): DictionaryEntry[] {
    return this.getDictionary('NegativeInvoiceAction');
  }

  getIncreasedInvoiceActions(): DictionaryEntry[] {
    return this.getDictionary('IncreasedInvoiceAction');
  }

  getProgrammeIncreasedInvoiceActions(): DictionaryEntry[] {
    return this.getDictionary('ProgrammeIncreasedInvoiceAction');
  }

  getReappearingInvoiceActions(): DictionaryEntry[] {
    return this.getDictionary('ReappearingInvoiceAction');
  }

  getProgrammeReappearingInvoiceActions(): DictionaryEntry[] {
    return this.getDictionary('ProgrammeReappearingInvoiceAction');
  }

  getDuplicateInvoiceActions(): DictionaryEntry[] {
    return this.getDictionary('DuplicateInvoiceAction');
  }

  getUserDefinitionTypes(): DictionaryEntry[] {
    return this.getDictionary('UserDefinitionType');
  }

  getUserDefinitionValueTypes(): DictionaryEntry[] {
    return this.getDictionary('UserDefinitionValueType');
  }

  getSelectionThresholds(): DictionaryEntry[] {
    return this.getDictionary('SelectionThreshold');
  }

  getPreferenceScores(): DictionaryEntry[] {
    return this.getDictionary('PreferenceScore');
  }

  getSelectionSetRuleTypes(): DictionaryEntry[] {
    return this.getDictionary('RuleType');
  }

  getSelectionSetScoringMethods(): DictionaryEntry[] {
    return this.getDictionary('ScoringMethod');
  }

  getLimitTypes(): DictionaryEntry[] {
    return this.getDictionary('LimitType');
  }

  getOpcoLimitTypes(): DictionaryEntry[] {
    return this.getDictionary('OpcoLimitType');
  }

  getProgrammeLimitTypes(): DictionaryEntry[] {
    return this.getDictionary('ProgrammeLimitType', readableDictionaryKey);
  }

  getProgrammeTypes(): DictionaryEntry[] {
    return this.getDictionary('ProgrammeType');
  }

  getDefaultLimitTypes(): DictionaryEntry[] {
    return this.getDictionary('DefaultLimitType', readableDictionaryKey);
  }

  getSpecificActions(): DictionaryEntry[] {
    return this.getDictionary('SpecificAction');
  }

  getSystemTypes(): DictionaryEntry[] {
    return this.getDictionary('SystemType');
  }

  getReportTypes(): DictionaryEntry[] {
    return this.getDictionary('ReportType');
  }

  getReportFormats(): DictionaryEntry[] {
    return this.getDictionary('ReportFormat');
  }

  getTimeSeriesTypes(): DictionaryEntry[] {
    return this.getDictionary('TimeSeriesType');
  }

  getTimeSeriesInterpolationTypes(): DictionaryEntry[] {
    return this.getDictionary('TimeSeriesInterpolationType');
  }

  getIneligibilityConditionTypes(): DictionaryEntry[] {
    return this.getDictionary('IneligibilityConditionType');
  }

  getPricingDiscountTypes(): DictionaryEntry[] {
    return this.getDictionary('PricingDiscountType', readableDictionaryKey);
  }

  getPricingApplications(): DictionaryEntry[] {
    return this.getDictionary('PricingApplication');
  }

  getPricingMethods(): DictionaryEntry[] {
    return this.getDictionary('PricingMethod', readableDictionaryKey);
  }

  getDateModifierType(): DictionaryEntry[] {
    return this.getDictionary('DateModifierType', readableDictionaryKey);
  }

  getPeriodModifierOffsetType(): DictionaryEntry[] {
    return this.getDictionary('PeriodModifierOffsetType', readableDictionaryKey);
  }

  getOffsetPeriods(): DictionaryEntry[] {
    return this.getDictionary('PricingOffsetPeriod');
  }

  getPricingBasis(): DictionaryEntry[] {
    return this.getDictionary('PricingBasis', readableDictionaryKey);
  }

  getPricingRateTypes(): DictionaryEntry[] {
    return this.getDictionary('PricingCurrencyRateType', readableDictionaryKey);
  }

  getReserveTypes(): DictionaryEntry[] {
    return this.getDictionary('ReserveType');
  }

  getReserveMethods(): DictionaryEntry[] {
    return this.getDictionary('ReserveMethod');
  }

  getReserveBasis(): DictionaryEntry[] {
    return this.getDictionary('ReserveBasis');
  }

  getReserveFloor(): DictionaryEntry[] {
    return this.getDictionary('ReserveFloor');
  }

  getVolatilityPeriods(): DictionaryEntry[] {
    return this.getDictionary('VolatilityPeriod');
  }

  getAmortisation(): DictionaryEntry[] {
    return this.getDictionary('Amortisation');
  }

  getRating(): DictionaryEntry[] {
    return this.getDictionary('Rating');
  }

  getAgency(): DictionaryEntry[] {
    return this.getDictionary('Agency');
  }

  getRateBasis(): DictionaryEntry[] {
    return this.getDictionary('RateBasis');
  }

  getFeeType(): DictionaryEntry[] {
    return this.getDictionary('FeeType');
  }

  getFeeCalculation(): DictionaryEntry[] {
    return this.getDictionary('FeeCalculation');
  }

  getBasis(): DictionaryEntry[] {
    return this.getDictionary('Basis');
  }

  getInterestInArrearsBasis(): DictionaryEntry[] {
    return this.getDictionary('InterestInArrearsBasis', readableDictionaryKey);
  }

  getInterestCurrencyRateType(): DictionaryEntry[] {
    return this.getDictionary('InterestCurrencyRateType');
  }

  getBasisCurrency(): DictionaryEntry[] {
    return this.getDictionary('BasisCurrency');
  }

  getRange(): DictionaryEntry[] {
    return this.getDictionary('Range');
  }

  getRateType(): DictionaryEntry[] {
    return this.getDictionary('RateType');
  }

  getExpenseTypes(): DictionaryEntry[] {
    return this.getDictionary('ExpenseType');
  }

  getExpenseCalculationMethod(): DictionaryEntry[] {
    return this.getDictionary('ExpenseCalculationMethod');
  }

  getExpenseBasis(): DictionaryEntry[] {
    return this.getDictionary('ExpenseBasis');
  }

  getDefaultedReceivables(): DictionaryEntry[] {
    return this.getDictionary('ReleaseLimit');
  }

  getPurchasingModes(): DictionaryEntry[] {
    return this.getDictionary('ProgrammePurchasingMode');
  }

  getDelinquencyActions(): DictionaryEntry[] {
    return this.getDictionary('DelinquencyAction');
  }

  getDefaultingActions(): DictionaryEntry[] {
    return this.getDictionary('DefaultingAction');
  }

  getWriteOffActions(): DictionaryEntry[] {
    return this.getDictionary('WriteOffAction');
  }

  getProgrammeDelinquencyAction(): DictionaryEntry<number>[] {
    return this.getDictionary('ProgrammeDelinquencyAction');
  }

  getReadableProgrammeDelinquencyAction(): DictionaryEntry[] {
    return this.getDictionary('ProgrammeDelinquencyAction', readableDictionaryKey);
  }

  getProgrammeDefaultingAction(): DictionaryEntry<number>[] {
    return this.getDictionary('ProgrammeDefaultingAction');
  }

  getReadableProgrammeDefaultingAction(): DictionaryEntry[] {
    return this.getDictionary('ProgrammeDefaultingAction', readableDictionaryKey);
  }

  getMissingDebtorActions(): DictionaryEntry[] {
    return this.getDictionary('MissingDebtorAction');
  }

  getMissingSellerActions(): DictionaryEntry[] {
    return this.getDictionary('MissingSellerAction');
  }

  getTransactionTypes(): DictionaryEntry[] {
    return this.getDictionary('TransactionType');
  }

  getProcessingTriggers(): DictionaryEntry[] {
    return this.getDictionary('ProcessingTrigger');
  }

  getExpenseExternalValueType(): DictionaryEntry[] {
    return this.getDictionary('ExpenseExternalValueType');
  }

  getLiabilityCalculationFeature(): DictionaryEntry<number>[] {
    return this.getDictionary('LiabilityCalculationFeature');
  }

  getReserveAmountTypes(): DictionaryEntry[] {
    return this.getDictionary('ReserveAmountType');
  }

  getFundingIssueValueType(): DictionaryEntry[] {
    return this.getDictionary('FundingIssueValueType');
  }

  getFeesTypes(): DictionaryEntry[] {
    return this.getDictionary('TLFFeeType');
  }

  getFeeDirection(): DictionaryEntry[] {
    return this.getDictionary('FeeDirection');
  }

  getVatRateInformation(): DictionaryEntry[] {
    return this.getDictionary('VatRateInformation');
  }

  getFeesCalculationMethod(): DictionaryEntry[] {
    return this.getDictionary('FeeCalculationMethod');
  }

  getFeesBasis(): DictionaryEntry[] {
    return this.getDictionary('FeeBasis');
  }

  getFeesExternalValueType(): DictionaryEntry[] {
    return this.getDictionary('FeeExternalValueType');
  }

  getSellerBankAccountTypes(): DictionaryEntry[] {
    return this.getDictionary('SellerBankAccountType', 'name');
  }

  getStandardIndustryCodes(): DictionaryEntry[] {
    return this.getDictionary('StandardIndustryCode', 'name');
  }

  getLegalStatuses(): DictionaryEntry[] {
    return this.getDictionary('SellerLegalStatus', 'name');
  }

  getReportTriggerTypes(): DictionaryEntry[] {
    return this.getDictionary('ReportTriggerType');
  }

  getProgrammePurchasingWindowType(): DictionaryEntry[] {
    return this.getDictionary('ProgrammePurchasingWindowType');
  }

  getTransactionPauses(): DictionaryEntry[] {
    return this.getDictionary('TransactionPause');
  }

  getCompoundingFrequency(): DictionaryEntry[] {
    return this.getDictionary('CompoundingFrequency', readableDictionaryKey);
  }

  getPeriodCalculationMethods(): DictionaryEntry[] {
    return this.getDictionary('PeriodCalculationMethod', readableDictionaryKey);
  }

  getImpacts(): DictionaryEntry[] {
    return this.getDictionary('Impact', 'name');
  }

  private getDictionary<T extends string | number = string>(
    name: string,
    key = 'entityId',
  ): DictionaryEntry<T>[] {
    if (isKey(this.dictionariesData, name)) {
      return this.dictionariesData[name].map((value: HasEntityId) => ({
        entityId: value.entityId,
        key: value[key as keyof HasEntityId],
      })) as DictionaryEntry<T>[];
    }
    return [];
  }
}
