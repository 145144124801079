import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { encodeEndpoint, EntityId, RestResponse, toData } from '@demica/resources/common';

import { FeeTypeResponse } from '../models/fee-type.interface';

export const FEE_TYPES_URL = 'resources/transactions/dictionaries/feeType';

@Injectable({ providedIn: 'root' })
export class FeeTypesResourcesService {
  constructor(private _http: HttpClient) {}

  getFeeTypes$(transactionType: EntityId): Observable<FeeTypeResponse[]> {
    const params = new HttpParams({ fromObject: { transactionType } });
    const url = encodeEndpoint(FEE_TYPES_URL);

    return this._http.get<RestResponse<FeeTypeResponse[]>>(url, { params }).pipe(map(toData));
  }
}
