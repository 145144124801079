import { Axis, createAxesMap } from './axes';

/* COMMON */
function createAxes(keys: string[], withMultipleData = false): Map<string, string> {
  const axis = new Set<Axis>();
  keys.forEach((key) => axis.add(new Axis().withMultipleData(withMultipleData).withAxisY(key)));
  return createAxesMap(axis);
}

export function createAxesByKey(keys: string[]) {
  return createAxes(keys, false);
}

export function createAxesByKeyWithMultipleData(keys: string[]) {
  return createAxes(keys, true);
}

export function createAxesByKeyWithPrefix(keys: string[], prefix = ''): Map<string, string> {
  const axis = new Set<Axis>();
  keys.forEach((key) => axis.add(new Axis().withMultipleData(false).withAxisY(prefix + key)));
  return createAxesMap(axis);
}
