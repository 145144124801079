import { NgZone } from '@angular/core';

import { AppConfigService } from '../app-config/app-config.service';
import { AuthenticationFlowStore } from '../auth2/authentication-flow-store.service';

import { KeycloakService } from 'keycloak-angular';
import { KeycloakConfig } from 'keycloak-js';

export function configureCodeFlow(
  keycloak: KeycloakService,
  appConfig: AppConfigService,
  flowStore: AuthenticationFlowStore,
  ngZone: NgZone,
  kcConfig: KeycloakConfig,
) {
  return appConfig.configAsPromise.then(({ auth }) => {
    return ngZone.runOutsideAngular(() =>
      keycloak
        .init({
          config: kcConfig,
          initOptions: {
            flow: 'standard', // Auth-code + PKCE
            onLoad: 'login-required',
            pkceMethod: 'S256',
            messageReceiveTimeout: auth.serverTimeout,
          },
          enableBearerInterceptor: false,
        })
        .then((result: boolean) => {
          ngZone.run(() => flowStore.setCodeFlow());
          return result;
        }),
    );
  });
}
