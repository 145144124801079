import { Component, Input, OnInit } from '@angular/core';

import { finalize } from 'rxjs/operators';

import {
  CountriesService,
  DictionaryEntry,
  DictionaryService,
  FeatureToggleService,
  HasEntityId,
  PostResponse,
  RegionsResourceService,
  RestResponse,
  SlideinContainerAbstractClass,
} from '@demica/core/core';
import { Client } from '@demica/resources/client';
import { Seller, SellerResourceService } from '@demica/resources/seller';

import { StandardIndustryCodesService } from '../../service/standard-industry-codes.service';

import { SicCode } from '../../model/sic-codes-translation';

@Component({
  selector: 'trf-create-seller-slidein-component',
  templateUrl: './create-seller-slidein.component.html',
  styleUrls: ['./create-seller-slidein.component.sass'],
})
export class CreateSellerSlideinComponent extends SlideinContainerAbstractClass implements OnInit {
  @Input()
  sellerId: number = null;

  countryOptions: HasEntityId[];
  sicCodesOptions: SicCode[];
  legalStatusOptions: DictionaryEntry[];
  client: Client;
  seller: Seller;
  successHandler: (response: RestResponse<PostResponse>) => void;
  processing = false;
  hasEnabledSellerAccounts = this._toggleService.hasEnabled('SELLER_BANK_ACCOUNTS');
  partialsEnabled = false;

  constructor(
    private _sellerResource: SellerResourceService,
    private _countriesService: CountriesService,
    private _dictionaryService: DictionaryService,
    private _toggleService: FeatureToggleService,
    private _sicCodesService: StandardIndustryCodesService,
    private _regionsResourceService: RegionsResourceService,
  ) {
    super();
  }

  ngOnInit() {
    this._countriesService.countriesAsSelectOptions().subscribe((options) => {
      this.countryOptions = options;
    });

    if (this.hasEnabledSellerAccounts) {
      this._sicCodesService.standardIndustryCodesAsSelectOptions().subscribe((sicCodes) => {
        this.sicCodesOptions = sicCodes;
      });
      this.legalStatusOptions = this._dictionaryService.getLegalStatuses();
    }
    this._regionsResourceService.getCurrentUserRegion().subscribe((region) => {
      this.partialsEnabled = region.partials;
    });
  }

  onSave(seller: Seller) {
    if (this.processing) return;

    this.processing = true;
    const putAction$ = this._sellerResource.updateSeller$(this.sellerId, {
      ...seller,
      clientId: this.client.entityId,
    });

    const postAction$ = this._sellerResource.createSeller$({
      ...seller,
      clientId: this.client.entityId,
    });

    const saveAction$ = this.sellerId ? putAction$ : postAction$;

    saveAction$
      .pipe(finalize(() => (this.processing = false)))
      .subscribe(this.handleSuccessfulSave);
  }

  private handleSuccessfulSave = (response: RestResponse<PostResponse>) => {
    this.modal.close();
    this.successHandler(response);
  };
}
