export const range = (start: number, end: number): NumericRange => new NumericRange(start, end);

export interface CustomRange {
  min?: string;
  max?: string;
}

export class NumericRange {
  constructor(private _start: number, private _end: number) {}

  contains(n: number): boolean {
    return n >= this._start && n <= this._end;
  }

  containsExclusive(n: number): boolean {
    return n > this._start && n < this._end;
  }

  asArray(): number[] {
    const arrayOfNumber = [];

    for (let i = this._start; i <= this._end; i++) {
      arrayOfNumber.push(i);
    }

    return arrayOfNumber;
  }
}
